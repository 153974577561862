import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import _values from 'lodash/values';
import _isArray from 'lodash/isArray';
import type { CustomStyleValue } from './types';

const getCustomStyleValue = ({
  styleValue,
  key,
  initialStylesAndClassName,
}: {
  styleValue: string | string[] | CustomStyleValue[];
  key: string;
  initialStylesAndClassName: { style: Record<string, string>; className: string };
}) => {
  if (typeof styleValue[0] === 'object') {
    const styleString = _reduce(
      styleValue[0],
      (acc, val) => {
        if (val) {
          return `${acc} ${val}`;
        }
        return acc;
      },
      '',
    );

    return {
      className: `${initialStylesAndClassName.className} ${styleString}`,
      style: initialStylesAndClassName.style,
    };
  }
  const styleString = _isArray(styleValue) ? styleValue[0] : styleValue;
  return {
    style: { ...initialStylesAndClassName.style, [key]: styleString },
    className: initialStylesAndClassName.className,
  };
};

export { getCustomStyleValue };
