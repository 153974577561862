import _values from 'lodash/values';
import _forEach from 'lodash/forEach';
import {
  getConditionalPropertyValue,
  type ConditionalValueFilters,
} from '../../../utils/conditionalValues';

export function getUpdatedAppearanceStyleFromConditionValues({
  appearanceStyles,
  conditionalValueFilters,
}: {
  appearanceStyles: {
    className: string;
    style?: Record<string, string>;
  };
  conditionalValueFilters:
    | ConditionalValueFilters<string>
    | Record<string, ConditionalValueFilters<string>>;
}) {
  const conditionalFilter =
    'conditionalValueFilters' in conditionalValueFilters
      ? (conditionalValueFilters as ConditionalValueFilters<string>)
      : _values(conditionalValueFilters)[0];

  const value = getConditionalPropertyValue<string>({ value: conditionalFilter });

  return {
    ...appearanceStyles,
    className: value ? `${appearanceStyles.className} ${value}` : appearanceStyles.className,
  };
}
