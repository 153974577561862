/* eslint-disable @typescript-eslint/no-extraneous-class -- required */
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import type { MRT_RowData as RowData, MRT_ColumnDef as ColumnDef } from '@unifyapps/table/types';

type Columns = DataTableComponentType['content']['columns'];

export class DataTableColumnHelper {
  static isNestedColumnType = (columnType: Columns[0]['type']) => {
    return columnType === 'NESTED_TABLE' || columnType === 'GROUPED';
  };

  static hasNestedColumns = (column: Columns[0]): column is Columns[0] & { columns: Columns } => {
    return this.isNestedColumnType(column.type);
  };

  static getColumns = (columns: Columns): Columns => {
    return columns.reduce<Columns>((accumulator, column) => {
      accumulator.push(column);

      if (column.type === 'GROUPED') {
        accumulator.push(...this.getColumns(column.columns));
      }

      return accumulator;
    }, []);
  };

  static hasEditableColumns = (component: DataTableComponentType) => {
    return (
      this.getColumns(component.content.columns).some((column) => column.editable) ||
      Boolean(component.content.mappedColumn?.editable)
    );
  };

  static getMRTColumns = (columns: ColumnDef<RowData>[]): ColumnDef<RowData>[] => {
    return columns.reduce<ColumnDef<RowData>[]>((accumulator, column) => {
      accumulator.push(column);

      if (column.columns) {
        accumulator.push(...this.getMRTColumns(column.columns));
      }

      return accumulator;
    }, []);
  };

  static isMRTColumnEditable = (column: ColumnDef<RowData>): boolean => {
    return Boolean(column.enableEditing);
  };
}
