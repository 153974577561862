import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { useMountEffect } from '@react-hookz/web';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  ChatComponentState,
  ChatComponentType,
  Message,
} from '@unifyapps/defs/blocks/Chat/types';
import userStore from '@unifyapps/carbon/auth/userStore';
import Stack from '@unifyapps/ui/_components/Stack';
import { Box } from '@unifyapps/ui/components/Box';
import AsyncView from '@unifyapps/carbon/components/AsyncView';
import { NULL_COMPONENT } from '@unifyapps/carbon/consts/empty';
import { useTranslation } from '@unifyapps/i18n/client';
import useToggle from '@unifyapps/hooks/useToggle';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { clsx } from 'clsx';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { useSubscribeNewMessage } from './hooks/useSubscribeNewMessage';
import { ChatList } from './components/ChatList';
import { useScrollAnchor } from './hooks/useScrollAnchor';
import { ChatPanel } from './components/ChatPanel';
import { useFetchPaginatedMessage } from './hooks/useFetchPaginatedMessage';
import { useMessageActions } from './hooks/useMessageActions';

export type ChatRef = {
  onSubmitMessage: (
    { message, attachments }: { message: string; attachments?: FileType[] },
    contextMessageId?: string,
  ) => void;
};

const AVERAGE_RESPONSE_TIME_IN_MS = 1000 * 16;

const ChatBlock = forwardRef<
  ChatRef,
  {
    fetchedMessages?: Message[];
    fetchNextPage?: () => void;
    isFetchingMessages?: boolean;
  } & Pick<
    BlockComponentProps<ChatComponentType, ChatComponentState, ChatComponentState>['component'],
    'content' | 'appearance'
  >
>(function ChatBlock(
  { content, appearance, fetchedMessages, fetchNextPage, isFetchingMessages },
  chatRef,
) {
  const {
    messagesRef: messagesContainerRef,
    scrollRef: scrollContainerRef,
    visibilityRef,
  } = useScrollAnchor({ fetchedMessages });

  const { className } = useBlockAppearanceStyles({ appearanceStyles: appearance?.styles });

  // to preload translations
  useTranslation('copilot');
  const {
    chatId,
    secondaryUserName,
    exampleMessages,
    initialPrompt,
    placeholder,
    size = 'lg',
    ChatHeader,
    primaryActionItems,
    averageResponseTime = AVERAGE_RESPONSE_TIME_IN_MS,
    welcomeText,
  } = content;
  const automationsConfig = content.automationsConfig as AutomationConfig;

  const initialPromptRef = useRef<string | null>(null);

  const data = userStore.use.currentUserDetails();
  const loggedInUserName = data?.user?.name ?? '';
  const loggedInUserId = data?.user?.id ?? '';

  const {
    messages,
    onNewMessage,
    onClearConversation,
    onSubmitMessage,
    onRegenerateResponse,
    isAwaitingResponse,
    refetchMessage,
  } = useMessageActions({
    automationsConfig,
    chatId,
    userId: loggedInUserId,
    fetchedMessages,
  });
  const [isScrolled, { on, off }] = useToggle(false);

  const onScroll = useCallback(
    (event: React.SyntheticEvent) => {
      if (!fetchNextPage) return;

      const scrollEl = event.currentTarget;
      if (scrollEl.scrollTop > 0) {
        on();
      } else {
        off();
      }

      if (scrollEl.scrollTop < 600) {
        fetchNextPage();
      }
      return undefined;
    },
    [fetchNextPage, off, on],
  );

  useSubscribeNewMessage({
    loggedInUserId,
    onNewMessage,
    chatId,
  });

  useImperativeHandle(
    chatRef,
    () => ({
      onSubmitMessage,
    }),
    [onSubmitMessage],
  );

  useMountEffect(() => {
    if (initialPromptRef.current === null && initialPrompt) {
      initialPromptRef.current = 'PROMPT_HANDLED';
      onSubmitMessage({ message: initialPrompt });
    }
  });

  return (
    <Stack className={clsx('size-full items-center', className)}>
      {ChatHeader ? (
        <ChatHeader isScrolled={isScrolled} onClearConversation={onClearConversation} />
      ) : null}
      <Stack className="min-h-0 w-full flex-1" direction="row">
        <Stack className="min-h-0 w-full flex-1 items-center">
          <Box
            className={clsx('flex w-full flex-1 justify-center overflow-y-auto', {
              'px-10xl': size === 'lg',
            })}
            onScroll={fetchNextPage ? onScroll : undefined}
            ref={scrollContainerRef}
          >
            <ChatList
              automationsConfig={automationsConfig}
              averageResponseTime={averageResponseTime}
              isAwaitingResponse={isAwaitingResponse}
              isFetchingMessages={isFetchingMessages}
              messages={messages}
              onRegenerateResponse={onRegenerateResponse}
              onSubmitMessage={onSubmitMessage}
              primaryActionItems={primaryActionItems}
              ref={messagesContainerRef}
              refetchMessage={refetchMessage}
              secondaryUserName={secondaryUserName}
              size={size}
              userName={loggedInUserName}
              visibilityRef={visibilityRef}
              welcomeText={welcomeText}
            />
          </Box>
          <ChatPanel
            className={size === 'lg' ? 'px-10xl' : ''}
            exampleMessages={exampleMessages}
            isChatEmpty={messages.length === 0 && !isFetchingMessages}
            onSubmitMessage={onSubmitMessage}
            placeholder={placeholder}
            size={size}
          />
        </Stack>
      </Stack>
    </Stack>
  );
});

const ChatBlockContainer = forwardRef<
  ChatRef,
  Pick<BlockComponentProps<ChatComponentType, ChatComponentState, ChatComponentState>, 'component'>
>(function ChatBlockContainer(props, chatRef) {
  const { chatId } = props.component.content;

  const automationsConfig = props.component.content.automationsConfig as
    | AutomationConfig
    | undefined;

  const {
    isLoading: isFetchingMessages,
    messages,
    fetchMore,
    error,
  } = useFetchPaginatedMessage({
    chatId,
    automationsConfig,
  });

  return (
    <AsyncView
      data={messages}
      error={error}
      // input panel will be always visible
      isEmpty={false}
      isLoading={false}
      renderEmptyState={NULL_COMPONENT}
    >
      <ChatBlock
        appearance={props.component.appearance}
        content={props.component.content}
        fetchNextPage={fetchMore}
        fetchedMessages={messages}
        isFetchingMessages={isFetchingMessages}
        ref={chatRef}
      />
    </AsyncView>
  );
});

export default ChatBlockContainer;
