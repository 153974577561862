import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import { cva } from 'class-variance-authority';
import type { MediaType } from '../../MediaViewer/types';
import type { MediaOverrides } from '../types';
import { isPdf } from '../utils';
import { downloadFileInSameTab } from '../../../utils/file/downloadFileInSameTab';
import { SpaciousMediaItem } from './SpaciousMediaItem';

const containerClassNameVariants = cva('size-full flex-wrap', {
  variants: {
    size: {
      sm: 'gap-md',
      md: 'gap-md',
      lg: 'gap-lg',
    },
  },
});

export function SpaciousMediaContainer({
  media,
  className,
  openMediaViewer,
  size,
  maxUpfrontItems,
  showCaption,
  onRemoveItem,
  overrides,
}: {
  media: MediaType[];
  className?: string;
  openMediaViewer: (index: number) => void;
  size: 'sm' | 'md' | 'lg';
  maxUpfrontItems: number;
  showCaption: boolean;
  onRemoveItem?: (id: string) => void;
  overrides?: MediaOverrides;
}) {
  const overflowCount = media.length - maxUpfrontItems;
  const maxLength = Math.min(media.length, maxUpfrontItems);

  return (
    <Stack className={clsx(containerClassNameVariants({ size }), className)} direction="row">
      {media.slice(0, maxLength).map((item, index) =>
        overflowCount <= 0 || index < maxLength - 1 ? (
          <SpaciousMediaItem
            item={item}
            key={item.id}
            onClick={() => {
              if (isPdf(item)) {
                downloadFileInSameTab(item.url);
              } else {
                openMediaViewer(index);
              }
            }}
            onRemoveItem={onRemoveItem}
            overrides={overrides}
            showCaption={showCaption}
            size={size}
          />
        ) : (
          <Box
            className="relative cursor-pointer"
            key={item.id}
            onClick={() => {
              if (isPdf(item)) {
                downloadFileInSameTab(item.url);
              } else {
                openMediaViewer(index);
              }
            }}
          >
            <SpaciousMediaItem
              className="bg-overlay"
              item={item}
              onRemoveItem={onRemoveItem}
              overrides={overrides}
              showCaption={showCaption}
              size={size}
            />
            <Typography
              className="bg-overlay text-primary_on-brand absolute inset-0 flex size-full items-center justify-center rounded-sm opacity-60"
              variant="display-xs"
              weight="medium"
            >{`+${overflowCount + 1}`}</Typography>
          </Box>
        ),
      )}
    </Stack>
  );
}
