import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { CollaborationComponentType } from '@unifyapps/defs/blocks/Collaboration/types';
import { lazy } from 'react';

const importCollaborationComponent = () =>
  import(
    /* webpackChunkName: "collaboration-block" */
    './Collaboration'
  );

const Collaboration = lazy(importCollaborationComponent);

class CollaborationBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Collaboration';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }
  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as CollaborationComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Collaboration;
  }

  preload() {
    return {
      blockPreload: importCollaborationComponent,
    };
  }
}

export default CollaborationBlockStateDefinition;
