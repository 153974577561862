import { useEffect, useRef, useState } from 'react';
import type { IdentityProvider } from '@unifyapps/network/auth/types';
import type {
  DeprecatedFormComponentType,
  SSOButtonComponentType,
} from '@unifyapps/defs/types/blocks';
import { nanoid } from 'nanoid';
import type { BlockId } from '@unifyapps/defs/types/block';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { ActionType } from '@unifyapps/defs/types/action';
import { NEW_BLOCK_ID } from '../../../../const';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useRegistryContext } from '../../../RegistryProvider';

function IDPComponent({ identityProvider }: { identityProvider: IdentityProvider }) {
  const BlockRenderer = useRegistryContext().registry.getBlockRenderer();
  const { createBlock, insertBlock } = useInterfaceStore().use.actions();
  const isPushedToStore = useRef(false);
  const [blockId, setBlockId] = useState<BlockId>(NEW_BLOCK_ID);

  useEffect(() => {
    if (isPushedToStore.current) return;
    if (identityProvider.uiConfig?.type === 'button') {
      const { success: isCreated, blockId: rootBlockId } = createBlock({
        block: {
          component: identityProvider.uiConfig.button as SSOButtonComponentType,
          events: [
            {
              id: nanoid(),
              eventType: EventTypeEnum.OnClick,
              action: {
                actionType: ActionType.Login,
                payload: {
                  method: 'sso',
                  identityProviderId: identityProvider.id,
                },
              },
            },
          ],
        },
      });
      if (!isCreated) return;
      const { success: isInserted } = insertBlock({ blockId: rootBlockId });
      if (!isInserted) return;
      setBlockId(rootBlockId);
    } else if (identityProvider.uiConfig?.form) {
      const formComponentType = identityProvider.uiConfig.form as DeprecatedFormComponentType;

      const { success: isCreated, blockId: rootBlockId } = createBlock({
        block: {
          events: [
            {
              id: nanoid(),
              eventType: EventTypeEnum.OnSubmit,
              action: {
                actionType: ActionType.Login,
                payload: {
                  method: 'password',
                  identityProviderId: identityProvider.id,
                },
              },
            },
          ],
          component: {
            ...formComponentType,
            componentType: 'DeprecatedForm',
          } as DeprecatedFormComponentType,
        },
      });
      if (!isCreated) return;
      const { success: isInserted } = insertBlock({ blockId: rootBlockId });
      if (!isInserted) return;
      setBlockId(rootBlockId);
    }
    isPushedToStore.current = true;
  }, [
    createBlock,
    identityProvider.id,
    identityProvider.uiConfig,
    identityProvider.uiConfig?.type,
    insertBlock,
  ]);

  if (!isPushedToStore.current || !blockId) return null;

  return <BlockRenderer blockId={blockId} />;
}

export default IDPComponent;
