import isObject from 'lodash/isObject';
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';

export const getFieldKey = (fieldKey: DataTableComponentType['content']['identifier']) => {
  return fieldKey && isObject(fieldKey) ? fieldKey.path : fieldKey;
};

export const getColumnFieldKey = (column: DataTableComponentType['content']['columns'][0]) => {
  return getFieldKey(column.fieldKey);
};

export const getColumnFieldKeys = (columns: DataTableComponentType['content']['columns']) => {
  return columns.map(getColumnFieldKey).filter((fieldKey): fieldKey is string => Boolean(fieldKey));
};
