import type { BlockComponentProps } from '../BlockRenderer/types';
import useGetFallbackNode from '../DataSourceController/hooks/useGetFallbackComponent';

function withSuspense<T, P, R>(Component: React.FC<BlockComponentProps<T, P, R>>) {
  return function SuspenseWrapper(props: BlockComponentProps<T, P, R>) {
    const { isSuspended, fallback } = props;
    const { fallbackNode } = useGetFallbackNode({
      fallback,
      isSuspended,
    });

    if (isSuspended) return fallbackNode;

    return <Component {...props} />;
  };
}

export default withSuspense;
