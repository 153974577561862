import type { Dictionary } from 'lodash';
import { startCase, keyBy, get } from 'lodash';
import { useCallback, useMemo } from 'react';
import type { PathObj } from '@unifyapps/form/utils/transformErrors';
import { transformErrors } from '@unifyapps/form/utils/transformErrors';
import { useTranslation } from '@unifyapps/i18n/client';
import type { FormProps } from '@rjsf/core';
import type { Registry } from '@rjsf/utils';
import { getUiOptions } from '@rjsf/utils';
import { getAllPaths } from '../../widgets/rjsf/utils/getAllPathsFromSchema';
import type { Props } from '..';

export const getUiSchemaFromPropertyPath = (
  property: string,
  uiSchema: FormProps['uiSchema'],
): FormProps['uiSchema'] | undefined => {
  // split the property path into segments
  // it can be of .format or .format.0.format or .format1.format2.format3.0.format4
  const segments = property.split('.').filter((segment) => segment !== '');

  // replace numeric indices with 'items' to match the uiSchema structure
  const adaptedSegments = segments.map((segment) =>
    Number.isInteger(Number(segment)) ? 'items' : segment,
  );

  const adaptedPath = adaptedSegments.join('.');

  return get(uiSchema, adaptedPath) as FormProps['uiSchema'];
};

export function useTransformErrors(props: {
  transformErrors?: Props['transformErrors'];
  schema: Props['schema'];
  uiSchema?: FormProps['uiSchema'];
  registry?: Registry;
}) {
  const { schema } = props;
  const { t } = useTranslation('Common');

  // this handle getting title from schema.title or schema fieldkey,
  const keyByPath = useMemo(() => {
    return keyBy(getAllPaths({ schema }), 'key') as Dictionary<PathObj | undefined>;
  }, [schema]);

  const getLabelFromUiSchema = useCallback(
    (property?: string) => {
      const uiSchema = property ? getUiSchemaFromPropertyPath(property, props.uiSchema) : undefined;
      const uiOptions = getUiOptions(uiSchema, props.registry?.globalUiOptions);
      return uiOptions.title;
    },
    [props.registry?.globalUiOptions, props.uiSchema],
  );

  const getLabelFromProperty = useCallback(
    // property looks like field1 or 'field1.field2.field3' or 'field1.0.field2.0'
    (property?: string) => {
      const titleFromUiSchema = getLabelFromUiSchema(property);

      if (titleFromUiSchema) {
        return titleFromUiSchema;
      }

      // so we split it by '.' and get the last part of the string
      const lastSegment = property?.split('.').pop();
      const pathObj = lastSegment ? keyByPath[lastSegment] : undefined;
      const numericSegment = Number(lastSegment);

      if (Number.isInteger(Number(numericSegment))) {
        // then the property must be like field1.0.field2.0 or something like that it could also be field1.0.0
        // so we split the string by '.' and create a label from the end till we get a non number field
        const split = property?.split('.');
        if (!split) return;
        let i = split.length - 1;
        let label = '';
        while (Number.isInteger(Number(split[i]))) {
          label = `${Number(split[i]) + 1} ${label}`;
          i--;
        }

        label = `${split[i]} ${label}`;

        return startCase(label);
      }

      // resolvedPath has the array with the title of the field, for every field in field1.field2.field3
      const labelFromPath = pathObj?.resolvedPath[pathObj.resolvedPath.length - 1];

      return startCase(labelFromPath);
    },
    [getLabelFromUiSchema, keyByPath],
  );

  const _transformErrors: NonNullable<FormProps['transformErrors']> = useCallback(
    (errors, uiSchema) => {
      let newErrors = errors;
      newErrors = transformErrors({
        errors: newErrors,
        getLabelFromProperty,
        t,
      });

      // we are giving the props.transformErrors priority because we are overriding the ua:error at alot of places
      if (props.transformErrors) {
        newErrors = props.transformErrors(newErrors, uiSchema);
      }
      return newErrors;
    },
    [getLabelFromProperty, props, t],
  );

  return { _transformErrors };
}
