import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import type React from 'react';
import useExecuteNode from '@unifyapps/carbon/copilot/hooks/useExecuteNode';
import MessageHelper from '../helpers/MessageHelper';

export const useLookupMessage = ({
  onError,
  upsertMessage,
  chatId,
  setIsAwaitingResponse,
}: {
  onError: (error: unknown) => void;
  upsertMessage: (messageId: string, message: Message) => void;
  chatId?: string;
  setIsAwaitingResponse: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}) => {
  return useExecuteNode({
    onError,
    onSettled: () => {
      setIsAwaitingResponse(false);
    },
    onSuccess: (
      payload: { response: Message | null },
      variables: { parameters: { messageId: string } },
    ) => {
      if (!payload.response) {
        setIsAwaitingResponse(false);
        return;
      }

      const newMessage = MessageHelper.fromLookupMessage(
        payload.response,
        variables.parameters.messageId,
        chatId,
      );
      upsertMessage(newMessage.messageId, newMessage);
    },
  });
};
