import { useCallback, useMemo } from 'react';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import type { Draft } from 'immer';
import { getRunTimeBlockId } from '../../utils/runTimeBlocks';
import { useRuntimeBlockKey } from '../../components/BlockRuntimeStateProvider';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { useGetAndSetRunTimeBlockState } from '../useGetAndSetRunTimeBlockState';

function useSetBlockState(originalBlockId: string) {
  const runTimeBlockKey = useRuntimeBlockKey();
  const blockId = useMemo(
    () =>
      runTimeBlockKey
        ? getRunTimeBlockId({
            originalBlockId,
            runTimeBlockKey,
          })
        : originalBlockId,
    [originalBlockId, runTimeBlockKey],
  );

  const { updateBlockState: updateBlockStateStore } = useGlobalStateStore().use.actions();

  // in case of !runTimeBlockKey, originalBlockState is same as runTimeBlockState
  const originalBlockState = useGlobalStateStore().use.block.details(originalBlockId);

  const runTimeBlockState = useGetAndSetRunTimeBlockState({
    originalBlockState,
    blockId,
    runTimeBlockKey,
  });

  const updateBlockState = useCallback(
    (setter: (blockState: Draft<BlockStateUnionType>) => void) => {
      updateBlockStateStore(blockId, setter);
    },
    [blockId, updateBlockStateStore],
  );

  return {
    blockState: runTimeBlockKey ? runTimeBlockState : originalBlockState,
    updateBlockState,
  };
}

export default useSetBlockState;

export type UseSetBlockStateReturnType = ReturnType<typeof useSetBlockState>;
