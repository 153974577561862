import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import _merge from 'lodash/merge';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';

export const useGetAndSetRunTimeBlockState = ({
  originalBlockState,
  blockId,
  runTimeBlockKey,
}: {
  originalBlockState: BlockStateUnionType | undefined;
  blockId: string;
  runTimeBlockKey: string | undefined;
}): BlockStateUnionType | undefined => {
  const runTimeBlockState = useGlobalStateStore().use.block.details(blockId) as
    | BlockStateUnionType
    | undefined;

  if (runTimeBlockKey === undefined) {
    return originalBlockState;
  }

  // In repeatable, we don't save all the properties of child blocks, we only save the properties that come as updates from updateBlockState called inside the child block
  return {
    ..._merge({}, originalBlockState, runTimeBlockState),
    id: blockId,
    originalBlockId: originalBlockState?.id,
  } as BlockStateUnionType;
};
