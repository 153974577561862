import type { EvaluatedField, Field } from '@unifyapps/defs/blocks/KeyValue/types';

export const getFieldsState = ({
  fields,
  fieldId,
  value,
  values,
}: {
  fields: EvaluatedField[];
  fieldId: string;
  value: string | string[] | undefined;
  values: Record<string, string | string[] | undefined> | undefined;
}) => {
  return fields.reduce((acc, field) => {
    if (field.id === fieldId) {
      return {
        ...acc,
        [fieldId]: value,
      };
    }

    return acc;
  }, values ?? {});
};

export const getFieldValues = (fields: EvaluatedField[] | Field[]) =>
  fields.reduce((acc, field) => {
    return {
      ...acc,
      [field.id]: field.content.value,
    };
  }, {});
