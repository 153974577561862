import type { AvatarSlotsAndSlotProps } from '@mui/joy/Avatar';
import DefaultAvatar from '@mui/joy/Avatar';
import { forwardRef, useMemo } from 'react';
import _trim from 'lodash/trim';
import { slowCn } from '../../lib/utils';
import { avatarRootVariants, getAvatarRootStyles } from './styles';
import type { AvatarProps } from './types';

const getUserInitials = (name: string): string | null => {
  const trimmedName = _trim(name);

  if (!trimmedName) {
    return null;
  }

  return trimmedName[0].toUpperCase();
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(function Avatar(props, ref) {
  const { color = 'primary', children, name, size = 'sm', className, ...rest } = props;

  const slotProps = useMemo<AvatarSlotsAndSlotProps['slotProps']>(
    () => ({
      root: {
        className: slowCn(avatarRootVariants({ color, size }), className),
        style: getAvatarRootStyles(color),
      },
    }),
    [className, color, size],
  );

  return (
    <DefaultAvatar alt={name} {...rest} ref={ref} slotProps={slotProps}>
      {children ?? (name ? getUserInitials(name) : null)}
    </DefaultAvatar>
  );
});

export default Avatar;
