import { cva } from 'class-variance-authority';

export const menuItemTitleVariants = cva('', {
  variants: {
    variant: {
      default: '',
      danger: '',
    },
    disabled: {
      true: 'text-fg-disabled',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      disabled: false,
      className: 'text-secondary',
    },
    {
      variant: 'danger',
      disabled: false,
      className: 'text-error-primary',
    },
  ],
});

export const menuItemIconVariants = cva('', {
  variants: {
    variant: {
      default: '',
      danger: '',
    },
    disabled: {
      true: 'text-disabled',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      disabled: false,
      className: 'text-fg-quaternary',
    },
    {
      variant: 'danger',
      disabled: false,
      className: 'text-fg-error-primary',
    },
  ],
});

export const menuItemHelpTextVariants = cva('', {
  variants: {
    variant: {
      default: '',
      danger: '',
    },
    disabled: {
      true: 'text-disabled',
      false: '',
    },
  },
  compoundVariants: [
    {
      variant: 'default',
      disabled: false,
      className: 'text-quaternary',
    },
    {
      variant: 'danger',
      disabled: false,
      className: 'text-quaternary',
    },
  ],
});
