import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { NavigationContainerComponentType } from '@unifyapps/defs/blocks/NavigationContainer/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importNavigationContainerComponent = () =>
  import(
    /* webpackChunkName: "navigation-container-block" */
    './NavigationContainer'
  );

const NavigationContainer = lazy(importNavigationContainerComponent);

class NavigationContainerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'NavigationContainer';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as NavigationContainerComponentType, [
          'content',
          'componentType',
          'appearance',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return NavigationContainer;
  }

  preload() {
    return {
      blockPreload: importNavigationContainerComponent,
    };
  }
}

export default NavigationContainerBlockStateDefinition;
