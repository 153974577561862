import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import { slowCn } from '@unifyapps/ui/lib/utils';
import { useMemo } from 'react';
import _noop from 'lodash/noop';
import { useInterfaceStore } from '../../../../stores/InterfaceStore';
import { useRegistryContext } from '../../../RegistryProvider';
import type { LoginLayoutComponentType } from '../../types';
import LoginContent from '../LoginContent';
import CreativeContent from '../CreativeContent';
import type { BlockComponentProps } from '../../../BlockRenderer/types';

function CenteredCardLayout(
  props: BlockComponentProps<
    LoginLayoutComponentType,
    Record<string, unknown>,
    Record<string, unknown>
  >,
) {
  const { headerLogo, headerClassName, creativeContent } = props.component;
  const interfaceRecord = useInterfaceStore().use.record.details();
  const { registry } = useRegistryContext();
  const ImageComponent = registry.getBlockComponent('Image');

  const imageBlockState: BlockStateUnionType = useMemo(() => {
    // see if we can go deep to this selector
    const name = interfaceRecord.properties.name;
    const logo = interfaceRecord.properties.metadata.logo.large;

    return {
      ...headerLogo,
      content:
        registry.getRegistryName() === 'platform' && headerLogo?.content
          ? headerLogo.content
          : {
              alt: `${name} logo`,
              src: logo,
            },
      appearance: {
        objectFit: 'object-cover',
        styles: {
          height: {
            custom: '44px',
          },
        },
      },
      componentType: 'Image',
      id: 'logo',
      state: {
        isOpenInMediaViewer: false,
      },
    };
  }, [interfaceRecord, headerLogo, registry]);

  return (
    <Stack className="isolate h-full w-full">
      {headerLogo && ImageComponent ? (
        <Stack
          alignItems="center"
          className={slowCn(
            'bg-secondary p-lg ps-3xl border-secondary login-header h-16 w-full border-b',
            headerClassName,
          )}
          direction="row"
        >
          <ImageComponent
            blockState={imageBlockState}
            component={headerLogo}
            computedBlockState={imageBlockState}
            updateBlockState={_noop}
          />
        </Stack>
      ) : null}
      {creativeContent ? (
        <Stack className="relative h-dvh w-full flex-shrink-0">
          <CreativeContent creativeContent={creativeContent} />
        </Stack>
      ) : null}
      <Stack
        alignItems="center"
        className="z-badge absolute mx-auto h-full w-full"
        justifyContent="center"
      >
        <Stack
          alignItems="center"
          className={clsx('px-8xl bg-primary py-6xl', { 'rounded-4xl shadow-md': creativeContent })}
          justifyContent="center"
        >
          <LoginContent {...props} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CenteredCardLayout;
