import { Divider as BaseDivider } from '@unifyapps/ui/components/Divider';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { DividerComponentType, DividerBlockStateType } from '@unifyapps/defs/types/blocks';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { useRegistryContext } from '@unifyapps/carbon/no-code/components/RegistryProvider';
import { isEmptyBlock } from '@unifyapps/carbon/no-code/utils/block';
import { memo } from 'react';

function Divider({
  dataAttributes,
  component,
  computedBlockState,
}: BlockComponentProps<DividerComponentType, DividerBlockStateType, DividerBlockStateType>) {
  const { appearance } = computedBlockState;
  const { registry } = useRegistryContext();
  const BlockRenderer = registry.getBlockRenderer();

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  return (
    <BaseDivider
      className={className}
      orientation={appearance?.orientation}
      style={style}
      {...dataAttributes}
    >
      {component.slots?.body?.blockId && !isEmptyBlock(component.slots.body.blockId) ? (
        <BlockRenderer blockId={component.slots.body.blockId} />
      ) : null}
    </BaseDivider>
  );
}

export default memo(Divider);
