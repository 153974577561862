/* eslint-disable @typescript-eslint/naming-convention -- temporary */

export enum EntityTypeVsAssetClass {
  WorkflowDefinition = 'WORKFLOW_DEFINITION', // Automations
  CallableInterface = 'CALLABLE_INTERFACE', // Automation Interfaces
  NoCodeInterface = 'e_interface', // Interfaces
  WorkflowLibrary = 'TEMPLATE',
  PipelineConfig = 'DATA_PIPELINE',
  EntityType = 'ENTITY_TYPE', // ObjectManager
  ApiReport = 'API_REPORT',
  APIEndpointCollection = 'API_ENDPOINT_COLLECTION',
  APIPolicy = 'API_POLICY',
  APIClient = 'API_CLIENT',
  e_campaign = 'e_campaign',
  e_decision_table = 'e_decision_table',
  e_code_snippet = 'e_code_snippet',
  e_template = 'e_template',
  e_streams = 'e_streams',
  knowledge = 'knowledge',
  CopilotFeedback = 'service_hub_user_feedback',
  ai_agent_deployment = 'ai_agent_deployment',
  ai_agent = 'ai_agent',
  IdentityProvider = 'IDENTITY_PROVIDER',
  prompt = 'prompt',
  e_prerequisite_task_ai_agent = 'e_prerequisite_task_ai_agent',
  e_action_ai_agent = 'e_action_ai_agent',
  e_topic_ai_agent = 'e_topic_ai_agent',
  AccessProfile = 'ACCESS_PROFILE',
  SegmentDefinition = 'SEGMENT_DEFINITION',
  AlertDefinition = 'ALERT_DEFINITION',
  User = 'USER',
  Copilot = 'CO_PILOT',
  BusinessHours = 'e_business_hours',
  BusinessHolidays = 'e_business_holiday',
  EnvironmentVariables = 'e_env_variables',
  Connection = 'CONNECTION', // Connection Manager
  ConnectorDefinition = 'CONNECTOR_DEFINITION', // Connectors SDK
  CustomerEnvironment = 'CUSTOMER_ENVIRONMENT',
  Teams = 'USER_GROUP',
  Roles = 'ROLE_DEFINITION',
  ChangesetRequest = 'CHANGESET_REQUEST', // Inbound Change Set
  Changeset = 'CHANGESET', // Outbound Change Set
  Project = 's_project',
  UnifiedEntityAccessControl = 'UNIFIED_ENTITY_ACCESS_CONTROL',
  UnifiedEntityModel = 'UNIFIED_ENTITY_MODEL',
  Guardrail = 'gaurd_rail',
  Tenant = 'TENANT',
  AIModels = 'ai_agent_llm_model', // TODO: check
  e_knowledge_mime_type = 'e_knowledge_mime_type',
  CopilotConfig = 'co_pilot_config',
}

export type CustomEntityType = string;

export enum Permission {
  ALL = 'ALL',
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DEPLOY = 'DEPLOY',
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT',
  APPROVE = 'APPROVE',
  READ = 'READ',
  EXPORT_AS_TEMPLATE = 'EXPORT_AS_TEMPLATE',
  TEST = 'TEST',
  VIEW_RUNS = 'VIEW_RUNS',
  VIEW = 'VIEW',
  SHARE = 'SHARE',
  ROLLBACK = 'ROLLBACK',
  VIEW_REPORT = 'VIEW_REPORT',
  SWITCH_TENANT = 'SWITCH_TENANT',
  PUBLISH = 'PUBLISH',
  RESET_PASSWORD = 'RESET_PASSWORD',
}
