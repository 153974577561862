import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { BlockType } from '@unifyapps/defs/types/block';
import { UAEntityTypes } from '@unifyapps/defs/types/entities';

export const generatePage = ({
  blocks,
  id,
}: {
  id: string;
  blocks: Record<string, BlockType>;
}): InterfacePageEntity => ({
  id,
  deleted: false,
  entityType: UAEntityTypes.Component,
  version: 0,
  properties: { blocks },
});
