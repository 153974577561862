import { memo } from 'react';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { MediaBlockStateType, MediaComponentType } from '@unifyapps/defs/blocks/Media/types';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { clsx } from 'clsx';
import { Box } from '@unifyapps/ui/components/Box';
import Media from '@unifyapps/carbon/components/Media';
import { MediaVariants } from '@unifyapps/carbon/components/Media/types';
import { useAdaptMedia } from './hooks/useAdaptMedia';

function MediaBlock({
  dataAttributes,
  computedBlockState,
}: BlockComponentProps<MediaComponentType, MediaBlockStateType, MediaBlockStateType>) {
  const {
    content: { src, type, mappings },
    appearance,
  } = computedBlockState;

  const {
    styles,
    variant: _variant = 'spacious',
    size,
    display,
    showCaption,
    cols,
    maxUpfrontItems,
  } = appearance ?? {};

  const { style, className } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  const variant = (display === 'list' ? MediaVariants.Compact : _variant) as MediaVariants;

  const media = useAdaptMedia({ src, type, mappings });

  return (
    <Box className={clsx('flex', className)} style={style} {...dataAttributes}>
      {media ? (
        <Media
          className={clsx('w-full', className)}
          cols={display === 'list' ? 1 : cols}
          maxUpfrontItems={maxUpfrontItems}
          media={media}
          showCaption={showCaption}
          size={size}
          variant={variant}
        />
      ) : null}
    </Box>
  );
}

export default memo(MediaBlock);
