import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useEffect, useRef } from 'react';
import useCopilotActions from '@unifyapps/carbon/copilot/hooks/useCopilotActions';
import { executeWorkflowNode } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import type { Message, PaginatedMessages } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';

const POLLING_INTERVAL = 5000;

function usePollForNewMessages(
  addBatchMessages: (messages: Message[], isPolling: boolean) => void,
  chatId?: string,
  automationsConfig?: AutomationConfig,
) {
  const isPolling = useRef(false);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const { fetchConversation } = useCopilotActions(automationsConfig);
  const pollSince = useRef<number>();

  const clearTimer = useEventCallback(() => {
    if (timer.current) clearTimeout(timer.current);
  });

  const stopPolling = useEventCallback(() => {
    isPolling.current = false;
    pollSince.current = undefined;
    clearTimer();
  });

  const fetchNewMessage = useEventCallback(async () => {
    const { debugParams, ...inputs } = fetchConversation({
      caseId: chatId ?? '',
      since: pollSince.current,
    });

    try {
      const payload = (await executeWorkflowNode(
        { context: { appName: 'callables', resourceName: 'callables_call_automation' }, inputs },
        { debugParams } as { debugParams: Record<string, string> },
      )) as PaginatedMessages;
      if (payload.response.messages?.length) {
        const newMessages = payload.response.messages;

        // continue polling if there are only user messages
        const shouldStillPoll = !newMessages.some((msg) => msg.messageType !== MessageType.User);

        addBatchMessages(newMessages, shouldStillPoll);
        pollSince.current = newMessages[newMessages.length - 1].createdTime;

        console.debug('newMessages', newMessages, shouldStillPoll);
        if (!shouldStillPoll) {
          stopPolling();
        }
      } else {
        console.debug('no new messages', payload);
      }
    } catch (e) {
      console.error('Error while fetching new messages', e);
    }
  });

  // when chatId is changed from undefined to some valid id after first message, chat block is remounted,
  // and while its unmounted if pusher comes then its missed (as we unsubscribe on unmount). So, fetch new message on chatId change for safety
  useEffect(() => {
    if (chatId) {
      void fetchNewMessage();
    }
  }, [chatId, fetchNewMessage]);

  const startPolling = useEventCallback((since?: number) => {
    isPolling.current = true;
    since && (pollSince.current = since);

    clearTimer();
    timer.current = setTimeout(() => {
      void fetchNewMessage().finally(() => {
        // to work as interval
        isPolling.current && startPolling();
      });
    }, POLLING_INTERVAL);
  });

  useEffect(() => {
    return () => clearTimer();
  }, [clearTimer]);

  return { stopPolling, startPolling };
}

export default usePollForNewMessages;
