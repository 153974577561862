import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import type { AlertComponentType } from '@unifyapps/defs/blocks/Alert/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importAlertComponent = () =>
  import(
    /* webpackChunkName: "alert-block" */
    './Alert'
  );

const Alert = lazy(importAlertComponent);

class AlertBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Alert;
  }

  get type(): ComponentTypeUnionType {
    return 'Alert';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as AlertComponentType, ['content', 'appearance', 'componentType']),
    });
  }

  preload() {
    return {
      blockPreload: importAlertComponent,
    };
  }
}

export default AlertBlockStateDefinition;
