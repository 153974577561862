import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type {
  CopilotBlockRef,
  CopilotBlockStateType,
  CopilotComponentType,
} from '@unifyapps/defs/blocks/Copilot/types';
import { CopilotEventTypes } from '@unifyapps/defs/blocks/Copilot/types';
import type { Action } from '@unifyapps/defs/types/action';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import Copilot from './Copilot';

class CopilotBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Copilot;
  }

  get type(): ComponentTypeUnionType {
    return 'Copilot';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: CopilotEventTypes.SubmitMessage,
        label: 'Send Message',
        method: (block: BlockStateUnionType, action: Action, blockRef?: CopilotBlockRef) => {
          const methodPayload = action.payload?.methodPayload as
            | { message: string; attachments?: FileType[] }
            | undefined;
          if ((block as CopilotBlockStateType).content.aiAgentId) {
            if (methodPayload?.message) {
              blockRef?.submitMessage?.({
                message: methodPayload.message,
                attachments: methodPayload.attachments,
              });
            }

            return Promise.resolve();
          }

          return {
            ...block,
            errorMessage: 'Please select an AI Agent ID',
          };
        },
      },
      {
        id: CopilotEventTypes.CreateNewChat,
        label: 'Create New Chat',
        method: (_: BlockStateUnionType, __: Action, blockRef?: CopilotBlockRef) => {
          blockRef?.createNewChat?.();

          return Promise.resolve();
        },
      },
      {
        id: CopilotEventTypes.GoToChat,
        label: 'Go to Chat',
        method: (_: BlockStateUnionType, action: Action, blockRef?: CopilotBlockRef) => {
          const methodPayload = action.payload?.methodPayload as { chatId: string };
          blockRef?.goToChat?.(methodPayload.chatId);

          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CopilotComponentType, ['content', 'appearance', 'componentType']),
    });
  }
}

export default CopilotBlockStateDefinition;
