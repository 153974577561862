import BaseTimer from '@unifyapps/ui/components/Timer';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import { useCallback, useEffect, useImperativeHandle, useRef, memo } from 'react';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import type { TimerBlockStateType, TimerComponentType } from '@unifyapps/defs/blocks/Timer/types';
import type { TimerRef } from '@unifyapps/ui/components/Timer/Timer';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import clsx from 'clsx';

function Timer({
  dataAttributes,
  blockRef,
  updateBlockState,
  computedBlockState,
  events,
}: BlockComponentProps<TimerComponentType, TimerBlockStateType, TimerBlockStateType>) {
  const { content, appearance } = computedBlockState;
  const timerRef = useRef<TimerRef>(null);

  const { startValue, endValue, mode, format, interval, autostart } = content;
  const autoStartTimerRef = useRef<{ autostart: boolean }>({ autostart: false });

  const eventWithTimeInterval = events?.find(
    (event) => (event.eventType as EventTypeEnum) === EventTypeEnum.OnTimerIntervalElapsed,
  );

  const { weight, variant, styles, color } = appearance ?? {};
  const { emitEvent } = useBlockEvents(events);

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  useImperativeHandle(blockRef, () => ({
    start: timerRef.current?.start,
    stop: timerRef.current?.stop,
    reset: timerRef.current?.reset,
  }));

  const onCurrentValueUpdate = useCallback(
    (newTime: number) => {
      updateBlockState((draft) => {
        draft.currentValue = newTime;
      });
    },
    [updateBlockState],
  );

  const onTimerExpire = useCallback(() => {
    void emitEvent({
      eventType: EventTypeEnum.OnTimerExpire,
    });
  }, [emitEvent]);

  const onTimerIntervalElapsed = useCallback(() => {
    void emitEvent({
      eventType: EventTypeEnum.OnTimerIntervalElapsed,
    });
  }, [emitEvent]);

  useEffect(() => {
    if (!autoStartTimerRef.current.autostart && autostart) {
      timerRef.current?.reset();
      timerRef.current?.start();
    }
    autoStartTimerRef.current.autostart = Boolean(autostart);
  }, [content, autostart, updateBlockState]);

  return (
    <BaseTimer
      autostart={autostart}
      className={clsx(className, color)}
      endValue={endValue}
      eventTimeInterval={eventWithTimeInterval?.action.timeInterval}
      format={format}
      interval={interval}
      key={mode}
      mode={mode}
      onCurrentValueUpdate={onCurrentValueUpdate}
      onTimerExpire={onTimerExpire}
      onTimerIntervalElapsed={onTimerIntervalElapsed}
      ref={timerRef}
      startValue={startValue}
      style={style}
      variant={variant}
      weight={weight}
      {...dataAttributes}
    />
  );
}

export default memo(Timer);
