import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importProgressComponent = () =>
  import(
    /* webpackChunkName: "progress-block" */
    './Progress'
  );

const Progress = lazy(importProgressComponent);

class ProgressBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Progress;
  }

  get type(): ComponentTypeUnionType {
    return 'ProgressBar';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  preload() {
    return {
      blockPreload: importProgressComponent,
    };
  }
}

export default ProgressBlockStateDefinition;
