import _set from 'lodash/set';
import { createDraft, finishDraft } from 'immer';
import { ACTION_PREFIX, EVENT_PREFIX, NEW_BLOCK_ID } from '@unifyapps/carbon/no-code/const';
import type { BlockType } from '@unifyapps/defs/types/block';
import { ActionType } from '@unifyapps/defs/types/action';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { CopilotEventTypes } from '@unifyapps/defs/blocks/Copilot/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';

export const getActiveChatId = (chatId: string | null) => (chatId ? chatId : 'new');

export const getChatIdQueryParamKey = (blockId: string) => `${blockId}-chatId`;

const getMessageInputDefaultEvents = (
  targetBlockId: string,
  message: string,
  attachments?: string | FileType[],
) => [
  {
    action: {
      actionType: ActionType.ControlBlockMethod,
      payload: {
        blockId: targetBlockId,
        methodPayload: {
          message,
          attachments,
        },
        methodName: CopilotEventTypes.SubmitMessage,
      },
      id: getIdWithPrefix(ACTION_PREFIX),
    },
    id: getIdWithPrefix(EVENT_PREFIX),
    eventType: EventTypeEnum.OnSendMessage,
  },
];

export const composeCopilot = ({
  rootBlock,
  childBlocks,
}: {
  rootBlock: BlockType;
  childBlocks: Record<string, BlockType>;
}) => {
  const draftRootBlock = createDraft(rootBlock);
  const draftChildBlocks = createDraft(childBlocks);

  const {
    messageListContainer,
    messageListHeader,
    messageListBodyContainer,
    messageListBodyRepeatable,
    messageCard,
    messageListHeaderTitle,
    newChatButton,
    messageSummary,
    messageTimestamp,
    messageInputContainer,
    messageInput,
  } = draftChildBlocks;

  const messageInputDefaultEvents = getMessageInputDefaultEvents(
    draftRootBlock.id,
    `{{${messageInput.id}['value']}}`,
    `{{${messageInput.id}['attachments']}}`,
  );

  _set(
    messageTimestamp,
    'component.content.value',
    `{{utils.formatTime(Date.now() - ${messageListBodyRepeatable.id}['context']['item']['createdTime'], undefined, undefined, 1)}} ago`,
  );

  _set(
    messageSummary,
    'component.content.value',
    `{{${messageListBodyRepeatable.id}['context']['item']['summary']}}`,
  );

  //set individual message list card block ids
  _set(messageCard, 'component.slots.header', {
    blockId: messageTimestamp.id,
    wrappedInLayout: true,
  });
  _set(messageCard, 'component.slots.body', { blockId: messageSummary.id, wrappedInLayout: true });
  _set(messageSummary, 'parentId', messageCard.id);
  _set(messageTimestamp, 'parentId', messageCard.id);

  //set message list card as a repeatable block
  _set(messageListBodyRepeatable, 'component.content.blockId', messageCard.id);
  _set(messageCard, 'parentId', messageListBodyRepeatable.id);

  //set message list header block ids
  _set(messageListHeader, 'component.content.blockIds', [
    messageListHeaderTitle.id,
    newChatButton.id,
    NEW_BLOCK_ID,
  ]);
  _set(newChatButton, 'parentId', messageListHeader.id);
  _set(messageListHeaderTitle, 'parentId', messageListHeader.id);

  _set(messageListBodyContainer, 'component.content.blockIds', [
    messageListBodyRepeatable.id,
    NEW_BLOCK_ID,
  ]);
  _set(messageListBodyRepeatable, 'parentId', messageListBodyContainer);

  _set(messageListContainer, 'component.content.blockIds', [
    messageListHeader.id,
    messageListBodyContainer.id,
    NEW_BLOCK_ID,
  ]);
  _set(messageListHeader, 'parentId', messageListContainer.id);
  _set(messageListBodyContainer, 'parentId', messageListContainer.id);

  //set message list container card block id in copilot root block
  _set(draftRootBlock, 'component.slots.messageHistory.blockId', messageListContainer.id);
  _set(messageListContainer, 'parentId', draftRootBlock.id);

  _set(
    draftRootBlock,
    'component.content.messageHistoryRepeatableBlockId',
    messageListBodyRepeatable.id,
  );
  _set(messageListBodyRepeatable, 'parentId', draftRootBlock.id);

  _set(messageInput, 'events', messageInputDefaultEvents);

  //set messageInput block in messageInputContainer
  _set(messageInputContainer, 'component.content.blockIds', [messageInput.id]);

  //set messageInputContainer in copilot slot
  _set(draftRootBlock, 'component.slots.messageInputPanel.blockId', messageInputContainer.id);

  return {
    rootBlock: finishDraft(draftRootBlock),
    childBlocks: finishDraft(draftChildBlocks),
  };
};
