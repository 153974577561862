import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import AIAutomations from '../AIAutomations';

const useCopilotActions = (automationsConfig?: AutomationConfig) => {
  const createCase = useEventCallback(
    (
      variables: {
        message?: string;
        userId: string;
        caseId?: string;
        contextMessageId?: string; // this is passed when user clicks on a button in message. This is used to track the context of the message.
      } & Record<string, unknown>,
    ) => {
      const automationId = AIAutomations.getCreateCase(automationsConfig);
      return {
        automationId,
        synchronous: true,
        debugParams: { createCase: automationId },
        parameters: {
          copilotType: automationsConfig?.type,
          ...variables,
        },
      };
    },
  );

  const fetchMessage = useEventCallback((messageId: string) => {
    const automationId = AIAutomations.getFetchMessageById(automationsConfig);
    return {
      automationId,
      synchronous: true,
      debugParams: { fetchMessageById: automationId },
      parameters: { messageId, copilotType: automationsConfig?.type },
    };
  });

  const messageAttachment = useEventCallback((messageId?: string, caseId?: string) => {
    const automationId = AIAutomations.getFetchMessageAttachment(automationsConfig);
    return {
      automationId,
      synchronous: true,
      debugParams: { fetchMessageAttachment: automationId },
      parameters: { messageId, caseId },
    };
  });

  const clearConversation = useEventCallback((caseId?: string) => {
    const automationId = AIAutomations.getClearMessageList(automationsConfig);
    return {
      automationId,
      synchronous: true,
      debugParams: { clearMessageList: automationId },
      parameters: { caseId },
    };
  });

  const fetchConversation = useEventCallback(
    (
      variables: {
        caseId: string;
      } & Record<string, unknown>,
    ) => {
      const automationId = AIAutomations.getFetchConversation(automationsConfig);
      return {
        automationId,
        synchronous: true,
        debugParams: { fetchConversation: automationId, copilotType: automationsConfig?.type },
        parameters: {
          copilotType: automationsConfig?.type,
          ...variables,
        },
      };
    },
  );

  const retryCase = useEventCallback(
    (
      variables: {
        message: string;
        userId: string;
        caseId: string;
        responseId: string;
      } & Record<string, unknown>,
    ) => {
      const automationId = AIAutomations.getRetryCase(automationsConfig);
      return {
        automationId,
        debugParams: { retryCase: automationId },
        synchronous: true,
        parameters: {
          ...variables,
        },
      };
    },
  );

  return {
    createCase,
    fetchMessage,
    messageAttachment,
    clearConversation,
    fetchConversation,
    retryCase,
  };
};

export default useCopilotActions;
