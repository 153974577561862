import _reduce from 'lodash/reduce';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import useDynamicMemo from '../useDynamicMemo';
import { getComputedData } from '../../utils/getComputedData';
import { useComputeConditionEvaluationGetter } from '../useComputeConditionEvaluationGetter';
import type { EntityDependency } from '../../stores/GlobalStateStore';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { EMPTY_ARRAY } from '../../../consts/empty';
import { evaluateResult } from '../../utils/evaluateExpression';
import { omitKeysFromBlock } from '../../utils/block';

interface UseComputeBlockStateProps {
  blockState: Record<string, unknown>;
  context: Record<string, unknown>;
}
// This hook is used to return a computed block from the blockState object
function useComputeBlockState<T>(props: UseComputeBlockStateProps) {
  const { blockState, context } = props;

  const blockDependencies =
    useGlobalStateStore().use.entityDependencies.dependencies(blockState.id as string) ??
    (EMPTY_ARRAY as EntityDependency[]);

  const dependentEntities = _reduce(
    blockDependencies,
    (acc, dependency) => {
      const result = evaluateResult<unknown>({
        context,
        resolvedStringToEvaluate: dependency.property,
        shouldEvaluateResult: true,
      });

      acc.push(result);

      return acc;
    },
    [] as unknown[],
  );

  const getComputedFilters = useComputeConditionEvaluationGetter();

  return useDynamicMemo(() => {
    const blockStateWithoutEvent = omitKeysFromBlock(blockState as BlockStateUnionType, ['events']);

    const blockStateWithComputedFilters = getComputedFilters(blockStateWithoutEvent, context);
    const blockId = blockState.id as string;

    const contextWithComputedFilters = {
      ...context,
      [blockId]: {
        ...(context[blockId] as Record<string, unknown>),
        ...(blockStateWithComputedFilters as Record<string, unknown>),
      },
    };

    const evaluatedBlockState = getComputedData<T>(
      blockStateWithComputedFilters as Record<string, unknown>,
      contextWithComputedFilters,
    );
    return { ...evaluatedBlockState, events: blockState.events } as T;
  }, [blockState, ...dependentEntities, getComputedFilters]);
}

export default useComputeBlockState;
