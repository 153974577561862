import type { IconComponentType } from '@unifyapps/ui/types/index';
import { useTranslation } from '@unifyapps/i18n/client';
import { useMemo } from 'react';
import Copy01 from '@unifyapps/icons/outline/Copy01';
import RefreshCcw05 from '@unifyapps/icons/outline/RefreshCcw05';
import ThumbsUp from '@unifyapps/icons/outline/ThumbsUp';
import ThumbsDown from '@unifyapps/icons/outline/ThumbsDown';
import getEnabledItems from '@unifyapps/carbon/utils/getEnabledItems';
import {
  FeedbackReaction,
  type BlockMessageContentType,
  type Message,
} from '@unifyapps/defs/blocks/Chat/types';
import AIAutomations from '@unifyapps/carbon/copilot/AIAutomations';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import { useChatContext } from '../../ChatContext';

export type SecondaryActionItem = {
  id: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>, message: BlockMessageContentType[]) => void;
  tooltipLabel: string;
  Icon: IconComponentType;
  active?: boolean;
};

export const useSecondaryActionItems = ({
  onRegenerateResponse,
  onLikeMessage,
  onDislikeMessage,
  onCopyMessage,
  isLastMessage,
  message,
  automationsConfig,
}: {
  onRegenerateResponse?: () => void;
  onLikeMessage?: () => void;
  onDislikeMessage?: () => void;
  onCopyMessage?: () => void;
  isLastMessage?: boolean;
  message: Message;
  automationsConfig?: AutomationConfig;
}): SecondaryActionItem[] => {
  const { t } = useTranslation('copilot');
  const { canRetry } = useChatContext();
  const feedbackEnabled = Boolean(AIAutomations.getFeedbackAutomation(automationsConfig));

  return useMemo(
    () =>
      getEnabledItems<SecondaryActionItem>([
        {
          id: 'copy-message',
          onClick: onCopyMessage,
          tooltipLabel: t('copilot:Actions.CopyResponse'),
          Icon: Copy01,
          enabled: Boolean(onCopyMessage),
        },
        {
          id: 'regenerate-response',
          onClick: onRegenerateResponse,
          tooltipLabel: t('copilot:Actions.RegenerateResponse'),
          Icon: RefreshCcw05,
          enabled: canRetry && isLastMessage && Boolean(onRegenerateResponse),
        },
        {
          id: 'like-message',
          onClick: onLikeMessage,
          tooltipLabel: t('copilot:Actions.GoodResponse'),
          Icon: ThumbsUp,
          enabled: feedbackEnabled,
          active: message.feedback?.reaction === FeedbackReaction.LIKE,
        },
        {
          id: 'dislike-message',
          onClick: onDislikeMessage,
          tooltipLabel: t('copilot:Actions.BadResponse'),
          Icon: ThumbsDown,
          enabled: feedbackEnabled,
          active: message.feedback?.reaction === FeedbackReaction.DISLIKE,
        },
      ]),
    [
      canRetry,
      feedbackEnabled,
      isLastMessage,
      message.feedback?.reaction,
      onCopyMessage,
      onDislikeMessage,
      onLikeMessage,
      onRegenerateResponse,
      t,
    ],
  );
};
