import SvgVideo from '@unifyapps/icons/colored/file/Video';
import SvgPdf from '@unifyapps/icons/colored/file/Pdf';
import File04 from '@unifyapps/icons/outline/File04';
import { clsx } from 'clsx';
import type { MediaType } from '../../MediaViewer/types';
import { isImage, isPdf, isVideo } from '../utils';
import { ImageWithFallback } from '../../ImageWithFallback';
import { IMAGE_FALLBACK_URL } from '../../MediaViewer/Viewers/ImageViewer';

export function MediaItem({
  item,
  iconClassName,
  imgClassName,
}: {
  item: MediaType;
  iconClassName?: string;
  imgClassName?: string;
}) {
  switch (true) {
    case isImage(item):
      return (
        <ImageWithFallback
          alt={item.caption}
          className={clsx('object-cover', imgClassName)}
          fallbackImgClassname={clsx('text-icon-tertiary', iconClassName)}
          fallbackSrc={IMAGE_FALLBACK_URL}
          key={`${item.id}-${item.url}`}
          src={item.url}
        />
      );
    case isVideo(item):
      return <SvgVideo className={clsx('text-icon-tertiary', iconClassName)} />;
    case isPdf(item):
      return <SvgPdf className={clsx('text-icon-tertiary', iconClassName)} />;
    default:
      return <File04 className={clsx('text-icon-tertiary', iconClassName)} />;
  }
}
