import type { CSSProperties } from 'react';
import { useCallback, useState } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import MediaViewer from '../MediaViewer';
import type { MediaType } from '../MediaViewer/types';
import type { MediaOverrides } from './types';
import { MediaVariants } from './types';
import { SpaciousMediaContainer } from './components/SpaciousMediaContainer';
import { CompactMediaContainer } from './components/CompactMediaContainer';
import { useAdaptedMedia } from './hooks/useAdaptedMedia';

type ViewerState = { open: boolean; initialIndex: number };

function Media({
  media,
  variant,
  className,
  size = 'md',
  maxUpfrontItems = 4,
  onRemoveItem,
  showCaption = false,
  cols = 2,
  style,
  overrides,
  itemClassName,
}: {
  media: string | string[] | MediaType | MediaType[];
  variant: MediaVariants;
  className?: string;
  onRemoveItem?: (id: string) => void;
  size?: 'sm' | 'md' | 'lg';
  maxUpfrontItems?: number;
  showCaption?: boolean;
  cols?: number;
  style?: CSSProperties | undefined;
  overrides?: MediaOverrides;
  itemClassName?: string;
}) {
  const [viewerState, setViewerState] = useState<ViewerState | null>(null);

  const adaptedMedia: MediaType[] = useAdaptedMedia(media);

  const onClose = useCallback(() => {
    setViewerState(null);
  }, []);

  const MediaContainer =
    variant === MediaVariants.Compact ? CompactMediaContainer : SpaciousMediaContainer;

  const openMediaViewer = useEventCallback((index: number) => {
    setViewerState({ open: true, initialIndex: index });
  });

  return (
    <>
      <MediaContainer
        className={className}
        cols={cols}
        itemClassName={itemClassName}
        maxUpfrontItems={maxUpfrontItems}
        media={adaptedMedia}
        onRemoveItem={onRemoveItem}
        openMediaViewer={openMediaViewer}
        overrides={overrides}
        showCaption={showCaption}
        size={size}
        style={style}
      />
      {viewerState?.open ? (
        <MediaViewer
          initialIndex={viewerState.initialIndex}
          medias={adaptedMedia}
          onClose={onClose}
        />
      ) : null}
    </>
  );
}

export default Media;
