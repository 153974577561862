'use client';
import type { BlockType, ComputedVisibilityPayloadType } from '@unifyapps/defs/types/block';
import { useCallback } from 'react';
import { useComputeConditionEvaluationGetter } from '../useComputeConditionEvaluationGetter';

export const isTargetVisible = (visibility: ComputedVisibilityPayloadType | undefined) => {
  if (!visibility) return true;

  if (visibility.value === 'conditions') {
    return visibility.conditions !== false;
  }

  return visibility.value !== false;
};

function useIsBlockVisibility() {
  const getComputedFilters = useComputeConditionEvaluationGetter();

  const getIsBlockVisibility = useCallback(
    (visible: BlockType['visibility'], context: Record<string, unknown> = {}) => {
      if (!visible) return true;

      if (visible.value === 'conditions') {
        const { conditions } = getComputedFilters<{
          conditions?: boolean;
        }>(visible, context);

        return conditions !== false;
      }

      return visible.value !== false;
    },
    [getComputedFilters],
  );

  return { getIsBlockVisibility };
}

export default useIsBlockVisibility;
