import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { SyntheticEvent } from 'react';
import { useCallback, useMemo, memo, lazy } from 'react';
import type { ImageComponentType, ImageBlockStateType } from '@unifyapps/defs/blocks/Image/types';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { Box } from '@unifyapps/ui/components/Box';
import { clsx } from 'clsx';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import _some from 'lodash/some';
import _set from 'lodash/set';

const MediaViewer = lazy(
  () =>
    import(
      /* webpackChunkName: "MediaViewer" */
      '@unifyapps/carbon/components/MediaViewer'
    ),
);

function Image({
  dataAttributes,
  events,
  computedBlockState,
  updateBlockState,
}: BlockComponentProps<ImageComponentType, ImageBlockStateType, ImageBlockStateType>) {
  const { content, appearance } = computedBlockState;
  const { src, alt } = content;

  const { isOpenInMediaViewer } = computedBlockState.state;

  const { emitEvent } = useBlockEvents(events, computedBlockState.id);

  const isClickEventPresent = useMemo(
    () => _some(events, { eventType: EventTypeEnum.OnClick }),
    [events],
  );

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnClick, domEvent: e });
    },
    [emitEvent],
  );

  const onClose = useCallback(() => {
    updateBlockState((draft) => {
      _set(draft, 'state.isOpenInMediaViewer', false);
    });
  }, [updateBlockState]);

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const adaptedMedia = useMemo(() => {
    return [{ url: src, id: src, mimeType: 'image' }];
  }, [src]);

  return (
    <>
      <Box
        {...dataAttributes}
        className={clsx('overflow-hidden', className)}
        onClick={onClick}
        style={style}
      >
        <img
          alt={alt}
          className={clsx('h-full w-full', appearance?.objectFit, {
            'cursor-pointer': isClickEventPresent,
          })}
          src={src}
        />
      </Box>
      {isOpenInMediaViewer ? <MediaViewer medias={adaptedMedia} onClose={onClose} /> : null}
    </>
  );
}

export default memo(Image);
