import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { AudioPlayerComponentType } from '@unifyapps/defs/blocks/AudioPlayer/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { Action } from '@unifyapps/defs/types/action';

const importAudioPlayerComponent = () =>
  import(
    /* webpackChunkName: "audio-player-block" */
    './AudioPlayer'
  );

const AudioPlayer = lazy(importAudioPlayerComponent);

class AudioPlayerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'AudioPlayer';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'seekToTimestamp' as const,
        label: 'Seek to time',
        method: (
          blockState: BlockStateUnionType,
          action: Action,
          blockRef: { seekToTimestamp: (timestamp: number) => void },
        ) => {
          const { methodPayload } = action.payload as {
            methodPayload: { timestamp: number };
          };

          blockRef.seekToTimestamp(methodPayload.timestamp);

          return blockState;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as AudioPlayerComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
      playbackSpeed: 1,
    });
  }

  getComponent() {
    return AudioPlayer;
  }

  preload() {
    return {
      blockPreload: importAudioPlayerComponent,
    };
  }
}

export default AudioPlayerBlockStateDefinition;
