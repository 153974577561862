//https://github.com/facebook/react/issues/18229#issuecomment-926482711

import { useEffect, useMemo, useRef } from 'react';

function sameDeps(a: unknown[], b: unknown[]): boolean {
  return a.length === b.length && a.every((value, index) => Object.is(value, b[index]));
}

function useDynamicMemo<T>(factory: () => T, deps: unknown[]): T {
  const ref = useRef<unknown[]>();
  const next = ref.current !== undefined && sameDeps(ref.current, deps) ? ref.current : deps;
  useEffect(() => {
    ref.current = next;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps -- factory is a constant
  return useMemo(factory, [next]);
}

export default useDynamicMemo;
