'use client';
import Stack from '@unifyapps/ui/_components/Stack';
import type { IdentityProvider as IdentityProviderType } from '@unifyapps/network/auth/types';
import { Typography } from '@unifyapps/ui/components/Typography';
import { Divider } from '@unifyapps/ui/components/Divider';
import IDPComponent from './IDPComponent';

function IdentityProvider({ idps }: { idps: IdentityProviderType[] }) {
  if (!idps.length) return null;

  const groupButtons = idps.filter((idp) => idp.uiConfig?.type === 'button');
  const groupForms = idps.filter((idp) => idp.uiConfig?.type === 'form');

  const isBothGroupsPresent = groupButtons.length > 0 && groupForms.length > 0;

  return (
    <>
      {groupButtons.length ? (
        <Stack className="gap-xl">
          {groupButtons.map((idp) => {
            return <IDPComponent identityProvider={idp} key={idp.id} />;
          })}
        </Stack>
      ) : null}
      {isBothGroupsPresent ? (
        <Divider>
          <Typography variant="text-xxxs">OR</Typography>
        </Divider>
      ) : null}
      {groupForms.length ? (
        <Stack>
          {groupForms.map((idp) => {
            return <IDPComponent identityProvider={idp} key={idp.id} />;
          })}
        </Stack>
      ) : null}
    </>
  );
}

export default IdentityProvider;
