import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importTimelineComponent = () =>
  import(
    /* webpackChunkName: "timeline-block" */
    './Timeline'
  );

const Timeline = lazy(importTimelineComponent);

class TimelineBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Timeline;
  }

  get type(): ComponentTypeUnionType {
    return 'Timeline';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  preload() {
    return {
      blockPreload: importTimelineComponent,
    };
  }
}

export default TimelineBlockStateDefinition;
