import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { KeyValueComponentType } from '@unifyapps/defs/blocks/KeyValue/types';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import { getFieldValues } from './helper';

const importKeyValueComponent = () =>
  import(
    /* webpackChunkName: "key-value-block" */
    './KeyValue'
  );

const KeyValue = lazy(importKeyValueComponent);

class KeyValueBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'KeyValue';
  }

  getEventTargetIds(block: BlockType) {
    const component = block.component as KeyValueComponentType;
    return component.content.fields.map((field) => field.id);
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
        values: getFieldValues((block.component as KeyValueComponentType).content.fields),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return KeyValue;
  }

  preload() {
    return {
      blockPreload: importKeyValueComponent,
    };
  }
}

export default KeyValueBlockStateDefinition;
