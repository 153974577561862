import { cva } from 'class-variance-authority';

export const decoratorVariants = cva('', {
  variants: {
    variant: {
      'display-2xl': 'w-7xl h-7xl',
      'display-xl': 'w-6xl h-6xl',
      'display-lg': 'w-5xl h-5xl',
      'display-md': 'size-9',
      'display-sm': 'size-8',
      'display-xs': 'size-7',
      'text-xl': 'size-6',
      'text-lg': 'size-5',
      'text-md': 'size-4',
      'text-sm': 'size-3',
      'text-xs': 'size-2',
      'text-xxs': 'size-1.5',
      'text-xxxs': 'size-1.5',
    },
  },
});
