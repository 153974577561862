import type { BlockType } from '@unifyapps/defs/types/block';
import { isEmptyBlock } from './block';
import getBlockPipeline from './blockPipeline';

export const getBlockEmptyInfo = (
  _block: BlockType,
): {
  emptySlots: Record<string, boolean> | null;
  emptyBlockId: boolean | null;
  emptyBlockIds: boolean[] | null;
  emptyItems: boolean[] | null;
} => {
  const { blocksPipeline } = getBlockPipeline();
  let emptySlots: Record<string, boolean> | null = null;
  let emptyBlockId: boolean | null = null;
  let emptyBlockIds: boolean[] | null = null;
  let emptyItems: boolean[] | null = null;

  blocksPipeline.execute({
    block: _block,
    handleSlotsBlock: (block) => {
      Object.entries(block.component.slots ?? {}).forEach(([slotName, slot]) => {
        if (emptySlots === null) {
          emptySlots = {};
        }
        emptySlots[slotName] = isEmptyBlock(slot.blockId);
      });
    },
    handleBlockIdBlock: (block) => {
      const bId = block.component.content.blockId;
      emptyBlockId = isEmptyBlock(bId);
    },
    handleBlockIdsBlock: (block) => {
      Object.entries(block.component.content.blockIds ?? {}).forEach(([index, bId]) => {
        if (emptyBlockIds === null) {
          emptyBlockIds = [];
        }
        emptyBlockIds[index] = isEmptyBlock(bId);
      });
    },
    handleContentBlock: (block) => {
      Object.entries(block.component.content.items ?? {}).forEach(([index, item]) => {
        if (emptyItems === null) {
          emptyItems = [];
        }
        emptyItems[index] = isEmptyBlock(item.blockId);
      });
    },
  });

  return {
    emptySlots,
    emptyBlockId,
    emptyBlockIds,
    emptyItems,
  };
};

export const getIsWholeBlockEmpty = (params: ReturnType<typeof getBlockEmptyInfo>) => {
  if (params.emptyBlockId) {
    return true;
  } else if (params.emptyBlockIds && params.emptyBlockIds.every(Boolean)) {
    return true;
  } else if (params.emptyItems && params.emptyItems.every(Boolean)) {
    return true;
  } else if (params.emptySlots && Object.values(params.emptySlots).every(Boolean)) {
    return true;
  }
  return false;
};
