import type { RJSFValidationError } from '@rjsf/utils';
import { toString } from 'lodash';
import type { TFunction } from '@unifyapps/i18n/client';

//github.com/ajv-validator/ajv/tree/6a671057ea6aae690b5967ee26a0ddf8452c6297?tab=readme-ov-file#error-parameters
type ErrorParams = {
  limit?: number;
  exclusive?: boolean;
  comparison?: '<' | '<=' | '>' | '>=';
  additionalProperty?: string;
  missingProperty?: string;
  property?: string;
  deps?: string;
  depsCount?: number;
  format?: string;
  multipleOf?: number;
  pattern?: string;
  type?: string;
  i?: number;
  j?: number;
  allowedValue?: unknown;
  allowedValues?: unknown[];
  propertyName?: string;
  missingPattern?: string;
  ref?: string;
  passingSchemas?: number[] | null;
  keyword?: string;
};

export type PathObj = {
  key: string;
  path: string;
  resolvedPath: string[];
};

const REGEX_LABEL = /'[^']+'$/;

// the label of the field is inside '' at the end of the string in the error.stack,
// for e.g "must have required property 'Name'"
const getLabelFromErrorStack = (stack: string) => {
  const match = REGEX_LABEL.exec(stack);
  return match ? match[0].slice(1, -1) : undefined;
};

export const transformErrors = ({
  errors,
  getLabelFromProperty,
  t,
}: {
  errors: RJSFValidationError[];
  getLabelFromProperty: (property?: string) => string | undefined;
  t: TFunction;
}) => {
  return errors.map((error) => {
    const labelFromProperty = getLabelFromProperty(error.property);
    const labelFromStack = getLabelFromErrorStack(error.stack);
    const label = labelFromProperty || labelFromStack;

    const params = error.params as ErrorParams | undefined;

    let i18nKey: string;
    const messageParams: Record<string, unknown> = { label };

    switch (error.name) {
      case 'required':
        i18nKey = 'Common:Form.error.required';
        break;

      case 'type':
        i18nKey = 'Common:Form.error.type';
        messageParams.type = params?.type;
        break;

      case 'minLength':
        i18nKey = 'Common:Form.error.minLength';
        messageParams.limit = params?.limit;
        break;

      case 'maxLength':
        i18nKey = 'Common:Form.error.maxLength';
        messageParams.limit = params?.limit;
        break;

      case 'minimum':
        i18nKey = 'Common:Form.error.minimum';
        messageParams.limit = params?.limit;
        break;

      case 'maximum':
        i18nKey = 'Common:Form.error.maximum';
        messageParams.limit = params?.limit;
        break;

      case 'pattern':
        i18nKey = 'Common:Form.error.pattern';
        messageParams.pattern = params?.pattern;
        break;

      case 'const':
        i18nKey = 'Common:Form.error.const';
        messageParams.allowedValue = toString(params?.allowedValue);
        break;

      case 'enum':
        i18nKey = 'Common:Form.error.enum';
        messageParams.allowedValues = params?.allowedValues?.join(', ');
        break;

      case 'multipleOf':
        i18nKey = 'Common:Form.error.multipleOf';
        messageParams.multipleOf = params?.multipleOf;
        break;

      case 'additionalProperties':
        i18nKey = 'Common:Form.error.additionalProperties';
        break;

      case 'format':
        i18nKey = 'Common:Form.error.format';
        messageParams.format = params?.format;
        break;

      case 'minItems':
        i18nKey = 'Common:Form.error.minItems';
        messageParams.limit = params?.limit;
        break;

      default:
        i18nKey = 'Common:Form.error.default';
        break;
    }

    // Assuming you have an i18n library initialized as `i18n`
    error.message = t(i18nKey, messageParams);
    return error;
  });
};
