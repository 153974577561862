import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { ComponentTypeUnionType, BlockType } from '@unifyapps/defs/types/block';
import {
  ButtonGroupModes,
  type ButtonGroupComponentType,
} from '@unifyapps/defs/blocks/ButtonGroup/types';
import _pick from 'lodash/pick';
import _map from 'lodash/map';
import ButtonGroup from './ButtonGroup';

class ButtonGroupBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'ButtonGroup';
  }

  getEventTargetIds(block: BlockType) {
    const component = block.component as ButtonGroupComponentType;
    const mode = component.content.mode;

    return mode === ButtonGroupModes.manual && component.content.options
      ? _map(component.content.options.data, (option) => option.id)
      : [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ButtonGroupComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
    });
  }

  getComponent() {
    return ButtonGroup;
  }
}

export default ButtonGroupBlockStateDefinition;
