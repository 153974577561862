import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { RepeatableBlockStateType } from '@unifyapps/defs/blocks/Repeatable/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';
import type { RepeatableComponentType } from '@unifyapps/defs/types/blocks';
import type { Action } from '@unifyapps/defs/types/action';

const importRepeatableComponent = () =>
  import(
    /* webpackChunkName: "repeatable-block" */
    './Repeatable'
  );

const Repeatable = lazy(importRepeatableComponent);

class RepeatableBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Repeatable';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'selectNextItem' as const,
        label: 'Select Next Item',
        method: (
          blockState: RepeatableBlockStateType,
          action: Action,
          blockRef: {
            selectNextItem: () => {
              nextItem: unknown;
              nextItemKey: string | undefined;
              nextItemIndex: number;
            };
          },
        ) => {
          const primaryKey = blockState.content.primaryKey;

          if (primaryKey) {
            const { nextItem, nextItemKey, nextItemIndex } = blockRef.selectNextItem();

            return {
              ...blockState,
              selectedItemKey: nextItemKey,
              selectedItem: nextItem,
              selectedItemIndex: nextItemIndex,
            };
          }

          return blockState;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
        pagination: {
          currentPage: (block.component as RepeatableComponentType).content.addOns?.page?.current,
          offset: (block.component as RepeatableComponentType).content.addOns?.page?.offset,
          pageSize: (block.component as RepeatableComponentType).content.addOns?.page?.size,
          total: (block.component as RepeatableComponentType).content.addOns?.page?.total,
        },
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Repeatable;
  }

  preload() {
    return {
      blockPreload: importRepeatableComponent,
    };
  }
}

export default RepeatableBlockStateDefinition;
