import Stack from '@unifyapps/ui/_components/Stack';
import type { BlockComponentProps } from '../../../BlockRenderer/types';
import type { LoginLayoutComponentType } from '../../types';
import CreativeContent from '../CreativeContent';
import LoginContent from '../LoginContent';

function TwoColumnLayout(
  props: BlockComponentProps<
    LoginLayoutComponentType,
    Record<string, unknown>,
    Record<string, unknown>
  >,
) {
  const { creativeContent } = props.component;

  return (
    <Stack
      className="h-dvh w-full"
      direction={creativeContent?.position === 'left' ? 'row' : 'row-reverse'}
    >
      {creativeContent ? (
        <Stack className="border-secondary relative h-full w-full flex-shrink-0 basis-1/2 border-e">
          <CreativeContent creativeContent={creativeContent} />
        </Stack>
      ) : null}
      <Stack alignItems="center" className="mx-auto h-full w-full" justifyContent="center">
        <Stack alignItems="center" className="px-8xl bg-primary py-6xl" justifyContent="center">
          <LoginContent {...props} />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TwoColumnLayout;
