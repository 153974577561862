import { useCallback, useMemo, type CSSProperties, type SyntheticEvent } from 'react';
import { Button } from '@unifyapps/ui/components/Button';
import { Popover } from '@unifyapps/ui/components/Popover';
import { MenuList } from '@unifyapps/ui/components/Menu';
import type { Option, ButtonGroupBlockStateType } from '@unifyapps/defs/blocks/ButtonGroup/types';
import { useTranslation } from '@unifyapps/i18n/client';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { getConditionalIcon } from '@unifyapps/carbon/utils/conditionalValues';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import type { StyleObject } from '@unifyapps/defs/types/styleObject';
import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';

type Props = {
  dataAttributes: Record<string, string> | undefined;
  text: string;
  options: Option[];
  onAction: (id: string, label: string) => void;
  className?: string;
  style?: CSSProperties;
  color?: 'brand' | 'neutral' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  variant?: 'solid' | 'outline' | 'soft' | 'ghost';
  startDecorator?: string;
  endDecorator?: string;
  popupAppearance?: {
    styles?: Pick<StyleObject, 'maxHeight' | 'overflow'>;
  };
  isOpen?: boolean;
  updateBlockState: UpdateBlockStateType<ButtonGroupBlockStateType>;
};

export function DropdownButtonGroup({
  className,
  dataAttributes,
  text,
  options,
  onAction,
  color,
  size,
  variant,
  startDecorator,
  endDecorator,
  popupAppearance,
  isOpen,
  updateBlockState,
}: Props) {
  const { t } = useTranslation(['common']);

  const onOpenChange = useCallback(
    (open: boolean) => {
      updateBlockState((draft) => {
        draft.isOpen = open;
      });
    },
    [updateBlockState],
  );

  const handleMenuClick = useCallback(
    (id: string) => {
      onAction(id, options.find((option) => option.id === id)?.label ?? '');
      onOpenChange(false);
    },
    [onAction, onOpenChange, options],
  );

  const items = useMemo(
    () =>
      options.map((option) => ({
        id: option.id,
        title: option.appearance?.loading ? `${t('common:Loading')}...` : option.label,
        type: 'label' as const,
        disabled: option.appearance?.disabled,
        endDecoratorComponent: getIconFromRegistry(option.appearance?.endDecorator),
        startDecoratorComponent: getIconFromRegistry(option.appearance?.startDecorator),
      })),
    [options, t],
  );

  const { className: popupClassName, style: popupStyle } = useBlockAppearanceStyles({
    appearanceStyles: popupAppearance?.styles ?? {},
  });

  return (
    <Popover onOpenChange={onOpenChange} open={isOpen}>
      <Popover.Button>
        <Button
          className={className}
          color={color}
          endDecoratorComponent={getConditionalIcon(endDecorator)}
          size={size}
          startDecoratorComponent={getConditionalIcon(startDecorator)}
          variant={variant}
          {...dataAttributes}
        >
          {text}
        </Button>
      </Popover.Button>
      <Popover.Content useAnchorElWidth>
        <MenuList
          className={popupClassName}
          items={items}
          onAction={handleMenuClick}
          style={popupStyle}
        />
      </Popover.Content>
    </Popover>
  );
}
