import { EventTypeEnum } from '../../../types/event';

export const MAPPED_COLUMN_EVENT_TARGET_ID = 'mappedColumn';

export const mappedColumnSchema = {
  type: 'object',
  properties: {
    // dataSourceId: {
    //   type: 'string',
    // },
    data: {
      type: 'string',
    },
    label: {
      type: 'string',
    },
    fieldKey: {
      type: 'string',
    },
    type: {
      type: 'string',
      default: 'TEXT',
    },
    width: {
      type: 'string',
    },
    value: {
      type: 'string',
    },
    addOns: {
      type: 'object',
      properties: {
        fallbackValue: {
          type: 'string',
        },
      },
    },
    divider: {
      type: 'string',
    },
    columnActions: {
      type: 'array',
      items: {
        type: 'object',
      },
    },
    columnActionsDivider: {
      type: 'string',
    },
    editable: {
      type: 'object',
    },
    grow: {
      type: 'object',
    },
    align: {
      type: 'string',
      oneOf: [
        {
          title: 'Left',
          const: 'left',
          icon: 'TextAlignLeft',
        },
        {
          title: 'Center',
          const: 'center',
          icon: 'TextAlignCenter',
        },
        {
          title: 'Right',
          const: 'right',
          icon: 'TextAlignRight',
        },
      ],
      default: 'left',
    },
    interactions: {
      type: 'string',
    },
    interactionsDivider: {
      type: 'string',
    },
    permissions: {
      type: 'string',
    },
  },
};

export const mappedColumnUISchema = {
  data: {
    'ui:title': 'Data Source',
    'ui:widget': 'MappedDataSourceSelectorWidget',
    'ua:allowCustom': true,
    'ua:disableClearable': false,
    'ua:editorClassName': 'overflow-hidden !text-nowrap',
  },
  label: {
    'ui:title': 'Name',
    'ui:widget': 'MappedValueWidget',
    'ua:path': 'content.mappedColumn.data',
  },
  fieldKey: {
    'ui:title': 'Field Key',
    'ui:widget': 'MappedValueWidget',
    'ua:path': 'content.mappedColumn.data',
  },
  width: {
    'ui:title': 'Width',
    'ui:widget': 'MappedValueWidget',
    'ua:path': 'content.mappedColumn.data',
  },
  editable: {
    'ui:title': 'Editable',
    'ui:titleClassName': 'text-xxs text-tertiary font-normal',
    'ui:field': 'ConditionalToggleField',
    'ui:options': {
      size: 'sm',
    },
    'ua:options': {
      orientation: 'column',
    },
    'ui:fieldLayout': ['title', 'children'],
  },
  type: {
    'ui:title': 'Type',
    'ui:widget': 'MappedValueWidget',
    'ua:path': 'content.mappedColumn.data',
  },
  value: {
    'ui:title': 'Value',
  },
  addOns: {
    'ua:collapsible': false,
    'ua:disableBackground': true,
    'ui:title': false,
    fallbackValue: {
      'ui:title': 'Fallback Value',
    },
  },
  grow: {
    'ui:field': 'ConditionalToggleField',
    'ui:title': 'Grow',
    'ui:titleClassName': 'text-xxs text-tertiary font-normal',
    'ui:options': {
      size: 'sm',
    },
    'ua:options': {
      orientation: 'column',
    },
    'ui:fieldLayout': ['title', 'children'],
  },
  align: {
    'ui:title': 'Text alignment',
    'ui:widget': 'ToggleButtonGroupWidget',
    'ua:options': {
      labelVariant: 'text-xs',
      hasIcons: true,
      className: 'h-7',
    },
    'ui:fieldLayout': [['title', 'children']],
  },
  divider: {
    'ui:widget': 'DividerWidget',
    'ui:label': false,
  },
  columnActions: {
    'ui:title': 'Column Actions',
    'ui:field': 'ColumnActionsField',
    'ua:viewType': 'COLUMN_ACTION_DETAILS',
    'ua:basePropertyPath': 'component.content.mappedColumn.columnActions',
  },
  columnActionsDivider: {
    'ui:widget': 'DividerWidget',
    'ui:label': false,
  },
  interactions: {
    'ui:title': 'Events',
    'ui:field': 'InteractionsField',
    'ua:options': {
      targetId: MAPPED_COLUMN_EVENT_TARGET_ID,
      events: [
        {
          eventType: EventTypeEnum.OnChange,
          label: 'On Value Change',
        },
      ],
    },
  },
  interactionsDivider: {
    'ui:widget': 'DividerWidget',
    'ui:label': false,
  },
  permissions: {
    'ui:field': 'PermissionsField',
    'ui:title': 'Permissions',
  },
  'ui:layout': [
    'data',
    'label',
    'fieldKey',
    'type',
    'width',
    'value',
    'addOns',
    'editable',
    'grow',
    'align',
    'divider',
    'columnActions',
    'columnActionsDivider',
    'interactions',
    'interactionsDivider',
    'permissions',
  ],
};
