import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { TimerComponentType } from '@unifyapps/defs/blocks/Timer/types';
import type { Action } from '@unifyapps/defs/types/action';
import Timer from './Timer';

type TimerRef = {
  start: () => void;
  stop: () => void;
  reset: () => void;
};

const TWO_MINUTES = 2 * 60 * 1000;

class TimerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Timer';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'start',
        label: 'Start',
        method: (_: BlockStateUnionType, __: Action, blockRef?: TimerRef) => {
          blockRef?.start();

          return Promise.resolve();
        },
      },
      {
        id: 'stop',
        label: 'Stop',
        method: (_: BlockStateUnionType, __: Action, blockRef?: TimerRef) => {
          blockRef?.stop();

          return Promise.resolve();
        },
      },
      {
        id: 'reset',
        label: 'Reset',
        method: (_: BlockStateUnionType, __: Action, blockRef?: TimerRef) => {
          blockRef?.reset();

          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      currentValue: TWO_MINUTES,
      ..._pick(block.component as TimerComponentType, ['content', 'appearance', 'componentType']),
    });
  }

  getComponent() {
    return Timer;
  }
}

export default TimerBlockStateDefinition;
