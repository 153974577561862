import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  CitationBlockStateType,
  CitationComponentType,
} from '@unifyapps/defs/blocks/Citation/types';
import { memo } from 'react';
import { UpfrontCitationSources } from './components/UpfrontCitationSources';

function Citation(
  props: BlockComponentProps<CitationComponentType, CitationBlockStateType, CitationBlockStateType>,
) {
  return <UpfrontCitationSources citations={props.component.content.citations} />;
}

const MemoizedCitation = memo(Citation);

export default MemoizedCitation;
