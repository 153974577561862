import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';

export const getCitationTitle = (citation: CitationType) => {
  const { appName, title: _title, referenceUrl, name } = citation;
  const title = _title ?? name ?? referenceUrl;

  // TODO: to be removed
  if (appName === 'slack') {
    return `#${title}`;
  }
  return title;
};
