import { lazy } from 'react';
import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import _omit from 'lodash/omit';
import type { Action } from '@unifyapps/defs/types/action';
import type {
  DataTableBlockStateType,
  DataTableComponentType,
} from '@unifyapps/defs/blocks/Table/DataTable/types';
import { TABLE_SAVE_ACTION_TARGET_ID } from '@unifyapps/defs/blocks/Table/DataTable/tableSaveActionSchema';
import { MAPPED_COLUMN_EVENT_TARGET_ID } from '@unifyapps/defs/blocks/Table/DataTable/mappedColumnSchema';
import type { DataTableBlockRef } from './types';
import { DataTableColumnHelper } from './helpers/DataTableColumnHelper';
import { clearChangeset } from './utils/changeset';

const importTableComponent = () =>
  import(
    /* webpackChunkName: "table-block" */
    './Table'
  );

const Table = lazy(importTableComponent);

class TableBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Table'; // Todo: Rename to DataTable
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds(block: BlockType) {
    const eventTargetIds: string[] = [];

    if (block.component.componentType === 'Table') {
      block.component.content.addOns?.topToolbar?.customActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });

      // removed adding TABLE_SAVE_ACTION_TARGET_ID as default - as event was not getting removed
      if (block.component.content.addOns?.saveAction) {
        eventTargetIds.push(TABLE_SAVE_ACTION_TARGET_ID);
      }

      if (block.component.content.columnType === 'mapped') {
        eventTargetIds.push(MAPPED_COLUMN_EVENT_TARGET_ID);

        block.component.content.mappedColumn?.columnActions?.forEach((action) => {
          eventTargetIds.push(action.id);
        });
      }

      block.component.content.rowActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });

      DataTableColumnHelper.getColumns(block.component.content.columns).forEach((column) => {
        eventTargetIds.push(column.id);

        column.columnActions?.forEach((action) => {
          eventTargetIds.push(action.id);
        });
      });

      block.component.content.bulkActions?.forEach((action) => {
        eventTargetIds.push(action.id);
      });
    }

    return eventTargetIds;
  }

  getBlockControlMethods() {
    return [
      {
        id: 'clearChangeset' as const,
        label: 'Clear Changeset',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const tableBlockState = blockState as DataTableBlockStateType;
          const payload = action.payload as { methodPayload?: { changesetCursor: number } };

          return {
            ...tableBlockState,
            ...clearChangeset({
              changesetMap: tableBlockState.changesetMap,
              changesetList: tableBlockState.changesetList,
              identifier: tableBlockState.content.identifier,
              changesetCursor: payload.methodPayload?.changesetCursor,
            }),
          } as BlockStateUnionType;
        },
      },
      {
        id: 'refetchData' as const,
        label: 'Refetch Data',
        method: (_: BlockStateUnionType, __: Action, blockRef?: DataTableBlockRef) => {
          void blockRef?.refetch?.();
          return Promise.resolve();
        },
      },
      {
        id: 'exportData' as const,
        label: 'Export Data',
        method: (_: BlockStateUnionType, action: Action, blockRef?: DataTableBlockRef) => {
          const payload = action.payload as {
            methodPayload: { fileType: string; exportHiddenColumns?: boolean };
          };
          if (!blockRef?.exportData) {
            console.error('No exportData method found on blockRef');
          }
          blockRef?.exportData?.(payload.methodPayload);
          return Promise.resolve();
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['appearance', 'componentType', 'slots']),
        content: {
          ..._omit((block.component as DataTableComponentType).content, ['addOns']),
          ...(block.component as DataTableComponentType).content.addOns,
        },
        ..._pick((block.component as DataTableComponentType).content, ['addOns']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Table;
  }

  preload() {
    return {
      blockPreload: importTableComponent,
    };
  }
}

export default TableBlockStateDefinition;
