import type { JSONSchema7 } from 'json-schema';
import type { Props as DraggableTreeProps } from '../../../components/DraggableTree/DraggableTree';
import type { TreeItemDataType } from '../../../types';
import { schemaToFormType } from './schemaToFormData';
import { VariableType } from './types';

// getIsSection is section is used to determine whether a schema can have a child or not in the form in no-code
export const getIsSection = (schema: JSONSchema7) => {
  const type = schemaToFormType(schema);
  if (type === VariableType.Array) {
    // we check for schema.items schema because FileUpload field type, when it allows multiple file upload, it schema becomes
    // {type:'array', items: {type: 'object', format: 'file-upload'}}
    const items = schema.items as JSONSchema7 | undefined;
    if (!items) return false;
    const arrayType = schemaToFormType(items);
    // the items type should also be plain object then that means it is a simple schema without any formatter
    return arrayType === VariableType.Object;
  }
  return type === VariableType.Object;
};

export const canNodeHaveChild: DraggableTreeProps<TreeItemDataType>['canNodeHaveChild'] = ({
  data: { schema },
}) => {
  return getIsSection(schema);
};
