import { useMemo } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { ConfigType } from '@unifyapps/carbon/copilot/hooks/useFetchAIConfig';
import type { UIFilter } from '@unifyapps/defs/types/uiFilter';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import type { ChatContextType } from '../../Chat/components/ChatContext';
import { useInitialAwaitingContext } from '../../Chat/hooks/useInitialAwaitingContext';
import { useQueryParams } from './useQueryParams';

export const useChatPage = ({
  chatId,
  aiAgentId,
  chatIdQueryParamKey,
  filters,
  automationsConfig,
}: {
  chatId?: string;
  aiAgentId?: string;
  chatIdQueryParamKey: string;
  filters?: UIFilter;
  automationsConfig?: AutomationConfig;
}) => {
  const { setQueryParam } = useQueryParams();
  const [, setInitialIsAwaitingResponse] = useInitialAwaitingContext();

  const onLookupMessage = useEventCallback<NonNullable<ChatContextType['onLookupMessage']>>(
    (message) => {
      if (chatId === 'new' && message.caseId) {
        setInitialIsAwaitingResponse(message.isAwaitingResponse ?? false);
        setQueryParam(chatIdQueryParamKey, message.caseId);
      }
    },
  );

  const copilotType = automationsConfig?.type ?? ConfigType.AIAgent;

  return useMemo<ChatContextType>(
    () => ({
      onLookupMessage,
      getNewMessageContext: () => ({
        aiAgentId: aiAgentId ?? '',
        copilotType: copilotType as ConfigType.AIAgent,
        filters,
      }),
      hideChatInput: true,
      showCitations: true,
      automationsConfig,
    }),
    [aiAgentId, automationsConfig, copilotType, filters, onLookupMessage],
  );
};
