import { useCallback } from 'react';
import type { BlockMessageContentType, Message } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';
import { generateTypographyBlock } from '../helpers/generateTypographyBlock';
import { generateKeyValueBlock } from '../helpers/generateKeyValueBlock';
import { generateStatCardBlock } from '../helpers/generateStatCardBlock';
import { generateTableBlock } from '../helpers/generateTableBlock';
import { generateChartBlock } from '../helpers/generateChartBlock';
import { generateFormBlock } from '../helpers/generateFormBlock';
import { generateCitationBlock } from '../helpers/generateCitationBlock';
import { useChatContext } from '../components/ChatContext';
import { generateButtonBlock } from '../helpers/generateButtonBlock';
import { generateStackBlock } from '../helpers/generateStackBlock';
import { generateMediaBlock } from '../helpers/generateMediaBlock';

export const useGetBlockJson = () => {
  const { formBlockInput, showCitations } = useChatContext();

  return useCallback(
    (messageBlock: BlockMessageContentType, message?: Message) => {
      const getBlockJson = (block: BlockMessageContentType) => {
        switch (block.blockType) {
          case 'Typography':
            return generateTypographyBlock({
              data: block.data,
              appearance: block.appearance,
              isUserMessage: message?.messageType === MessageType.User,
            });
          case 'KeyValue':
            return generateKeyValueBlock({ data: block.data });
          case 'StatCard':
            return generateStatCardBlock({ data: block.data });
          case 'Table':
            return generateTableBlock({ data: block.data });
          case 'Chart':
            return generateChartBlock({ data: block.data, appearance: block.appearance });
          case 'Form':
            return generateFormBlock({
              data: block.data,
              schema: formBlockInput?.schema ?? {},
              layout: formBlockInput?.layout ?? {},
            });
          case 'Button':
            return generateButtonBlock(block, message);
          case 'Stack':
            return generateStackBlock(block, getBlockJson);
          case 'Citation':
            return generateCitationBlock({ data: block.data, showCitations });
          case 'Media':
            return generateMediaBlock({ data: block.data, messageType: message?.messageType });
          default:
            return null;
        }
      };
      return getBlockJson(messageBlock);
    },
    [formBlockInput?.layout, formBlockInput?.schema, showCitations],
  );
};
