import type { CardComponentType } from '@unifyapps/defs/blocks/Card/types';
import type { StackComponentType } from '@unifyapps/defs/blocks/Stack/types';
import type { TabsComponentType } from '@unifyapps/defs/blocks/Tabs/types';
import type { RepeatableComponentType } from '@unifyapps/defs/blocks/Repeatable/types';
import type { BlockType } from '@unifyapps/defs/types/block';

// these are all the different kinds of block structure we support
export type PropertiesPanelSupportedWidgetComponentType =
  | StackComponentType
  | TabsComponentType
  | CardComponentType
  | RepeatableComponentType;

export const defaultToFormData = (block: BlockType): unknown => {
  return {
    ...(block.component as PropertiesPanelSupportedWidgetComponentType).content,
    slots: (block.component as PropertiesPanelSupportedWidgetComponentType).slots,
  };
};
