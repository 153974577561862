import { usePrevious } from '@react-hookz/web';
import { useEffect, useState } from 'react';

const getIsChatIdPresent = (chatId?: string) => chatId && chatId !== 'new';

export const useGetCopilotKey = (chatId?: string) => {
  const prevChatId = usePrevious(chatId);

  const [key, setKey] = useState(0);

  // Note: Avoid remounting when switching from a new chat to some chat ID to prevent flickering during chat initialization.
  useEffect(() => {
    const isChatIdPresent = getIsChatIdPresent(chatId);
    const isPrevChatIdPresent = getIsChatIdPresent(prevChatId);

    // some chatId -> new chat, remount
    if (!isChatIdPresent && isPrevChatIdPresent) {
      setKey((prevKey) => prevKey + 1);
      return;
    }

    // some chatId -> some other chatId, remount
    if (isChatIdPresent && isPrevChatIdPresent && chatId !== prevChatId) {
      setKey((prevKey) => prevKey + 1);
    }
  }, [chatId, prevChatId]);

  return key;
};
