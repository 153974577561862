import type { InterfacePermissions } from '@unifyapps/defs/types/interface';
import { InterfaceModes } from '../../stores/InterfaceStore';
import type { UserPermissionsMap } from '../types';

type Args = {
  currentPermissions: UserPermissionsMap;
  permissionsToAccess?: InterfacePermissions;
  mode: InterfaceModes;
};

export const hasAccess = ({ currentPermissions, permissionsToAccess, mode }: Args): boolean => {
  if (mode !== InterfaceModes.RUNNER) {
    return true;
  }

  for (const groupId in permissionsToAccess) {
    for (const permission of permissionsToAccess[groupId]) {
      if (!currentPermissions[groupId]?.[permission]) {
        return false;
      }
    }
  }
  return true;
};
