import { forwardRef } from 'react';
import { Button } from '../Button';
import type { SSOButtonProps } from './types';
import { SSOButtonVariants } from './styles';

const SSOButton = forwardRef<HTMLButtonElement, React.PropsWithChildren<SSOButtonProps>>(
  (
    {
      startDecoratorComponent: StartDecoratorComponent,
      className,
      children,
      disabled,
      onClick,
      ...rest
    },
    ref,
  ): JSX.Element => {
    const startDecorator = StartDecoratorComponent ? (
      <StartDecoratorComponent className="fill-current" height={20} width={20} />
    ) : undefined;
    const _className = SSOButtonVariants({
      className,
      variant: children ? 'plain' : 'icon',
    });

    return (
      <Button
        className={_className}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        startDecoratorNode={startDecorator}
        {...rest}
        color="neutral"
        size="md"
        variant="outline"
      >
        {children}
      </Button>
    );
  },
);

SSOButton.displayName = 'SSOButton';
export default SSOButton;
