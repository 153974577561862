import { useMemo } from 'react';
import _castArray from 'lodash/castArray';
import type { MediaItemType } from '@unifyapps/defs/blocks/Media/types';
import { getIdWithPrefix } from '../../../utils/id';
import { MEDIA_ID_PREFIX } from '../../../no-code/const';

export const useAdaptedMedia = (media: string | string[] | MediaItemType | MediaItemType[]) => {
  return useMemo(() => {
    const mediaItems = _castArray(media);

    return mediaItems.map((mediaItem) => {
      if (typeof mediaItem === 'string') {
        return { url: mediaItem, id: getIdWithPrefix(MEDIA_ID_PREFIX) };
      }

      return mediaItem;
    });
  }, [media]);
};
