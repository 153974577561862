/* eslint-disable @typescript-eslint/no-extraneous-class -- needed*/
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import { AIActions } from '@unifyapps/defs/blocks/Copilot/types';

class AIAutomations {
  /*
   * automation to run for new message
   */
  static getCreateCase(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.CreateCase];
  }

  /*
   * to fetching message attachment by id
   */
  static getFetchMessageAttachment(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.FetchMessageAttachment];
  }

  /*
   * to fetch specific message by id, used when user news message is received on mqtt
   */
  static getFetchMessageById(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.FetchMessageById];
  }

  /*
   * to clear conversation
   */
  static getClearMessageList(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.ClearMessageList];
  }

  /*
   * to fetch conversation
   */
  static getFetchConversation(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.FetchConversation];
  }

  /*
   * to fetch case list
   */
  static getFetchCaseList(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.FetchCaseList];
  }

  /*
   * to retry case
   */
  static getRetryCase(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.RetryCase];
  }

  /*
   * to fetch quick questions
   */
  static getQuickQuestions(automationsConfig?: AutomationConfig) {
    return automationsConfig?.QuickQuestions;
  }

  /*
   * to post feedback on message
   */
  static getFeedbackAutomation(automationsConfig?: AutomationConfig) {
    return automationsConfig?.[AIActions.FeedbackAutomation];
  }
}

export default AIAutomations;
