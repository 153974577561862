import type { BlockMessageContentType, StackBlockParams } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import type { StackComponentType } from '@unifyapps/defs/blocks/Stack/types';
import _reduce from 'lodash/reduce';

export const generateStackBlock = (
  data: StackBlockParams,
  getBlockJson: (
    block: BlockMessageContentType,
  ) => { blocks: Record<string, unknown>; containerId: string } | null,
): {
  blocks: Record<string, { id: string; component: StackComponentType }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX);
  const content = _reduce(
    data.data,
    (acc, block) => {
      const newBlock = getBlockJson(block);
      if (!newBlock?.containerId) return acc;

      acc.blocks = { ...acc.blocks, ...newBlock.blocks };
      acc.blockIds.push(newBlock.containerId);

      return acc;
    },
    {
      blocks: {},
      blockIds: [] as string[],
    },
  );

  return {
    containerId: blockId,
    blocks: {
      ...content.blocks,
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Stack',
          appearance: data.appearance,
          content: { blockIds: content.blockIds },
        },
      },
    },
  };
};
