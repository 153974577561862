import { default as BaseButton } from '@mui/joy/Button';
import ButtonGroupContext from '@mui/joy/ButtonGroup/ButtonGroupContext';
import { forwardRef, useContext } from 'react';
import type { ButtonGroupButtonProps, ButtonGroupSizes } from './types';
import { buttonGroupButtonBaseClassname, buttonGroupButtonSizeVariants } from './styles';
import { buttonGroupIconSizeMap } from './utils';

const ButtonGroupButton = forwardRef<HTMLButtonElement, ButtonGroupButtonProps>(
  (props, ref): JSX.Element => {
    const {
      size: sizeProp,
      startDecoratorComponent: StartDecoratorComponent,
      startDecoratorNode,
      endDecoratorComponent: EndDecoratorComponent,
      endDecoratorNode,
      className,
      ...rest
    } = props;
    const buttonGroup = useContext(ButtonGroupContext);

    // @ts-expect-error -- buttonGroup.size is of same type as ButtonGroupSizes. We are not overriding internal types currently
    const size: ButtonGroupSizes = sizeProp ?? buttonGroup.size ?? 'sm';

    const iconSize = buttonGroupIconSizeMap[size];

    const startDecorator = StartDecoratorComponent ? (
      <StartDecoratorComponent height={iconSize} width={iconSize} />
    ) : (
      startDecoratorNode
    );

    const endDecorator = EndDecoratorComponent ? (
      <EndDecoratorComponent height={iconSize} width={iconSize} />
    ) : (
      endDecoratorNode
    );

    const buttonClassName = buttonGroupButtonSizeVariants({
      className: [buttonGroupButtonBaseClassname, className],
      size,
    });

    return (
      <BaseButton
        ref={ref}
        {...rest}
        className={buttonClassName}
        endDecorator={endDecorator}
        startDecorator={startDecorator}
      />
    );
  },
);

ButtonGroupButton.displayName = 'ButtonGroupButton';

export default ButtonGroupButton;
