import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  ContainerComponentType,
  ContainerBlockStateType,
} from '@unifyapps/defs/blocks/Container/types';
import { useRegistryContext } from '@unifyapps/carbon/no-code/components/RegistryProvider';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import _omit from 'lodash/omit';
import { useMemo, memo } from 'react';
import { BlockDataAttributes } from '@unifyapps/carbon/no-code/hooks/useBlockDataAttributes';
import { ContainerVariants } from './variants';

function Container({
  blockState,
  dataAttributes: _dataAttributes,
}: BlockComponentProps<ContainerComponentType, ContainerBlockStateType, ContainerBlockStateType>) {
  const { registry } = useRegistryContext();
  const BlockRenderer = registry.getBlockRenderer();

  const { variant } = blockState.appearance;

  const VariantComponent = ContainerVariants[variant];

  const appearance = blockState.appearance;

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance.styles,
  });

  const dataAttributes = useMemo(() => {
    return { ..._dataAttributes, [BlockDataAttributes.BlockVariant]: variant };
  }, [_dataAttributes, variant]);

  if (VariantComponent) {
    return (
      <VariantComponent
        BlockRenderer={BlockRenderer}
        className={className}
        component={_omit(blockState, 'events')}
        dataAttributes={dataAttributes}
        style={style}
      />
    );
  }

  if (process.env.NODE_ENV === 'development') {
    throw new Error(
      `Container variant "${variant}" not found. This is an highly unexpected error.`,
    );
  }

  return null;
}

export default memo(Container);
