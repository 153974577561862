import { cva } from 'class-variance-authority';

export const SSOButtonVariants = cva('!gap-lg', {
  variants: {
    variant: {
      icon: 'p-md',
      plain: 'px-xl py-md',
    },
  },
});
