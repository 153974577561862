import { useCallback, useState } from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { useFileUpload } from '../../../fields/FileUploaderField/hooks/useFileUpload';

export const useAttachmentsUpload = ({
  referenceId,
  onAttachmentsUpdate,
}: {
  referenceId: string;
  onAttachmentsUpdate?: (files?: FileType[]) => void;
}) => {
  const [attachments, setAttachments] = useState<FileType[] | undefined>(undefined);

  const onAttachmentsChange = useCallback(
    (_files?: FileType[]) => {
      setAttachments(_files);
      onAttachmentsUpdate?.(_files);
    },
    [onAttachmentsUpdate],
  );

  const { uppy, isUploading, clearAll, removeFileById } = useFileUpload({
    referenceId,
    onChange: onAttachmentsChange,
    onAllFilesUploaded: onAttachmentsChange,
  });

  const onRemoveAttachment = useEventCallback((id: string) => {
    removeFileById(id);
    onAttachmentsChange(attachments?.filter((item) => item.id !== id));
  });

  const onClearAll = useEventCallback(() => {
    clearAll();
    onAttachmentsChange([]);
  });

  return { uppy, attachments, isUploading, onClearAll, onRemoveAttachment };
};
