import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#CFD4DE"
      strokeWidth={1.25}
      d="M3.025 4A3.375 3.375 0 0 1 6.4.625h8a.1.1 0 0 1 .07.03l6.476 6.475a.1.1 0 0 1 .029.07V20a3.375 3.375 0 0 1-3.375 3.375H6.4A3.375 3.375 0 0 1 3.025 20z"
    />
    <g stroke="#5C37EB" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
      <path d="M12 18.9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9" />
      <path d="M10.875 13.034c0-.214 0-.322.045-.382a.23.23 0 0 1 .164-.09c.075-.005.165.054.346.17l2.124 1.365c.157.101.235.151.262.215a.23.23 0 0 1 0 .176c-.027.064-.105.114-.262.215l-2.124 1.365c-.181.117-.271.175-.346.17a.23.23 0 0 1-.164-.09c-.045-.06-.045-.168-.045-.382z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M6.6 9h10.8v10.8H6.6z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgVideo;
