import { useTranslation } from '@unifyapps/i18n/client';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';

export function FallbackComponent() {
  const { t } = useTranslation(['copilot']);

  return (
    <Box>
      <Typography as="div" color="text-tertiary" variant="text-md">
        {t('copilot:MessageContentPrimaryErrorMessage')}
      </Typography>
      <Typography as="div" color="text-tertiary" variant="text-md">
        {t('copilot:MessageContentSecondaryErrorMessage')}
      </Typography>
    </Box>
  );
}
