import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { MessageInputComponentType } from '@unifyapps/defs/blocks/MessageInput/types';
import MessageInput from './MessageInput';

class MessageInputBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'MessageInput';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as MessageInputComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
      value: '',
    });
  }

  getComponent() {
    return MessageInput;
  }
}

export default MessageInputBlockStateDefinition;
