import type { TableData } from '@unifyapps/defs/blocks/Chat/types';
import type { DataTableComponentType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import _uniqueId from 'lodash/uniqueId';

export const generateTableBlock = ({
  data,
}: {
  data: TableData;
}): {
  blocks: Record<string, { id: string; component: DataTableComponentType }>;
  containerId: string;
} => {
  const { rowData, columns } = data;

  const blockId = getIdWithPrefix(BLOCK_PREFIX);

  const evaluatedData = rowData.map((datum) => ({
    columns: Object.keys(datum).reduce(
      (acc, key) => ({ ...acc, [key.replace(/\s/g, '')]: datum[key] }),
      {},
    ),
  }));

  return {
    containerId: blockId,
    //@ts-expect-error -- this is evaluated data
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Table',
          appearance: {
            gridLineAxis: 'both',
            rowHeight: 'comfortable',
          },
          // metadata: {
          //   entityType: '',
          //   group: 'CUSTOM',
          //   enableTopToolbar: false,
          //   layoutMode: 'semantic',
          // },
          content: {
            data: evaluatedData,
            columns: columns.map((column) => ({
              ...column,
              id: column.id ?? _uniqueId('column'),
              fieldKey: { path: `columns.${(column.id ?? column.label).replace(/\s/g, '')}` },
              addOns: {
                ...column.addOns,
                fallbackValue: '-',
              },
            })),
            layoutMode: 'semantic',
          },
        },
      },
    },
  };
};
