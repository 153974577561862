import { produce } from 'immer';
import get from 'lodash/get';
import unset from 'lodash/unset';
import isEqual from 'react-fast-compare';
import type {
  ChangesetMapType,
  ChangesetListType,
  ChangesetUpdateFieldsType,
} from '@unifyapps/defs/types/changeset';
import type { DataTableBlockStateType } from '@unifyapps/defs/blocks/Table/DataTable/types';
import { getFieldKey } from '../columnFieldKey';

const DEFAULT_CHANGESET_VALUES = {
  changesetMap: undefined,
  changesetList: undefined,
  changesetCursor: undefined,
};

interface RemoveItemsFromChangesetMapParams {
  changesetMap: ChangesetMapType;
  changesetList: ChangesetListType;
  rowIdPath: string;
}

const removeItemsFromChangesetMap = (params: RemoveItemsFromChangesetMapParams) => {
  const { changesetMap, changesetList, rowIdPath } = params;

  return produce(changesetMap, (draft) => {
    changesetList.forEach((changesetListItem) => {
      const rowId = get(changesetListItem, rowIdPath) as string;

      (changesetListItem.updateFields as ChangesetUpdateFieldsType).forEach((updateField) => {
        const path = `${rowId}.${updateField.fieldName}`;

        if (isEqual(get(draft, path), updateField.value)) {
          unset(draft, path);
        }
      });
    });
  });
};

type ClearChangesetParams = Pick<DataTableBlockStateType, 'changesetMap' | 'changesetList'> & {
  identifier: DataTableBlockStateType['content']['identifier'];
  changesetCursor?: number;
};

export const clearChangeset = (params: ClearChangesetParams) => {
  const { changesetMap, changesetList, changesetCursor, identifier } = params;
  const rowIdPath = getFieldKey(identifier);

  if (!changesetList || !changesetMap || typeof changesetCursor !== 'number' || !rowIdPath) {
    return DEFAULT_CHANGESET_VALUES;
  }

  if (changesetCursor >= changesetList.length || Number.isNaN(changesetCursor)) {
    return DEFAULT_CHANGESET_VALUES;
  }

  const nextChangesetList = changesetList.slice(changesetCursor);

  return {
    changesetMap: removeItemsFromChangesetMap({
      changesetMap,
      changesetList: changesetList.slice(0, changesetCursor),
      rowIdPath,
    }),
    changesetList: nextChangesetList,
    changesetCursor: nextChangesetList.length,
  };
};
