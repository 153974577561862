import type { InterfacePageType } from '@unifyapps/defs/types/page';
import type { BlockType } from '@unifyapps/defs/types/block';
import type { MediaComponentType } from '@unifyapps/defs/blocks/Media/types';

export const getAttachmentsBlockId = (blockId: string) => `attachments-${blockId}`;

export const generateMessage = ({
  messageId,
  blocks,
  attachmentBlocks,
}: {
  messageId: string;
  blocks: { containerId: string; blocks: Record<string, BlockType> }[];
  attachmentBlocks: {
    blocks: Record<string, { id: string; component: MediaComponentType }>;
    containerId: string;
  }[];
}): NonNullable<InterfacePageType['blocks']> => {
  if (blocks.length === 0 && attachmentBlocks.length === 0) {
    return {};
  }

  return {
    [messageId]: {
      id: messageId,
      component: {
        componentType: 'Stack',
        content: { blockIds: blocks.map((block) => block.containerId) },
        appearance: { direction: 'column', styles: { gap: { y: 'gap-xl' } } },
      },
    },
    ...(attachmentBlocks.length
      ? {
          [getAttachmentsBlockId(messageId)]: {
            id: getAttachmentsBlockId(messageId),
            component: {
              componentType: 'Stack',
              content: { blockIds: attachmentBlocks.map((block) => block.containerId) },
              appearance: {
                direction: 'column',
                styles: { gap: { y: 'gap-xl' }, width: 'w-full', alignItems: 'items-end' },
              },
            },
          },
        }
      : {}),

    ...blocks.reduce((acc, block) => ({ ...acc, ...block.blocks }), {}),
    ...attachmentBlocks.reduce((acc, block) => ({ ...acc, ...block.blocks }), {}),
  };
};
