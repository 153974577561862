import { TimerPrecision } from './types';
import { MILLIS_PRE_MUL, PRECISION_CONVERSION_MAP, PRECISION_VS_INDEX } from './const';

const millisInPreviousDurations = (durationArray: number[]) =>
  durationArray.reduce((acc, _, i) => acc + durationArray[i] * MILLIS_PRE_MUL[i], 0);

const getDurationArray = (ms: number) =>
  MILLIS_PRE_MUL.reduce<number[]>((acc, _, index) => {
    const msInPreviousCalculatedDurations = index > 0 ? millisInPreviousDurations(acc) : 0;

    return [...acc, Math.floor((ms - msInPreviousCalculatedDurations) / MILLIS_PRE_MUL[index])];
  }, []);

const getPreciseDuration = ({ ms, precision }: { ms: number; precision: TimerPrecision }) => {
  const duration = getDurationArray(ms);
  const precisionIndex = PRECISION_VS_INDEX[precision];

  let surplus = 0;
  for (let i = 0; i <= precisionIndex; i++) {
    surplus += duration[i] * PRECISION_CONVERSION_MAP[precision][i];
  }

  return duration.map((value, index) => {
    if (index < precisionIndex) return 0;
    return index === precisionIndex ? surplus : duration[index];
  });
};

const getPrecision = (format: string): TimerPrecision => {
  if (format.includes('YYYY')) return TimerPrecision.Year;
  if (format.includes('MM')) return TimerPrecision.Month;
  if (format.includes('dd')) return TimerPrecision.Day;
  if (format.includes('hh')) return TimerPrecision.Hour;
  if (format.includes('mm')) return TimerPrecision.Minute;
  if (format === 'sss') return TimerPrecision.Millisecond;
  return TimerPrecision.Second;
};

export const getFormattedTime = ({
  totalMilliSeconds,
  format,
}: {
  totalMilliSeconds: number;
  format: string;
}) => {
  const doesFormatContainMs = format.includes('sss');

  //total milliseconds rounded in case the format does not contain milliseconds in order to ensure that second don't jump by an extra second
  const durationArray = getPreciseDuration({
    ms: doesFormatContainMs ? totalMilliSeconds : Math.round(totalMilliSeconds / 1000) * 1000,
    precision: getPrecision(format),
  });
  const [year, months, days, hours, minutes, seconds, milliseconds] = durationArray;

  return format
    .replace(/YYYY/g, year.toString().padStart(2, '0'))
    .replace(/MM/g, months.toString().padStart(2, '0'))
    .replace(/dd/g, days.toString().padStart(2, '0'))
    .replace(/hh/g, hours.toString().padStart(2, '0'))
    .replace(/mm/g, minutes.toString().padStart(2, '0'))
    .replace(/(?<temp2>^|(?<=:))ss(?<temp1>(?=:)|$)/g, seconds.toString().padStart(2, '0'))
    .replace(/sss/g, milliseconds.toString().padStart(3, '0'));
};
