import type { BlockType } from '@unifyapps/defs/types/block';
import { useMemo } from 'react';
import type { DataSourceEntity } from '@unifyapps/defs/types/dataSource';
import type { DataSourceMetaType, QueryRequestResult } from '../../stores/GlobalStateStore';
import { useGlobalStateStore } from '../../stores/GlobalStateStore';
import { useDataSourceRecords } from '../../stores/DataSourceRecordStore';

/**
 * should we move it to block definition like we have done for events?
 */
export function getDataSourcesFromBlock(block: BlockType) {
  // In filter block, we select the data source ids, we have it like [ds1, ds2]
  if (
    block.component.componentType === 'Filters' ||
    block.component.componentType === 'CategoryFilter'
  ) {
    return block.component.content.dataSourceIds;
  }
  // In all other blocks the used data source id is used like {{ ds1 }} {{ ds2 }}, which we pick and store at blockLevel.
  return block.dataSourceIds;
}

export type DataSourceResponseType = {
  data?: QueryRequestResult;
  meta?: DataSourceMetaType;
  info?: DataSourceEntity;
};

export type DataSourceResponsesType = ReturnType<
  typeof useDataSourceResponses
>['dataSourceResponses'];

function useDataSourceResponses(block: BlockType) {
  const dataSourcesRecords = useDataSourceRecords();
  const dataSourceIds = getDataSourcesFromBlock(block);
  const dataSources = useGlobalStateStore().use.dataSources();
  const dataSourcesMeta = useGlobalStateStore().use.dataSourcesMeta();

  const data = useMemo(() => {
    return dataSourceIds?.reduce<Record<string, DataSourceResponseType>>((acc, dataSourceId) => {
      const dataSource = dataSources[dataSourceId];
      const dataSourceMeta = dataSourcesMeta[dataSourceId];
      const dataSourceInfo = dataSourcesRecords[dataSourceId];
      acc[dataSourceId] = {
        data: dataSource,
        meta: dataSourceMeta,
        info: dataSourceInfo,
      };
      return acc;
    }, {});
  }, [dataSourceIds, dataSources, dataSourcesMeta, dataSourcesRecords]);

  return { dataSourceResponses: data };
}

export default useDataSourceResponses;
export type UseDataSourceResponsesType = ReturnType<typeof useDataSourceResponses>;
