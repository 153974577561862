import type { UseMutationOptions } from '@unifyapps/network/react-query';
import { useMutation } from '@unifyapps/network/react-query';
import { executeWorkflowNode } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import type { ExecuteWorkflowNodeRequestInputs } from '@unifyapps/network/generated/models/executeWorkflowNodeRequestInputs';

export default function useExecuteNode(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof executeWorkflowNode>>,
    unknown,
    ExecuteWorkflowNodeRequestInputs
  >,
) {
  return useMutation<
    Awaited<ReturnType<typeof executeWorkflowNode>>,
    unknown,
    ExecuteWorkflowNodeRequestInputs
  >({
    ...options,
    mutationFn: (
      inputs: ExecuteWorkflowNodeRequestInputs & { debugParams?: Record<string, string> },
    ) => {
      const { debugParams, ...restInputs } = inputs;

      return executeWorkflowNode(
        {
          context: { appName: 'callables', resourceName: 'callables_call_automation' },
          inputs: restInputs,
        },
        debugParams ? { debugParams } : undefined,
      );
    },
  });
}
