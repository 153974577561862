import { useMemo } from 'react';
import type { InterfacePermissions } from '@unifyapps/defs/types/interface';
import { useGetGlobalStateStoreState } from '../../stores/GlobalStateStore';
import { hasAccess } from '../utils/hasAccess';
import { useInterfaceStore } from '../../stores/InterfaceStore';

export const useCanAccess = (permissionsToAccess: InterfacePermissions = {}) => {
  const { getGlobalStateStoreState } = useGetGlobalStateStoreState();
  const interfaceMode = useInterfaceStore().use.mode();

  return useMemo(() => {
    const currentPermissions = getGlobalStateStoreState().permissions;
    return hasAccess({
      currentPermissions,
      permissionsToAccess,
      mode: interfaceMode,
    });
  }, [getGlobalStateStoreState, interfaceMode, permissionsToAccess]);
};
