import InlineSVG from 'react-inlinesvg';

export function getInlineSvg(iconName: string | undefined, className: string) {
  if (!iconName) return null;

  // we only process string icon names
  if (typeof iconName === 'string') return <InlineSVG className={className} src={iconName} />;

  return null;
}
