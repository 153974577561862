export const placeholderAlignmentToClassName = {
  center: 'placeholder:text-center',
  left: 'placeholder:text-left',
  right: 'placeholder:text-right',
};

export const placeholderWeightToClassName = {
  bold: '[&>input]:placeholder:font-bold',
  'semi-bold': '[&>input]:placeholder:font-semibold',
  medium: '[&>input]:placeholder:font-medium',
  regular: '[&>input]:placeholder:font-normal',
  light: '[&>input]:placeholder:font-light',
};

export const placeholderColorToClassName = {
  'text-primary': '[&>input]:placeholder:!text-primary',
  'text-secondary': '[&>input]:placeholder:!text-secondary',
  'text-tertiary': '[&>input]:placeholder:!text-tertiary',
  'text-quaternary': '[&>input]:placeholder:!text-quaternary',
  'text-white': '[&>input]:placeholder:!text-white',

  'text-brand-primary': '[&>input]:placeholder:!text-brand-primary',
  'text-brand-secondary': '[&>input]:placeholder:!text-brand-secondary',
  'text-brand-tertiary': '[&>input]:placeholder:!text-brand-tertiary',
  'text-quaternary_on-brand': '[&>input]:placeholder:!text-quaternary_on-brand',
  'text-tertiary_on-brand': '[&>input]:placeholder:!text-tertiary_on-brand',
  'text-secondary_on-brand': '[&>input]:placeholder:!text-secondary_on-brand',

  'text-warning-primary': '[&>input]:placeholder:!text-warning-primary',
  'text-warning-secondary': '[&>input]:placeholder:!text-warning-secondary',
  'text-error-primary': '[&>input]:placeholder:!text-error-primary',
  'text-error-secondary': '[&>input]:placeholder:!text-error-secondary',
  'text-success-primary': '[&>input]:placeholder:!text-success-primary',
  'text-success-secondary': '[&>input]:placeholder:!text-success-secondary',
};
