import { useMemo } from 'react';
import _reduce from 'lodash/reduce';
import _isObject from 'lodash/isObject';
import _forEach from 'lodash/forEach';
import _values from 'lodash/values';
import type { StyleObject } from '@unifyapps/defs/types/styleObject';
import type { ConditionalValueFilters } from '../../../utils/conditionalValues';
import type { CustomStyleValue, Direction } from './styles/types';
import { getUpdatedAppearanceStyleFromConditionValues } from './getUpdatedAppearanceStyleFromConditionValues';
import { getCustomStyleValue } from './styles/getCustomStyles';

type AppearanceStyles =
  | Record<
      string,
      | string
      | number
      | CustomStyleValue
      | ConditionalValueFilters<string>
      | Record<Direction, ConditionalValueFilters<string>>
    >[]
  | StyleObject
  | undefined;

export const getBlockAppearanceAndStyles = ({
  appearanceStyles,
}: {
  appearanceStyles: AppearanceStyles;
}): { style?: React.CSSProperties; className: string } => {
  if (!appearanceStyles) {
    return { className: '' };
  }

  const result = _reduce(
    appearanceStyles,
    (
      acc,
      val:
        | Record<string, string | number | ConditionalValueFilters<string>>
        | string
        | ConditionalValueFilters<string>,
      key,
    ) => {
      if (!val) return acc;

      if (
        _isObject(val) &&
        (val.conditionalValueFilters ||
          //@ts-expect-error --- will be of the type conditionalValueFilters
          (_isObject(_values(val)[0]) && _values(val)[0].conditionalValueFilters))
      ) {
        return getUpdatedAppearanceStyleFromConditionValues({
          appearanceStyles: acc,
          conditionalValueFilters: val as
            | Record<string, ConditionalValueFilters<string>>
            | ConditionalValueFilters<string>,
        });
      }
      const styleType = Object.keys(val)[0];

      const styleValue = typeof val === 'object' ? _values(val) : val;

      if (styleType === 'custom') {
        return getCustomStyleValue({
          styleValue: styleValue as string | CustomStyleValue[],
          key,
          initialStylesAndClassName: acc,
        });
      }

      const styleString = Array.isArray(styleValue) ? styleValue[0] : styleValue;
      if (styleString === 'none') {
        return acc;
      }

      return { className: `${acc.className} ${styleString as string}`, style: acc.style };
    },
    { className: '', style: {} },
  );

  return {
    className: result.className.trim(),
    style: result.style,
  };
};

const useBlockAppearanceStyles = ({
  appearanceStyles,
}: {
  appearanceStyles: AppearanceStyles;
}): { style?: React.CSSProperties; className: string } =>
  useMemo(
    () =>
      getBlockAppearanceAndStyles({
        appearanceStyles,
      }),
    [appearanceStyles],
  );

export default useBlockAppearanceStyles;
