import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import XClose from '@unifyapps/icons/outline/XClose';
import { Link } from '@unifyapps/ui/components/Link';
import { useTranslation } from '@unifyapps/i18n/client';
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';
import { getCitationTitle } from '../helpers/getCitationTitle';
import { useCitationsPanelContext } from './CitationsPanelProvider';
import { CitationIcon } from './CitationIcon';

function CitationItem({ citation }: { citation: CitationType }) {
  const title = getCitationTitle(citation);

  return (
    <Link
      className="p-lg border-secondary !gap-lg hover:bg-primary_hover relative flex h-[70px] flex-row items-center rounded-xl border hover:no-underline"
      href={citation.referenceUrl}
      target="_blank"
    >
      <CitationIcon citation={citation} className="size-8 flex-shrink-0" />
      <Stack className="gap-xs">
        {title ? (
          <Typography className="!break-all" maxNumberOfLines={1} variant="text-sm" weight="medium">
            {title}
          </Typography>
        ) : null}
        {citation.referenceUrl !== title ? (
          <Typography
            className="!break-all"
            maxNumberOfLines={1}
            variant="text-sm"
            weight="regular"
          >
            {citation.referenceUrl}
          </Typography>
        ) : null}
      </Stack>
    </Link>
  );
}

export function CitationsPanel() {
  const { t } = useTranslation(['copilot']);
  const { closePanel, citations } = useCitationsPanelContext();

  return (
    <Stack className="border-secondary bg-primary h-full w-[420px] border-s">
      <Stack
        className="px-lg py-sm border-secondary h-[56px] items-center justify-between border-b"
        direction="row"
      >
        <Typography variant="text-md" weight="semi-bold">
          {t('copilot:Sources')}
        </Typography>
        <IconButton
          Icon={XClose}
          className="border-none"
          color="neutral"
          onClick={closePanel}
          size="md"
          variant="soft"
        />
      </Stack>
      <Stack className="px-xl pt-xl pb-3xl gap-xl min-h-0 flex-1 overflow-auto">
        {citations.map((citation, index) => (
          <CitationItem citation={citation} key={index} />
        ))}
      </Stack>
    </Stack>
  );
}
