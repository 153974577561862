import type { BlockComponentType, BlockId, BlockComponentStateId } from '../../types/block';
import type { StyleObject } from '../../types/styleObject';

export enum MenuContentType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export type StaticMenuItem = {
  type: 'label' | 'confirmation';
  value: string;
  label: string;
  caption?: string;
  confirmationLabel?: string;
  appearance: {
    variant: 'default' | 'danger';
    startDecorator?: {
      type: 'icon' | 'image';
      icon: string;
      imageUrl: string;
    };
    endDecorator?: {
      type: 'icon' | 'image';
      icon: string;
      imageUrl: string;
    };
  };
};

export type DynamicMenuItem = {
  type: 'label' | 'confirmation';
  value: string | { path: string } | undefined;
  label: string | { path: string } | undefined;
  caption?: string | { path: string } | undefined;
  confirmationLabel?: string | { path: string } | undefined;
  appearance?: {
    variant: 'default' | 'danger';
    startDecorator?: string;
    endDecorator?: string;
  };
};

type MenuContent =
  | {
      type: MenuContentType.DYNAMIC;
      data?: unknown[];
      menuItem?: DynamicMenuItem;
      initialSelectedMenuItemId?: string;
    }
  | {
      type: MenuContentType.STATIC;
      menuItems?: StaticMenuItem[];
      initialSelectedMenuItemId?: string;
    };

type MenuAppearance = {
  size: 'sm' | 'md';
  styles?: Pick<
    StyleObject,
    | 'padding'
    | 'margin'
    | 'height'
    | 'maxHeight'
    | 'minHeight'
    | 'width'
    | 'minWidth'
    | 'maxWidth'
    | 'layout'
    | 'gap'
  >;
};

export type MenuComponentType = BlockComponentType<'Menu', MenuContent, MenuAppearance>;

export type MenuComponentStateType = Pick<
  MenuComponentType,
  'appearance' | 'componentType' | 'content'
> & {
  selectedItem?: { id?: string; label?: string };
} & BlockComponentStateId;
