import type { StatCardComponentType } from '@unifyapps/defs/blocks/StatCard/types';
import type { StatCardData } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';

export const generateStatCardBlock = ({
  data,
}: {
  data: StatCardData;
}): {
  blocks: Record<string, { id: string; component: StatCardComponentType }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX),
    containerId = `container_${blockId}`;

  return {
    containerId,
    //@ts-expect-error -- we need to add all properties here
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'StatCard',
          content: {
            type: data.type,
            label: data.label,
            description: data.description,
            value: data.value,
            currencyCode: data.currencyCode ?? 'USD',
            notation: data.notation,
            decimalPlaces: 0,
            addOns: data.trend
              ? {
                  trend: {
                    content: {
                      value: data.trend.value,
                      type: data.trend.type,
                      decimalPlaces: data.trend.decimalPlaces,
                      notation: data.trend.notation,
                      unit: data.trend.unit,
                      style: data.trend.style,
                      currencyCode: data.trend.currencyCode ?? 'USD',
                      thresholdValue: data.thresholdValue ?? 0,
                    },
                    appearance: {
                      signDisplay: data.trend.signDisplay,
                      decimalPlaces: data.trend.decimalPlaces,
                    },
                  },
                }
              : undefined,
          },
          appearance: {
            size: 'md',
            styles: {
              padding: { all: 'p-lg' },
              gap: { all: 'gap-xs' },
              backgroundColor: 'bg-primary',
              borderRadius: { all: 'rounded-lg' },
              borderColor: 'border-secondary',
              borderWidth: { all: 'border' },
            },
          },
        },
      },
      [containerId]: {
        id: containerId,
        component: {
          componentType: 'Container',
          content: {
            blockIds: [blockId],
          },
          appearance: {
            variant: '1_2',
          },
        },
      },
    },
  };
};
