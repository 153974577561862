import { type KeyboardEvent, useCallback, useMemo } from 'react';
import type { TextareaProps } from '@unifyapps/ui/components/Textarea';
import Textarea from '@unifyapps/ui/components/Textarea';
import CornerDownLeft from '@unifyapps/icons/outline/CornerDownLeft';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import useControlledState from '@unifyapps/ui/hooks/useControlledState';
import SvgLoading02 from '@unifyapps/icons/outline/Loading02';
import { clsx } from 'clsx';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import Stack from '@unifyapps/ui/_components/Stack';
import { UppyProvider } from '../UppyProvider';
import { AttachmentsUploader } from './AttachmentsUploader';
import { useAttachmentsUpload } from './hooks/useAttachmentsUpload';
import { TextareaRoot } from './TextareaRoot';
import { TextareaEditor } from './TextareaEditor';

export type MessageInputProps = {
  onSubmitMessage: (props: {
    message: string | undefined;
    attachments?: FileType[];
  }) => void | Promise<void>;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
  isLoading?: boolean;
  allowAttachmentsUpload?: boolean;
  onAttachmentsUpdate?: (files: FileType[]) => void;
  textareaVariant?: 'editor' | 'textarea';
} & Omit<TextareaProps, 'onChange' | 'value'>;

const chatInputSlotProps = {
  textarea: {
    className: 'resize-none',
  },
  endDecorator: {
    className: 'mt-0 ms-auto',
  },
};

const REFERENCE_ID = 'MESSAGE_INPUT';

function MessageInput({
  isLoading,
  onSubmitMessage,
  placeholder,
  onChange,
  onAttachmentsUpdate,
  value,
  className,
  slotProps: _slotProps,
  allowAttachmentsUpload = false,
  disabled,
  textareaVariant = 'editor',
  ...restProps
}: MessageInputProps) {
  const [input, setInput] = useControlledState<string | undefined>({
    onChange,
    controlledValue: value,
  });
  const { uppy, attachments, isUploading, onRemoveAttachment, onClearAll } = useAttachmentsUpload({
    referenceId: REFERENCE_ID,
    onAttachmentsUpdate,
  });

  const onInputSubmit = useCallback(() => {
    if (isUploading || (!input && attachments && attachments.length > 0)) return;
    void onSubmitMessage({ message: input, attachments });
    onClearAll();
  }, [isUploading, onSubmitMessage, input, attachments, onClearAll]);

  const onInputChange = useCallback(
    (_value: string) => {
      setInput(_value);
    },
    [setInput],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        onInputSubmit();
      }
    },
    [onInputSubmit],
  );

  const endDecorator = useMemo(() => {
    return (
      <Stack className="gap-lg items-center" direction="row">
        {allowAttachmentsUpload ? (
          <AttachmentsUploader disabled={disabled} referenceId={REFERENCE_ID} uppy={uppy} />
        ) : null}
        <IconButton
          Icon={isLoading ? SvgLoading02 : CornerDownLeft}
          color="neutral"
          disabled={disabled || !input}
          iconClassName={isLoading ? 'animate-spin' : ''}
          onClick={onInputSubmit}
          size="md"
          variant="outline"
        />
      </Stack>
    );
  }, [allowAttachmentsUpload, disabled, input, isLoading, onInputSubmit, uppy]);

  const slots = useMemo(
    () => ({
      root: TextareaRoot,
      ...(textareaVariant === 'editor' ? { textarea: TextareaEditor } : {}),
    }),
    [textareaVariant],
  );

  const slotProps = useMemo(
    () => ({
      root: {
        attachments,
        isUploading,
        onRemoveAttachment,
      },
      ...chatInputSlotProps,
      textarea: {
        ...chatInputSlotProps.textarea,
        onInputChange,
      },
      ...(_slotProps ?? {}),
    }),
    [attachments, isUploading, onRemoveAttachment, onInputChange, _slotProps],
  );

  return (
    <Textarea
      // eslint-disable-next-line jsx-a11y/no-autofocus -- needed for focussing on input
      autoFocus
      className={clsx('gap-xs p-md flex min-h-14 items-center shadow-md', className)}
      disabled={disabled}
      endDecorator={endDecorator}
      onChangeValue={onInputChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      slotProps={slotProps}
      slots={slots}
      value={input}
      {...restProps}
    />
  );
}

function MessageInputWrapper(props: MessageInputProps) {
  return (
    <UppyProvider>
      <MessageInput {...props} />
    </UppyProvider>
  );
}

export { MessageInputWrapper as MessageInput };
