import { default as BaseSSOButton } from '@unifyapps/ui/components/SSOButton';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { SSOButtonComponentType } from '@unifyapps/defs/types/blocks';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { SyntheticEvent } from 'react';
import { EventTypeEnum } from '@unifyapps/defs/types/event';

// do no have registry so need to run by component
function SSOButton({
  component,
  events,
  dataAttributes,
}: BlockComponentProps<SSOButtonComponentType, Record<string, unknown>, Record<string, unknown>>) {
  const { className, disabled, value } = component;
  const startDecoratorComponent = getIconFromRegistry(component.startDecoratorComponent);
  const { emitEvent } = useBlockEvents(events);

  const onClick = useEventCallback(
    (ev: SyntheticEvent) =>
      void emitEvent({
        eventType: EventTypeEnum.OnClick,
        domEvent: ev,
      }),
  );

  return (
    <BaseSSOButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      startDecoratorComponent={startDecoratorComponent}
      {...dataAttributes}
    >
      {value}
    </BaseSSOButton>
  );
}

export default SSOButton;
