/* eslint-disable @typescript-eslint/no-extraneous-class -- safe to ignore */

import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';

class MessageHelper {
  /*
   * get Message from lookup message response
   */
  static fromLookupMessage(
    response: Omit<Message, 'messageType'> & {
      messageType?: MessageType;
    },
    messageId: string,
    chatId?: string,
  ): Message {
    return {
      message: response.message,
      messageType: response.messageType ?? MessageType.Bot,
      messageId,
      createdTime: response.createdTime,
      caseId: response.caseId ?? chatId,
      feedback: response.feedback,
      attachments: response.attachments,
      interfaceDetails: response.interfaceDetails,
    };
  }

  static retryMessage({
    message = 'Can you retry the message?',
    userId,
    caseId,
    responseId,
  }: {
    message?: string;
    userId: string;
    caseId: string;
    responseId: string;
  }) {
    return {
      message,
      userId,
      caseId,
      responseId,
    };
  }
}

export default MessageHelper;
