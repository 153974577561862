import type { BlockNavItem, BlockGroupNavItem } from './types';

export function isGroupNavItem(item: BlockNavItem): item is BlockGroupNavItem {
  return item.type === 'group' && 'items' in item;
}

export function getLabelNavItems(items: BlockNavItem[]): BlockNavItem[] {
  const labelItems: BlockNavItem[] = [];

  function collectLabels(currentItems: BlockNavItem[]) {
    for (const item of currentItems) {
      if (item.type === 'label') {
        labelItems.push(item);
      }

      if (isGroupNavItem(item)) {
        collectLabels(item.items);
      }
    }
  }

  collectLabels(items);
  return labelItems;
}
