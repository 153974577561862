import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  ButtonBlockStateType,
  ButtonComponentType,
  ComputedButtonBlockStateType,
} from '@unifyapps/defs/types/blocks';
import type { SyntheticEvent } from 'react';
import React, { lazy, memo, useCallback, useMemo } from 'react';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { useButtonPreConfiguredEvents } from './useButtonPreConfiguredEvents';
import BaseButton from './components/BaseButton';

const importReCaptchaButtonComponent = () =>
  import(
    /* webpackChunkName: "ReCaptchaButton" */
    './components/ReCaptchaButton'
  );

const ReCaptchaButton = lazy(importReCaptchaButtonComponent);

function Button({
  dataAttributes,
  events,
  computedBlockState,
  updateBlockState,
}: BlockComponentProps<ButtonComponentType, ButtonBlockStateType, ComputedButtonBlockStateType>) {
  const { content, appearance } = computedBlockState;
  const buttonPreConfiguredEvents = useButtonPreConfiguredEvents(computedBlockState);
  const _events = useMemo(
    () => [...buttonPreConfiguredEvents, ...(events ?? [])],
    [events, buttonPreConfiguredEvents],
  );

  const { addOns, value } = content;
  const { reCaptcha, tooltip } = addOns ?? {};
  const { reCaptchaSiteKey } = reCaptcha ?? {};

  // used to check if we need recaptcha in our button
  const isCaptchaNeeded = Boolean(reCaptchaSiteKey);

  // in BUTTON we are only handling onClick event, for any new event handling handle it for recaptcha too
  const { emitEvent } = useBlockEvents(_events);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnClick, domEvent: e });
    },
    [emitEvent],
  );

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const type = content.type ?? 'default';
  const size = appearance?.size ?? 'md';

  const baseButtonProps = useMemo(
    () => ({
      appearance,
      className,
      dataAttributes,
      onClick,
      size,
      style,
      type,
      value,
      tooltip,
    }),
    [appearance, className, dataAttributes, onClick, size, style, tooltip, type, value],
  );

  if (isCaptchaNeeded) {
    return (
      <React.Suspense fallback={<BaseButton {...baseButtonProps} onClick={undefined} />}>
        <ReCaptchaButton
          baseButtonProps={baseButtonProps}
          emitEvent={emitEvent}
          reCaptcha={content.addOns?.reCaptcha}
          reCaptchaToken={computedBlockState.reCaptchaToken}
          updateBlockState={updateBlockState}
        />
      </React.Suspense>
    );
  }

  return <BaseButton {...baseButtonProps} />;
}

export default memo(Button);
