import type { FormContextType, StrictRJSFSchema, UiSchema } from '@rjsf/utils';
import { UI_OPTIONS_KEY } from '@rjsf/utils';
import { isObject, isPlainObject } from 'lodash';
import type { RJSFSchema, UaOptions } from '../../types';

// ui: prefix is deprecated and will be removed in the future
export const UI_PREFIX = 'ui:';
export const UA_PREFIX = 'ua:';
export const UA_OPTIONS_KEY = 'ua:options';

/** Get all passed options from ua:options, ui:options, and ua:<optionName>, returning them in an object with the `ua:`
 * stripped off.
 *
 * @param [uiSchema={}] - The UI Schema from which to get any `ua:xxx` options
 * @param globalOptions - global options to be used as fallback
 * @returns - An object containing all the `ua:xxx` options with the `ua:` stripped off along with all `globalOptions`
 */

export default function getUaOptions<
  ExtraOptions extends object,
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = object,
>(uiSchema: UiSchema<T, S, F> = {}, globalOptions = {}): UaOptions & ExtraOptions {
  const uaKeys = Object.keys(uiSchema).filter(
    (key) => key.startsWith('ua:') || key === UI_OPTIONS_KEY,
  );
  const data = uaKeys.reduce<UaOptions>(
    (options, key) => {
      const value = uiSchema[key] as unknown;

      // If the key is `ua:options` then we pick all the options from it
      if (key === UA_OPTIONS_KEY && isPlainObject(value)) {
        return { ...options, ...(value as object) };
      }

      // If the key is `ui:options` then we go through the object and get all the ua:*** options from it
      if (key === UI_OPTIONS_KEY && isObject(value)) {
        const uaOptions = getUaOptions(value as UiSchema);
        return { ...options, ...uaOptions };
      }

      // If the key is `ua:***` then we add it to the options
      return { ...options, [key.substring(3)]: value };
    },
    { ...globalOptions },
  );

  return data as UaOptions & ExtraOptions;
}
