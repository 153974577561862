import { useCallback, useMemo, useState } from 'react';
import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import { useMap } from '@react-hookz/web';
import { nanoid } from 'nanoid';
import type { BlockType } from '@unifyapps/defs/types/block';
import type { MediaComponentType } from '@unifyapps/defs/blocks/Media/types';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { isImage } from '@unifyapps/carbon/components/Media/utils';
import { generatePage } from '../../../helpers/generatePage';
import { generateMessage } from '../../../helpers/generateMessage';
import { useGetBlockJson } from '../../../hooks/useGetBlockJson';
import { NEW_MESSAGE_ID } from '../../../hooks/useSubmitUserMessage';

export type MessageWithBlocks = Message & {
  blocks: { containerId: string; blocks: Record<string, BlockType> }[];
};

export const useGetMessageBlocks = () => {
  const getBlockJson = useGetBlockJson();

  return useCallback(
    (message: Message) =>
      message.message
        .map((b) => getBlockJson(b, message))
        .filter(Boolean) as MessageWithBlocks['blocks'],
    [getBlockJson],
  );
};

const getPartitionAttachmentBlockParams = (attachments?: FileType[]) => {
  if (!attachments) return [];
  const isImageType = (item: FileType) => isImage(item);

  const imageAttachments = attachments.filter(isImageType);
  const otherAttachments = attachments.filter((item) => !isImageType(item));

  const blockType = 'Media' as const;
  if (imageAttachments.length > 0 && otherAttachments.length > 0) {
    return [
      { blockType, data: imageAttachments },
      { blockType, data: otherAttachments },
    ];
  }

  if (imageAttachments.length > 0) return [{ blockType, data: imageAttachments }];

  return [{ blockType, data: otherAttachments }];
};

export const useGetPage = (messages?: MessageWithBlocks[]) => {
  const [pageId, _] = useState(() => nanoid());
  const messageMap = useMap<string, ReturnType<typeof generateMessage>>();
  const getBlockJson = useGetBlockJson();

  return useMemo(() => {
    if (!messages?.length) return;
    const blocks = messages.reduce((acc, message) => {
      const attachments = getPartitionAttachmentBlockParams(message.attachments);

      const attachmentBlocks = attachments
        .map(
          (attachment) =>
            getBlockJson(attachment, message) as {
              blocks: Record<string, { id: string; component: MediaComponentType }>;
              containerId: string;
            },
        )
        .filter(Boolean);

      if (!messageMap.has(message.messageId) || message.messageId === NEW_MESSAGE_ID) {
        messageMap.set(
          message.messageId,
          generateMessage({
            messageId: message.messageId,
            blocks: message.blocks,
            attachmentBlocks,
          }),
        );
      }

      return { ...acc, ...messageMap.get(message.messageId) };
    }, {});

    return generatePage({ id: pageId, blocks });
  }, [getBlockJson, messageMap, messages, pageId]);
};
