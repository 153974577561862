import type { ReactNode } from 'react';
import type { MediaType } from '../MediaViewer/types';

export enum MediaVariants {
  Compact = 'compact',
  Spacious = 'spacious',
}

export type MediaItemProps = {
  item: MediaType;
  onRemoveItem?: (id: string) => void;
  className?: string;
  onClick?: () => void;
  size: 'sm' | 'md' | 'lg';
  showCaption?: boolean;
  overrides?: MediaOverrides;
};

export type MediaOverrides = {
  ImageItem?: (props: Omit<MediaItemProps, 'overrides'>) => ReactNode;
};
