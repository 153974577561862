import type { BlockId, BlockType, BlockTypeUnionType } from '@unifyapps/defs/types/block';
import keyBy from 'lodash/keyBy';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import type { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import { isUndefined } from 'lodash';
import type {
  BlockEditorDefinition,
  BlockStateDefinition,
  ComponentType,
} from '../BlockDefinition';
import type { InsertBlockContext } from '../../stores/InterfaceStore/actions/insertBlock';
import type { GlobalStateStoreType } from '../../stores/GlobalStateStore';
import type { BlockRendererComponentType, BlocksRecordType } from './types';
import { defaultToBlockData } from './defaultToBlockData';
import { defaultToFormData } from './defaultToFormData';

class BlocksRegistry {
  private readonly name: string;
  private readonly blockRenderer: BlockRendererComponentType;
  private readonly blockEditorDefinitions?: readonly BlockEditorDefinition[];
  private readonly blockStateDefinitionsByType?: Record<string, BlockStateDefinition>;
  private readonly blockEditorDefinitionsByType?: Record<string, BlockEditorDefinition>;
  private readonly blocks: Partial<BlocksRecordType>;
  private preloadedComponent: Record<string, ComponentType | undefined> = {};
  private isComponentPreloaded: Record<string, boolean | undefined> = {};

  constructor({
    blockRenderer,
    blocks,
    name,
    blockEditorDefinitions,
    blockStateDefinitions,
  }: {
    name: string;
    blockRenderer: BlockRendererComponentType;
    blocks: Partial<BlocksRecordType>;
    blockEditorDefinitions?: readonly BlockEditorDefinition[];
    blockStateDefinitions?: readonly BlockStateDefinition[];
  }) {
    this.name = name;
    this.blockRenderer = blockRenderer;
    this.blocks = blocks;
    this.blockEditorDefinitions = blockEditorDefinitions;
    this.blockStateDefinitionsByType = keyBy(blockStateDefinitions, 'type');
    this.blockEditorDefinitionsByType = keyBy(blockEditorDefinitions, 'type');
  }

  getBlockStateDefinition(name: BlockTypeUnionType) {
    const blockStateDefinition = this.blockStateDefinitionsByType?.[name];
    if (blockStateDefinition) return blockStateDefinition;
    console.error(`Block state definition not found for block type: ${name}`);
  }

  getBlockStateDefinitionsTypes() {
    return this.blockStateDefinitionsByType ? Object.keys(this.blockStateDefinitionsByType) : [];
  }

  getBlockEditorDefinition(name: BlockTypeUnionType) {
    const blockEditorDefinition = this.blockEditorDefinitionsByType?.[name];
    if (blockEditorDefinition) return blockEditorDefinition;
    console.error(`Block editor definition not found for block type: ${name}`);
  }

  getBlockComponent(name: BlockTypeUnionType) {
    const deprecatedPatternBlock = this.blocks[name] as ComponentType | undefined;
    if (deprecatedPatternBlock) return deprecatedPatternBlock;
    const block = this.preloadedComponent[name];
    if (block) return block;

    return this.getBlockStateDefinition(name)?.getComponent();
  }

  getBlockInitialFormData(
    name: BlockTypeUnionType,
    device?: DeviceVariantType,
    context?: InsertBlockContext,
  ) {
    return this.getBlockEditorDefinition(name)?.getInitialFormData(device, context);
  }

  getBlockInfo(name: BlockTypeUnionType) {
    return this.getBlockEditorDefinition(name)?.info;
  }

  getBlockContentSchema(block: BlockType) {
    const name = block.component.componentType;

    return this.getBlockEditorDefinition(name)?.getContentSchema(block) ?? {};
  }

  getBlockAppearanceSchema(
    block: BlockType,
    params?: { getGlobalStateStoreState: () => GlobalStateStoreType },
  ) {
    const name = block.component.componentType;

    return this.getBlockEditorDefinition(name)?.getAppearanceSchema(block, params) ?? {};
  }

  getSlotOrder(block: BlockType) {
    return this.getBlockEditorDefinition(block.component.componentType)?.getSlotOrder(block);
  }

  getBlockCompositionData(name: BlockTypeUnionType) {
    return this.getBlockEditorDefinition(name)?.getCompositionData();
  }

  getBlockEditorComponent(block: BlockType | undefined) {
    if (isUndefined(block)) {
      return null;
    }
    const name = block.component.componentType;

    return this.getBlockEditorDefinition(name)?.getEditorComponent();
  }

  getUseBlockRef(name?: BlockTypeUnionType) {
    return name ? this.getBlockStateDefinition(name)?.useBlockRef : false;
  }

  getBlockStateSchema(
    name: BlockTypeUnionType,
    block: BlockType,
    {
      getDataSourceOutputSchema,
      modules,
      activeBlockId,
      isBlockAncestorOfActiveBlock,
      getGlobalStateStoreState,
    }: {
      getDataSourceOutputSchema: (params: { block: BlockType; data: string }) => object | undefined;
      getGlobalStateStoreState: () => GlobalStateStoreType;
      modules: InterfacePageEntity[] | undefined;
      activeBlockId: BlockId;
      isBlockAncestorOfActiveBlock?: boolean;
    },
  ) {
    return this.getBlockEditorDefinition(name)?.stateSchema(block, {
      getDataSourceOutputSchema,
      modules,
      activeBlockId,
      getGlobalStateStoreState,
      isBlockAncestorOfActiveBlock,
    });
  }

  getBlockControlMethods(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.getBlockControlMethods(block);
  }

  getBlockRenderer() {
    return this.blockRenderer;
  }

  getBlockInitialState(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.initialStateGetter(block);
  }

  getBlockMethodSchema(block: BlockType, methodName: string) {
    const blockComponent = block.component;
    const blockComponentType = blockComponent.componentType;

    return this.getBlockEditorDefinition(blockComponentType)?.controlMethodSchemaGetter(
      methodName,
      block,
    );
  }

  preloadBlock(name: BlockTypeUnionType, block?: BlockType) {
    const { blockPreload, miscellaneous } = this.getBlockStateDefinition(name)?.preload?.() ?? {};

    if (!this.isComponentPreloaded[name]) {
      void blockPreload?.().then((_block) => {
        this.setPreloadedComponent(name, _block.default);
      });
      this.isComponentPreloaded[name] = true;
    }

    miscellaneous?.(block)?.forEach((misc) => {
      void misc();
    });
  }

  getEventTargetIds(name: BlockTypeUnionType, block: BlockType) {
    return this.getBlockStateDefinition(name)?.getEventTargetIds(block);
  }

  getRegistryName() {
    return this.name;
  }

  getAllDefinitions() {
    return this.blockEditorDefinitions;
  }

  getAllBlocksIcons() {
    return Object.entries(this.blockEditorDefinitions ?? {}).reduce<Record<string, string>>(
      (acc, [key, value]) => {
        acc[value.type] = value.info.icon;
        return acc;
      },
      {},
    );
  }

  getBlockIcon(name: BlockTypeUnionType) {
    return this.getBlockInfo(name)?.icon;
  }

  getBlockColor(name: BlockTypeUnionType) {
    return this.getBlockInfo(name)?.color;
  }

  getBlockCategory(name: BlockTypeUnionType) {
    return this.getBlockInfo(name)?.category;
  }

  getBlockContentSchemaTransformer(name: BlockTypeUnionType) {
    const transformers = this.getBlockEditorDefinition(name)?.getContentSchemaTransformer?.();

    return {
      toFormData: transformers?.toFormData ?? defaultToFormData,
      toBlockData: transformers?.toBlockData ?? defaultToBlockData,
    };
  }

  setPreloadedComponent(name: string, component: ComponentType) {
    this.preloadedComponent[name] = component;
  }
}

export default BlocksRegistry;
