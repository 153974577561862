import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { MultiStatsCardComponentType } from '@unifyapps/defs/blocks/MultiStatsCard/types';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importMultiStatsCardComponent = () =>
  import(
    /* webpackChunkName: "multi-stats-card-block" */
    './MultiStatsCard'
  );

const MultiStatsCard = lazy(importMultiStatsCardComponent);

class MultiStatsCardBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return MultiStatsCard;
  }

  get type(): ComponentTypeUnionType {
    return 'MultiStatsCard';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as MultiStatsCardComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }

  preload() {
    return {
      blockPreload: importMultiStatsCardComponent,
    };
  }
}

export default MultiStatsCardBlockStateDefinition;
