import type { TabsComponentType } from '@unifyapps/defs/blocks/Tabs/types';
import type { BlockType } from '@unifyapps/defs/types/block';
import type {
  CardComponentType,
  RepeatableComponentType,
  StackComponentType,
} from '@unifyapps/defs/types/blocks';
import Pipeline from '../../utils/middleware/synchronous';

type BlockPipelineContext = {
  block: BlockType;
  handleSlotsBlock: (block: BlockType<CardComponentType>) => void;
  handleContentBlock: (block: BlockType<TabsComponentType>) => void;
  handleBlockIdsBlock: (block: BlockType<StackComponentType>) => void;
  handleBlockIdBlock: (block: BlockType<RepeatableComponentType>) => void;
};

function blockSlotsMiddleware(ctx: BlockPipelineContext, next: () => void) {
  const { block } = ctx;

  if ('slots' in block.component && block.component.slots) {
    ctx.handleSlotsBlock(block as BlockType<CardComponentType>);
  } else {
    next();
  }
}

function blockContentMiddleware(ctx: BlockPipelineContext, next: () => void) {
  const { block } = ctx;

  if ('content' in block.component && 'items' in block.component.content) {
    ctx.handleContentBlock(block as BlockType<TabsComponentType>);
  } else {
    next();
  }
}

function blockBlockIdsMiddleware(ctx: BlockPipelineContext, next: () => void) {
  const { block } = ctx;

  if ('content' in block.component && 'blockIds' in block.component.content) {
    ctx.handleBlockIdsBlock(block as BlockType<StackComponentType>);
  } else {
    next();
  }
}

function blockBlockIdMiddleware(ctx: BlockPipelineContext, next: () => void) {
  const { block } = ctx;

  if ('content' in block.component && 'blockId' in block.component.content) {
    ctx.handleBlockIdBlock(block as BlockType<RepeatableComponentType>);
  } else {
    next();
  }
}

/**
 *
 * @returns - blocksPipeline
 * Use this function when you want to tinker with blockIds present in the block,
 * it handles all the scenarios where blockIds are present in the block, for example, if it is slot type block etc
 */
function getBlockPipeline() {
  const blocksPipeline = Pipeline<BlockPipelineContext>();

  blocksPipeline.push(
    blockSlotsMiddleware,
    blockContentMiddleware,
    blockBlockIdsMiddleware,
    blockBlockIdMiddleware,
  );

  return { blocksPipeline };
}

export default getBlockPipeline;
