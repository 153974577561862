import { useCallback, useMemo, memo, useEffect } from 'react';
import type { SyntheticEvent, KeyboardEvent, ChangeEvent } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type {
  TextInputComponentType,
  TextInputStateType,
} from '@unifyapps/defs/blocks/TextInput/types';
import { withSuspense as withBlockSuspense } from '@unifyapps/carbon/no-code/components/withSuspense';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';
import { Input } from '@unifyapps/ui/components/Input';
import useBlockEvents from '@unifyapps/carbon/no-code/hooks/useBlockEvents';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useGetDeviceDetailsInStateStore } from '@unifyapps/carbon/no-code/stores/GlobalStateStore';
import { DeviceVariantType } from '@unifyapps/defs/types/deviceVariant';
import { getIconFromRegistry } from '@unifyapps/icons/utils/registry';
import { getConditionalPropertyValue } from '@unifyapps/carbon/utils/conditionalValues';
import type { TypographyColors } from '@unifyapps/defs/types/typography';
import { clsx } from 'clsx';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { useMountEffect, usePrevious } from '@react-hookz/web';
import { InterfaceModes, useInterfaceStore } from '@unifyapps/carbon/no-code/stores/InterfaceStore';
import {
  placeholderAlignmentToClassName,
  placeholderWeightToClassName,
  placeholderColorToClassName,
} from './constants';

function TextInput({
  events,
  blockState,
  dataAttributes,
  updateBlockState,
  computedBlockState: computedComponent,
}: BlockComponentProps<TextInputComponentType, TextInputStateType, TextInputStateType>) {
  const { emitEvent } = useBlockEvents(events);

  const { device } = useGetDeviceDetailsInStateStore();
  const isMobileDevice = device === DeviceVariantType.MOBILE;
  const pageMode = useInterfaceStore().use.mode();

  const previousDefaultValue = usePrevious(computedComponent.content.defaultValue);

  const { content, appearance } = computedComponent;
  const { defaultValue, placeholder, addOns } = content;
  const { label, caption, prefixIcon, suffixIcon } = addOns ?? {};
  const {
    placeholder: placeholderAppearance,
    variant,
    size,
    styles,
    value: valueAppearance,
  } = appearance ?? {};

  const { appearance: labelAppearance } = label ?? {};
  const { variant: labelVariant, weight: labelWeight, color: _labelColor } = labelAppearance ?? {};
  const labelColor =
    getConditionalPropertyValue<TypographyColors>({ value: _labelColor }) || 'text-primary';

  const { appearance: captionAppearance } = caption ?? {};
  const {
    variant: captionVariant,
    weight: captionWeight,
    color: _captionColor,
  } = captionAppearance ?? {};
  const captionColor =
    getConditionalPropertyValue<TypographyColors>({ value: _captionColor }) || 'text-tertiary';

  const { variant: textVariant, weight: valueWeight, color: valueColor } = valueAppearance ?? {};
  const {
    weight: placeholderWeight,
    color: placeholderColor,
    align: placeholderAlignment,
  } = placeholderAppearance ?? {};

  const PrefixIcon = getIconFromRegistry(prefixIcon?.name);
  const SuffixIcon = getIconFromRegistry(suffixIcon?.name);

  const { className, style } = useBlockAppearanceStyles({
    appearanceStyles: styles,
  });

  const { className: inputClassName, style: inputStyle } = useBlockAppearanceStyles({
    appearanceStyles: {
      backgroundColor: styles?.backgroundColor,
    },
  });

  useMountEffect(() => {
    updateBlockState((draft) => {
      draft.value = defaultValue;
    });
  });

  /**
   * if the user has trigggered reset value block method,  we are setting to default value if user opted for reset to default method
   * and set resetToRefault undefined
   */
  useEffect(() => {
    if (blockState.resetToDefault !== undefined) {
      updateBlockState((draft) => {
        if (blockState.resetToDefault) {
          // user opted for reset to default so we are setting it here
          draft.value = defaultValue;
        } else {
          // user opted for complete reset so we are setting it to undefined
          draft.value = undefined;
        }

        draft.resetToDefault = undefined;
      });
    }
  }, [defaultValue, updateBlockState, blockState.resetToDefault]);

  /**
   * when we are in builder mode and if we have changed default value we are setting the value to new default value
   */
  useEffect(() => {
    if (pageMode === InterfaceModes.BUILDER && defaultValue !== previousDefaultValue) {
      updateBlockState((draft) => {
        draft.value = defaultValue;
      });
    }
  }, [defaultValue, updateBlockState, previousDefaultValue, pageMode, blockState]);

  const onChange = useEventCallback((event: ChangeEvent<HTMLInputElement>) => {
    const updatedValue = event.target.value;

    updateBlockState((draft) => {
      draft.value = updatedValue;
    });

    void emitEvent({
      eventType: EventTypeEnum.OnChange,
      actionContext: { value: updatedValue },
      domEvent: event,
    });
  });

  const onKeyDown = useEventCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      void emitEvent({ eventType: EventTypeEnum.OnSubmit, domEvent: event });
    }
  });

  const onFocus = useCallback(
    (event: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnFocus, domEvent: event });
    },
    [emitEvent],
  );

  const onBlur = useCallback(
    (event: SyntheticEvent) => {
      void emitEvent({ eventType: EventTypeEnum.OnBlur, domEvent: event });
    },
    [emitEvent],
  );

  const slotProps = useMemo(
    () => ({
      input: {
        className: placeholderAlignment
          ? placeholderAlignmentToClassName[placeholderAlignment]
          : undefined,
      },
    }),
    [placeholderAlignment],
  );

  const valueAndPlaceholderClassNames = `${textVariant} ${valueWeight} ${valueColor} ${placeholderColor ? placeholderColorToClassName[placeholderColor] : ''} ${placeholderWeight ? placeholderWeightToClassName[placeholderWeight] : ''}`;

  return (
    <Box {...dataAttributes} className={clsx('gap-y-sm flex flex-col', className)} style={style}>
      {label?.value ? (
        <Typography as="div" className={labelColor} variant={labelVariant} weight={labelWeight}>
          {label.value}
        </Typography>
      ) : null}
      {!isMobileDevice && caption?.value ? (
        <Typography
          as="div"
          className={captionColor}
          variant={captionVariant}
          weight={captionWeight}
        >
          {caption.value}
        </Typography>
      ) : null}
      <Input
        defaultValue={defaultValue}
        endDecoratorComponent={SuffixIcon}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        rootClassName={clsx(
          inputClassName,
          isMobileDevice
            ? `focus-within:!ring-0 ${valueAndPlaceholderClassNames}`
            : valueAndPlaceholderClassNames,
        )}
        size={size}
        slotProps={slotProps}
        startDecoratorComponent={PrefixIcon}
        style={inputStyle}
        value={blockState.value}
        variant={variant}
      />
      {isMobileDevice && caption?.value ? (
        <Typography
          as="div"
          className={captionColor}
          variant={captionVariant}
          weight={captionWeight}
        >
          {caption.value}
        </Typography>
      ) : null}
    </Box>
  );
}

export default memo(withBlockSuspense(TextInput));
