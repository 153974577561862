export enum TimerModes {
  Countdown = 'countdown',
  CountUp = 'countUp',
}

export enum TimerPrecision {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Millisecond = 'millisecond',
}
