import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';
import useExecuteNode from '@unifyapps/carbon/copilot/hooks/useExecuteNode';
import type React from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { NewMessage } from './useSubscribeNewMessage';

export const NEW_MESSAGE_ID = 'LATEST_USER_MSG_ID';

export function newUserMessage(
  { message, attachments }: { message: string; attachments?: FileType[] },
  caseId?: string,
): Message {
  return {
    messageId: NEW_MESSAGE_ID,
    caseId,
    messageType: MessageType.User,
    message: [{ blockType: 'Typography', data: { type: 'MARKDOWN', text: message } }],
    createdTime: Date.now(),
    attachments,
  };
}

type Payload = {
  response?: {
    messageId?: string;
    caseId?: string;
    context?: {
      playgroundId?: string;
    };
  };
};

export const useSubmitUserMessage = ({
  upsertMessage,
  onError,
  setIsAwaitingResponse,
  triggerPolling,
  onLookupMessage,
}: {
  setIsAwaitingResponse: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  onError: (error: unknown) => void;
  triggerPolling: () => void;
  upsertMessage: (
    messageId: string,
    message: Message | ((prev?: Message) => Message | undefined),
  ) => void;
  onLookupMessage?: (message: NewMessage) => void;
}) =>
  useExecuteNode({
    onError: (error) => {
      setIsAwaitingResponse(false);
      onError(error);
    },
    onSuccess: (payload: Payload) => {
      const { messageId, context } = payload.response || {};
      const caseId = payload.response?.caseId;
      if (caseId) {
        onLookupMessage?.({
          messageId,
          caseId,
          isAwaitingResponse: Boolean(messageId),
          messageType: MessageType.User,
          context,
        });
        if (messageId) {
          upsertMessage(NEW_MESSAGE_ID, (prevMessage) => {
            if (prevMessage) {
              prevMessage.messageId = messageId;
              prevMessage.caseId = caseId;
              triggerPolling();
            }
            return prevMessage;
          });
        }
      }
    },
  });
