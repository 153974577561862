import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { useTranslation } from '@unifyapps/i18n/client';
import { supportedFileTypes } from '@unifyapps/defs/blocks/FileUpload/contentSchema';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { UploadedFile } from '../../hooks/useUppy';

/**
 * temporary url ---\> to be deprecated
 * url ---\> while uploading, blob url and when uploaded, url path from backend
 */
export const getFileTypeFromUploadedFile = (file: UploadedFile): Omit<FileType, 'extension'> => {
  return {
    id: file.id,
    name: file.name,
    url: file.url ?? '', // this is the download url
    sourceType: 'CLOUD_STORAGE',
    fileType: file.extension,
    size: file.size,
    type: 'FILE',
    mimeType: file.type,
    temporaryUrl: file.uploadResponse?.s3url,
    progress: 100,
    isUploaded: true,
    key: file.uploadResponse?.key,
    bucket: file.uploadResponse?.bucket,
  };
};

export const getFileTypeFromFileInProgress = (file: UploadedFile): FileType => {
  return {
    id: file.id,
    name: file.name,
    url: file.meta.previewUrl ?? '', // this is the blob url
    sourceType: 'CLOUD_STORAGE',
    fileType: file.extension,
    size: file.size,
    type: 'FILE',
    mimeType: file.type,
    temporaryUrl: file.url,
    progress: file.fileProgress,
    isUploaded: false,
  };
};

const supportedFileTypesMap = keyBy(supportedFileTypes, 'const');

export function useGetAcceptedFileTypes(supportedFileTypesArg: string[] | undefined) {
  const { t } = useTranslation('interfaces');

  const isAcceptingAllFileTypes =
    !supportedFileTypesArg || isEmpty(supportedFileTypesArg) || supportedFileTypesArg.includes('*');

  if (isAcceptingAllFileTypes) {
    return {
      acceptFileTypes: '*',
      acceptingFileTypesLabel: t('interfaces:SupportedFileTypes', {
        fileTypes: t('interfaces:AllFileTypes'),
      }),
      uploaderDescription: {
        label: t('interfaces:SupportedFileTypes', {
          fileTypes: t('interfaces:AllFileTypes'),
        }),
      },
    };
  }

  const acceptFileTypes = supportedFileTypesArg.map((value) => value).join(', ');
  const acceptingFileTypesLabel = supportedFileTypesArg
    .map((value) => supportedFileTypesMap[value].title)
    .join(', ');

  return {
    acceptFileTypes,
    acceptingFileTypesLabel: t('interfaces:SupportedFileTypes', {
      fileTypes: acceptingFileTypesLabel,
    }),
    uploaderDescription: {
      label: t('interfaces:SupportedFileTypes', {
        fileTypes: acceptingFileTypesLabel,
      }),
    },
  };
}
