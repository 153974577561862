import type { UIFilter } from '@unifyapps/network/generated/models/uIFilter';
import type { SchemaAndLayoutSchema } from '@unifyapps/network/generated/models/schemaAndLayoutSchema';
import type { StyleObject } from '../../types/styleObject';
import type {
  BlockComponentStateId,
  BlockComponentWithRequiredAppearance,
} from '../../types/block';
import type { ConditionEvaluationObject } from '../../types/conditionEvaluationObject';
import type { SlotType } from '../../types/slot';

export type FieldKey = { path: string; key: string } | string;

export type Schema = {
  schema: SchemaAndLayoutSchema;
  layout: SchemaAndLayoutSchema;
};

export type ValidationRule = {
  id: string;
  conditions: {
    type: 'filter';
    payload: UIFilter;
  };
  errorMessage: string;
};

export type ComputedValidationRule = {
  id: string;
  conditions: boolean;
  errorMessage: string;
};

export type ValidationRules = ValidationRule[];
export type ComputedValidationRules = ComputedValidationRule[];

export enum FormFieldTypes {
  Manual = 'manual',
  Mapped = 'mapped',
}

export enum FormSource {
  Entity = 'entity',
  Custom = 'custom',
}

export enum FormFieldErrorType {
  EmptyMappedFormFields = 'emptyMappedFormFields',
  NoMappedFormFields = 'noMappedFormFields',
}

export type DefaultFormContent = {
  readonly?: boolean;
  initialFormData?: Record<string, unknown>;
  // undefined means true, so in case of undefined reset the form after submit
  resetFormWhenHidden?: boolean;
  schema?: {
    schema: SchemaAndLayoutSchema;
    layout: SchemaAndLayoutSchema;
  };
};

type EntityFormContent = DefaultFormContent & {
  source: FormSource.Entity;
  action?: 'create' | 'update';
  entity?: string;
  entityId?: string;
  schema?: Schema;
};

export type MappedCustomFormContent = DefaultFormContent & {
  source: FormSource.Custom;
  mappedFields: {
    data?: string;
    fieldId?: FieldKey;
    fieldLabel?: FieldKey;
    fieldType?: FieldKey;
    description?: FieldKey;
    helpText?: FieldKey;
    defaultValue?: FieldKey;
    isOptional?: FieldKey;
    additionalFields?: {
      CHECKBOX?: {
        options?: FieldKey;
      };
      CODE_EDITOR?: {
        language?: FieldKey;
        expandable?: FieldKey;
      };
      DATE?: {
        disablePast?: FieldKey;
      };
      DATE_TIME?: {
        disablePast?: FieldKey;
      };
      EMAIL?: {
        placeholder?: FieldKey;
      };
      FILE_UPLOADER?: {
        multipleFiles?: FieldKey;
        supportedFileTypes?: FieldKey;
        maxFiles?: FieldKey;
        maxFileSize?: FieldKey;
      };
      PHONE_NUMBER?: {
        placeholder?: FieldKey;
        countryCode?: {
          showCountryCode?: FieldKey;
          isConfigurable?: FieldKey;
        };
      };
      INTEGER?: {
        placeholder?: FieldKey;
      };
      JSON_SCHEMA?: {
        allowedFieldTypes?: FieldKey;
        appearance?: {
          emptyState?: {
            description?: FieldKey;
          };
        };
      };
      KEY_VALUE_PAIR?: {
        appearance?: {
          emptyState?: {
            description?: FieldKey;
          };
        };
      };
      MULTI_SELECT_DROPDOWN?: {
        placeholder?: FieldKey;
        optionsType?: FieldKey;
        options?: FieldKey;
        clientSearchable?: FieldKey;
        foreignKeyReference?: FieldKey;
      };
      NUMBER?: {
        placeholder?: FieldKey;
      };
      OTP?: {
        placeholder?: FieldKey;
        otp?: {
          length?: FieldKey;
        };
      };
      PASSWORD?: {
        placeholder?: FieldKey;
        autocomplete?: FieldKey;
      };
      RADIO?: {
        options?: FieldKey;
      };
      RICH_TEXT?: {
        richTextAddOns?: {
          toolbar?: {
            variant?: FieldKey;
          };
        };
      };
      SINGLE_SELECT_DROPDOWN?: {
        placeholder?: FieldKey;
        optionsType?: FieldKey;
        options?: FieldKey;
        clientSearchable?: FieldKey;
        foreignKeyReference?: FieldKey;
      };
      SLIDER?: {
        minimum?: FieldKey;
        maximum?: FieldKey;
        multipleOf?: FieldKey;
        showNumberInput?: FieldKey;
      };
      STRING?: {
        placeholder?: FieldKey;
        textFieldAddOns?: {
          prefixIcon?: FieldKey;
          suffixIcon?: FieldKey;
        };
      };
      TEXTAREA?: {
        placeholder?: FieldKey;
        maxRows?: FieldKey;
        minRows?: FieldKey;
      };
      TIME?: {
        timeStepSize?: FieldKey;
      };
    };
  };
  fieldsType: FormFieldTypes.Mapped;
};

export type ManualCustomFormContent = DefaultFormContent & {
  source: FormSource.Custom;
  schema?: Schema;
  fieldsType: FormFieldTypes.Manual;
};

type CustomFormContent = MappedCustomFormContent | ManualCustomFormContent;

export type FormContent = EntityFormContent | CustomFormContent;

export type FormAppearance = {
  layout?: 'column' | 'row';
  autoFocus?: boolean;
  disabled?: ConditionEvaluationObject;
  styles?: Pick<StyleObject, 'padding' | 'maxHeight' | 'minHeight'>;
};

export type ComputedFormAppearance = FormAppearance & {
  disabled?: boolean;
};

export type FormSlots = Record<string, SlotType | undefined>;

export type FormComponentType = BlockComponentWithRequiredAppearance<
  'Form',
  FormContent,
  FormAppearance,
  FormSlots
>;

export type FormBlockStateType = Pick<FormComponentType, 'appearance' | 'componentType' | 'slots'> &
  BlockComponentStateId & {
    data: Record<string, unknown> | undefined;
    fieldVsDefaultValueMap: Record<string, unknown>;
    content: FormComponentType['content'] & {
      schema: Schema;
    };
  };

export type ComputedFormBlockStateType = Omit<FormBlockStateType, 'appearance'> & {
  appearance: ComputedFormAppearance;
};
