import Stack from '@unifyapps/ui/_components/Stack';
import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import { useMemo, useState } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { MessageInput } from '@unifyapps/carbon/components/MessageInput/MessageInput';
import { clsx } from 'clsx';
import { cva } from 'class-variance-authority';
import { useChatContext } from './ChatContext';
import { COPILOT_LARGE_MAX_WIDTH_CLASSNAME } from './ChatList';

const quickQuestionsClassNameVariants = cva(
  'pb-xl ps-xl bg-utility-brand-25 hover:bg-utility-brand-100 flex flex-1 cursor-pointer items-end rounded-5xl min-h-18',
  {
    variants: {
      size: {
        sm: 'pt-4xl pe-3xl',
        lg: 'pt-4xl pe-4xl',
      },
    },
  },
);

export function ChatPanel({
  onSubmitMessage: _onSubmitMessage,
  placeholder,
  size,
  isChatEmpty,
  exampleMessages,
  className,
}: {
  className?: string;
  onSubmitMessage: (input: { message: string; attachments?: FileType[] }) => void;
  placeholder: string;
  size?: 'sm' | 'lg';
  isChatEmpty: boolean;
  exampleMessages?: string[];
}) {
  const { hideChatInput, controlledChatInput, allowAttachmentsUpload } = useChatContext();

  const {
    value: controlledValue,
    onChange: controlledOnChange,
    onSubmit: controlledOnSubmit,
    setSubmitHandler,
    chatInputProps,
  } = controlledChatInput ?? {};

  const [input, setInput] = useState<string | undefined>(undefined);

  const handleInputChange = useEventCallback((val: string) => {
    setInput(val);
    controlledOnChange?.(val);
  });

  const onSubmitMessage = useEventCallback(
    ({ attachments }: { message?: string; attachments?: FileType[] }) => {
      _onSubmitMessage({ message: controlledValue ?? input ?? '', attachments });
      setInput('');
    },
  );

  const handleSubmit = useEventCallback(
    ({ attachments }: { message?: string; attachments?: FileType[] }) => {
      onSubmitMessage({ attachments });
      controlledOnSubmit?.();
    },
  );

  useMemo(() => {
    setSubmitHandler?.(onSubmitMessage);
  }, [setSubmitHandler, onSubmitMessage]);

  const quickQuestionClassName = quickQuestionsClassNameVariants({ size });

  return (
    <Box className={clsx('flex w-full justify-center', className)}>
      <Stack
        className={clsx('w-full items-center', {
          [COPILOT_LARGE_MAX_WIDTH_CLASSNAME]: size === 'lg',
        })}
      >
        {isChatEmpty && exampleMessages ? (
          <Stack className={size === 'sm' ? 'pb-xl px-2xl w-full' : 'pb-5xl w-full'} gap={4}>
            <Box className="gap-lg grid w-full grid-cols-2">
              {exampleMessages.map((message) => (
                <Box
                  className={quickQuestionClassName}
                  key={message}
                  onClick={() => {
                    _onSubmitMessage({ message });
                  }}
                >
                  <Typography variant="text-md" weight="medium">
                    {message}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Stack>
        ) : null}
        {hideChatInput ? null : (
          <Stack className="pt-xs px-xl pb-3xl w-full" gap={4}>
            <MessageInput
              allowAttachmentsUpload={allowAttachmentsUpload}
              className="!p-lg"
              onChange={handleInputChange}
              onSubmitMessage={handleSubmit}
              placeholder={placeholder}
              value={controlledValue ?? input}
              {...chatInputProps}
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
}
