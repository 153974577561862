import type { ComputedButtonBlockStateType } from '@unifyapps/defs/types/blocks';
import { useMemo } from 'react';
import type { Event } from '@unifyapps/defs/types/event';
import { ActionType } from '@unifyapps/defs/types/action';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { ACTION_PREFIX, EVENT_PREFIX } from '@unifyapps/carbon/no-code/const';

const getResetEvent = (blockId: string): Event => {
  return {
    id: getIdWithPrefix(EVENT_PREFIX),
    eventType: 'onClick',
    action: {
      id: getIdWithPrefix(ACTION_PREFIX),
      actionType: ActionType.ControlBlockMethod,
      payload: { blockId, methodName: 'resetForm' },
    },
  };
};

const getSubmitEvent = (blockId: string): Event => {
  return {
    id: getIdWithPrefix(EVENT_PREFIX),
    eventType: 'onClick',
    action: {
      id: getIdWithPrefix(ACTION_PREFIX),
      actionType: ActionType.ControlBlockMethod,
      payload: { blockId, methodName: 'submitForm' },
    },
  };
};

export function useButtonPreConfiguredEvents(computedBlockState: ComputedButtonBlockStateType) {
  return useMemo(() => {
    const events: Event[] = [];

    if (computedBlockState.content.formBlockId) {
      if (computedBlockState.content.type === 'submit') {
        events.push(getSubmitEvent(computedBlockState.content.formBlockId));
      } else if (computedBlockState.content.type === 'reset') {
        events.push(getResetEvent(computedBlockState.content.formBlockId));
      }
    }
    return events;
  }, [computedBlockState.content.formBlockId, computedBlockState.content.type]);
}
