import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { ImageBlockStateType, ImageComponentType } from '@unifyapps/defs/blocks/Image/types';
import type {
  BlockType,
  BlockStateUnionType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import Image from './Image';

class ImageBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Image;
  }

  get type(): ComponentTypeUnionType {
    return 'Image';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'openInMediaViewer',
        label: 'Open In Media Viewer',
        method: (blockState: BlockStateUnionType | undefined, _: Action) => {
          return {
            ...blockState,
            state: {
              isOpenInMediaViewer: true,
            },
          } as ImageBlockStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ImageComponentType, ['content', 'appearance', 'componentType']),
      state: {
        isOpenInMediaViewer: false,
      },
    });
  }
}

export default ImageBlockStateDefinition;
