import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { ButtonComponentType, ButtonBlockStateType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import Button from './Button';

class ButtonBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Button';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'setDisabled' as const,
        label: 'Set Disabled',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as { methodPayload: { disabled: boolean } };

          const { disabled } = methodPayload;

          return {
            ...blockState,
            appearance: {
              ...blockState.appearance,
              disabled: {
                type: 'boolean',
                payload: disabled,
              },
            },
          } as ButtonBlockStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ButtonComponentType, ['content', 'appearance', 'componentType']),
    });
  }

  getComponent() {
    return Button;
  }
}

export default ButtonBlockStateDefinition;
