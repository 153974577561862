import { useEffect, useState } from 'react';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { usePrevious, useUnmountEffect } from '@react-hookz/web';
import { useUppy } from '../../../hooks/useUppy';
import type { UploadedFile } from '../../../hooks/useUppy';
import { getFileTypeFromFileInProgress, getFileTypeFromUploadedFile } from '../utils';

const adaptUploadedFileToFileType = (
  file: UploadedFile | UploadedFile[],
): FileType | FileType[] => {
  if (!Array.isArray(file)) {
    return getFileTypeFromUploadedFile(file);
  }

  return file.map(getFileTypeFromUploadedFile);
};

export const useFileUpload = ({
  referenceId,
  onChange,
  onAllFilesUploaded,
}: {
  referenceId: string;
  onChange: (files: FileType[]) => void;
  onAllFilesUploaded?: (files: FileType[]) => void;
}) => {
  const [shouldUpdateBlockState, setShouldUpdateBlockState] = useState<{
    value: boolean;
    file: UploadedFile | null;
  }>({
    value: false,
    file: null,
  });

  const { uppy, files, removeFileById, clearAll, isUploading, updateFiles } = useUppy({
    referenceId,
    onUploadSuccess: async (file) => {
      setShouldUpdateBlockState({
        file,
        value: true,
      });

      return Promise.resolve(file);
    },
    onProgress: (file) => {
      if (!shouldUpdateBlockState.value)
        setShouldUpdateBlockState({
          file,
          value: true,
        });
    },
    onFileAdded: (file) => {
      setShouldUpdateBlockState({
        file,
        value: true,
      });
      return file;
    },
  });

  const prevIsUploading = usePrevious(isUploading);

  useEffect(() => {
    if (shouldUpdateBlockState.value) {
      const _files = Object.values(files).map((f) => {
        const file = shouldUpdateBlockState.file?.id === f.id ? shouldUpdateBlockState.file : f;
        return getFileTypeFromFileInProgress(file);
      });

      onChange(_files);

      setShouldUpdateBlockState({
        file: null,
        value: false,
      });
    }
  }, [files, shouldUpdateBlockState, onChange]);

  useEffect(() => {
    if (!isUploading && prevIsUploading) {
      const adaptedFiles = Object.values(files).map(adaptUploadedFileToFileType) as FileType[];

      onAllFilesUploaded?.(adaptedFiles);
      onChange(adaptedFiles);
    }
  }, [isUploading, files, prevIsUploading, onChange, onAllFilesUploaded]);

  useUnmountEffect(() => {
    // clean up files when modal is unmounted
    clearAll();
  });

  return {
    uppy,
    files,
    removeFileById,
    clearAll,
    isUploading,
    shouldUpdateBlockState,
    setShouldUpdateBlockState,
    updateFiles,
  };
};
