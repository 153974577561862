import { TimerPrecision } from './types';

const MILLISECONDS = 1,
  SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
  MONTH = 31.5 * DAY,
  YEAR = 365 * DAY;

export const MILLIS_PRE_MUL = [YEAR, MONTH, DAY, HOUR, MINUTE, SECOND, MILLISECONDS];

export const PRECISION_VS_INDEX = {
  [TimerPrecision.Year]: 0,
  [TimerPrecision.Month]: 1,
  [TimerPrecision.Day]: 2,
  [TimerPrecision.Hour]: 3,
  [TimerPrecision.Minute]: 4,
  [TimerPrecision.Second]: 5,
  [TimerPrecision.Millisecond]: 6,
};

// Maps each TimerPrecision to an array of multipliers for converting durations to the specified precision.
// The array elements represent the multipliers for years, months, days, hours, minutes, seconds, and milliseconds respectively.
export const PRECISION_CONVERSION_MAP = {
  [TimerPrecision.Year]: [1, 0, 0, 0, 0, 0, 0],
  [TimerPrecision.Month]: [12, 1, 0, 0, 0, 0, 0],
  [TimerPrecision.Day]: [365, 31.5, 1, 0, 0, 0, 0],
  [TimerPrecision.Hour]: [365 * 24, 31.5 * 24, 24, 1, 0, 0, 0],
  [TimerPrecision.Minute]: [365 * 24 * 60, 31.5 * 24 * 60, 24 * 60, 60, 1, 0, 0],
  [TimerPrecision.Second]: [
    365 * 24 * 60 * 60,
    31.5 * 24 * 60 * 60,
    24 * 60 * 60,
    60 * 60,
    60,
    1,
    0,
  ],
  [TimerPrecision.Millisecond]: [
    365 * 24 * 60 * 60 * 1000,
    31.5 * 24 * 60 * 60 * 1000,
    24 * 60 * 60 * 1000,
    60 * 60 * 1000,
    60 * 1000,
    1000,
    1,
  ],
};
