import type { MutableRefObject } from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import Stack from '@unifyapps/ui/_components/Stack';
import { Box } from '@unifyapps/ui/components/Box';
import { cva } from 'class-variance-authority';
import { useRegistryContext } from '@unifyapps/carbon/no-code/components/RegistryProvider';
import { InterfaceResourceProvider } from '@unifyapps/carbon/no-code/components/InterfaceResourceProvider';
import { DUMMY_INTERFACE_RECORD } from '@unifyapps/carbon/no-code/const';
import type { PrimaryActionItem } from '@unifyapps/defs/blocks/Chat/types';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import { CopilotEventTypes } from '@unifyapps/defs/blocks/Copilot/types';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { usePrevious } from '@react-hookz/web';
import { differenceBy } from 'lodash';
import isEqual from 'react-fast-compare';
import type { InterfacePageEntity } from '@unifyapps/defs/types/page';
import { clsx } from 'clsx';
import type { InterfaceResourceProviderRefType } from '@unifyapps/carbon/no-code/components/InterfaceResourceProvider/types';
import ChatMessage from './ChatMessage';
import { EmptyState } from './EmptyState';
import { Loader } from './Loader';
import type { MessageWithBlocks } from './ChatMessage/hooks/useGetPage';
import { useGetPage } from './ChatMessage/hooks/useGetPage';
import { useChatContext } from './ChatContext';

type ChatListProps = {
  className?: string;
  messages?: MessageWithBlocks[];
  userName: string;
  visibilityRef?: MutableRefObject<HTMLDivElement | null>;
  isAwaitingResponse?: boolean;
  onRegenerateResponse?: (messageId: string) => void;
  size: 'sm' | 'lg';
  primaryActionItems?: PrimaryActionItem[];
  secondaryUserName?: string;
  averageResponseTime: number;
  refetchMessage: (messageId: string) => void;
  welcomeText?: string;
  onSubmitMessage?: (
    input: { message: string; attachments?: FileType[] },
    messageId?: string,
  ) => void;
  isFetchingMessages?: boolean;
  automationsConfig?: AutomationConfig;
};

export const COPILOT_LARGE_MAX_WIDTH_CLASSNAME = 'min-w-[788px] max-w-[960px]';

const chatListVariants = cva('h-max w-full', {
  variants: {
    size: {
      sm: 'p-xl',
      lg: `pt-6xl pb-6xl ${COPILOT_LARGE_MAX_WIDTH_CLASSNAME}`,
    },
  },
});

function useUpdateInterfaceBlocks({ page }: { page?: InterfacePageEntity }) {
  const interfaceResourceProviderRef = useRef<InterfaceResourceProviderRefType | undefined>();
  const prevPage = usePrevious(page);

  useEffect(() => {
    if (!interfaceResourceProviderRef.current) return;
    const prevBlocks = prevPage?.properties.blocks;
    const newBlocks = page?.properties.blocks;

    if (!prevBlocks || prevBlocks === newBlocks) return;

    const prevBlocksArray = Object.values(prevBlocks);
    const newBlocksArray = Object.values(newBlocks || {});

    const addedBlocks = differenceBy(newBlocksArray, prevBlocksArray, 'id');
    const deletedBlocks = differenceBy(prevBlocksArray, newBlocksArray, 'id');

    // find blocks that are present in prevBlocks and newBlocks but have different values
    const changedBlocks = newBlocksArray.filter((newBlock) => {
      const prevBlock = prevBlocks[newBlock.id];
      // if the block exists in both states, compare them
      return !isEqual(prevBlock, newBlock);
    });

    const storeRef = interfaceResourceProviderRef.current;

    storeRef.updateBlocks({
      added: addedBlocks,
      deleted: deletedBlocks,
      changed: changedBlocks,
    });
  }, [prevPage?.properties.blocks, page?.properties.blocks]);

  return { interfaceResourceProviderRef };
}

export const ChatList = forwardRef<HTMLDivElement | null, ChatListProps>(
  (
    {
      onSubmitMessage,
      messages,
      userName,
      visibilityRef,
      isAwaitingResponse,
      onRegenerateResponse,
      size,
      primaryActionItems,
      secondaryUserName,
      averageResponseTime,
      refetchMessage,
      welcomeText,
      isFetchingMessages,
      automationsConfig,
    },
    ref,
  ) => {
    const className = chatListVariants({ size });
    const { registry } = useRegistryContext();
    const page = useGetPage(messages);
    const { renderLoader } = useChatContext();
    const { interfaceResourceProviderRef } = useUpdateInterfaceBlocks({ page });

    const Loading = renderLoader ? (
      renderLoader()
    ) : (
      <Box
        className={clsx(['flex flex-1 self-end', size === 'sm' ? 'pb-xl' : 'pb-4xl', className])}
      >
        <EmptyState size={size} userName={userName} welcomeText={welcomeText} />
      </Box>
    );

    if (isFetchingMessages && !messages?.length) {
      return null;
    }

    return messages?.length && page ? (
      <Stack className={className} ref={ref}>
        <InterfaceResourceProvider
          emitPageEvent={({
            eventType,
            eventPayload,
          }: {
            eventType: string;
            eventPayload: { message: string; attachments?: FileType[]; messageId?: string };
          }) => {
            if (eventType === (CopilotEventTypes.SubmitMessage as string) && eventPayload.message) {
              onSubmitMessage?.(
                { message: eventPayload.message, attachments: eventPayload.attachments },
                eventPayload.messageId,
              );
            }
            return Promise.resolve();
          }}
          interfaceRecord={DUMMY_INTERFACE_RECORD}
          interfaceResourceProviderRef={interfaceResourceProviderRef}
          page={page}
          registry={registry}
        >
          {messages.map((message, index) => (
            <ChatMessage
              automationsConfig={automationsConfig}
              isLastMessage={index === messages.length - 1}
              key={message.messageId}
              lastMessageType={index > 0 ? messages[index - 1].messageType : undefined}
              message={message}
              onRegenerateResponse={onRegenerateResponse}
              onSubmitMessage={onSubmitMessage}
              primaryActionItems={primaryActionItems}
              refetchMessage={refetchMessage}
              secondaryUserName={secondaryUserName}
              size={size}
              userName={userName}
            />
          ))}
        </InterfaceResourceProvider>
        {isAwaitingResponse ? (
          <Loader averageResponseTime={averageResponseTime} size={size} />
        ) : null}
        <Box data-testid="visibilityRef" ref={visibilityRef} />
      </Stack>
    ) : (
      Loading
    );
  },
);

ChatList.displayName = 'ChatList';
