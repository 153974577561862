// https://muniftanjim.dev/blog/basic-middleware-pattern-in-javascript/

export type Next = () => void;

type Middleware<T> = (context: T, next: Next) => void;

type Synchronous<T> = {
  push: (...middlewares: Middleware<T>[]) => void;
  execute: (context: T) => void;
};

export default function Pipeline<T>(...middlewares: Middleware<T>[]): Synchronous<T> {
  const stack: Middleware<T>[] = middlewares;

  const push: Synchronous<T>['push'] = (..._middlewares) => {
    stack.push(..._middlewares);
  };

  const execute: Synchronous<T>['execute'] = (context) => {
    let prevIndex = -1;

    const runner = (index: number): void => {
      if (index === prevIndex) {
        throw new Error('next() called multiple times');
      }

      prevIndex = index;

      const middleware = stack[index];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- this is necessary
      if (middleware) {
        middleware(context, () => {
          return runner(index + 1);
        });
      }
    };

    runner(0);
  };

  return { push, execute };
}
