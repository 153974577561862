import { EMPTY_OBJECT } from '@unifyapps/carbon/consts/empty';
import { createContext, useContext } from 'react';
import type { ConfigType } from '@unifyapps/carbon/copilot/hooks/useFetchAIConfig';
import type { JSONTreeSchemaObject } from '@unifyapps/carbon/types';
import type { WorkflowDefinition } from '@unifyapps/network/generated/models/workflowDefinition';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import type { MessageInputProps } from '@unifyapps/carbon/components/MessageInput/MessageInput';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import type { NewMessage } from '../hooks/useSubscribeNewMessage';

type NewMessageContext =
  | {
      copilotType: ConfigType.AutomationChat;
      workflowJson: WorkflowDefinition;
    }
  | { copilotType: ConfigType.CodeGenerator; runVariables: JSONTreeSchemaObject[] }
  | {
      copilotType: ConfigType.AIAgentTest;
      aiAgentId: string;
    }
  | {
      copilotType: ConfigType.AIAgent;
      aiAgentId: string;
    }
  | {
      copilotType: ConfigType.AIAgentPromptTest;
      promptVariables: Record<string, unknown>;
      promptContent: string;
    }
  | {
      copilotType: ConfigType.ModelComparison;
      modelId?: string;
    };

export type ChatContextType = {
  onLookupMessage?: (newMessage: NewMessage) => void;
  getNewMessageContext?: () => NewMessageContext;
  getPreviousMessageContext?: () => object;
  canRetry?: boolean;
  onMessageClick?: (message: NewMessage) => void;
  getMessages?: (messages: NewMessage[]) => void;
  formBlockInput?: {
    schema: Record<string, unknown>;
    layout: Record<string, unknown>;
  };
  renderLoader?: () => JSX.Element;
  readonly?: boolean;
  activeMessageId?: string;
  //adding temporarily, will be removed post messageInput block is integrated with existing Chat block
  hideChatInput?: boolean;
  controlledChatInput?: {
    value?: string;
    onChange?: (value: string) => void;
    onSubmit?: () => void;
    setSubmitHandler?: (
      handler: (props: { message?: string; attachment?: FileType[] }) => void,
    ) => void;
    chatInputProps?: Partial<MessageInputProps>;
  };
  allowAttachmentsUpload?: boolean;
  showCitations?: boolean;
};

const ChatContext = createContext<ChatContextType | null>(null);

const useChatContext = () => {
  return useContext(ChatContext) ?? (EMPTY_OBJECT as ChatContextType);
};

export { useChatContext };
export default ChatContext;
