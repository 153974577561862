import { EventTypeEnum } from '../../../types/event';

export const TABLE_SAVE_ACTION_TARGET_ID = 'tableSaveActionAddOn';
export const getTableSaveAddOnDefaultValue = () => ({ autoClearChangeset: true });

export const tableSaveActionSchema = {
  type: 'object',
  properties: {
    events: {
      type: 'string',
    },
    eventsDivider: {
      type: 'string',
    },
    appearance: {
      type: 'object',
      properties: {
        disabled: {
          type: 'string',
        },
        loading: {
          type: 'string',
        },
      },
    },
    appearanceDivider: {
      type: 'string',
    },
    autoClearChangeset: {
      type: 'boolean',
    },
  },
};

export const tableSaveActionUISchema = {
  events: {
    'ui:title': 'Interactions',
    'ui:field': 'InteractionsField',
    'ua:options': {
      targetId: TABLE_SAVE_ACTION_TARGET_ID,
      events: [{ eventType: EventTypeEnum.OnSave, label: 'On Save' }],
      emptyWarningMessage: `Changes to editable cells will not be saved until a Save event handler is configured.`,
    },
  },
  eventsDivider: {
    'ui:label': false,
    'ui:widget': 'DividerWidget',
  },
  appearance: {
    'ui:title': 'Appearance',
    'ua:collapsible': false,
    'ua:disableBackground': true,
    'ui:titleClassName': 'text-secondary',
    'ui:weight': 'semi-bold',
    'ua:isSectionRoot': true,
    disabled: {
      'ui:title': 'Disabled',
      'ui:widget': 'ConditionEvaluationWidget',
      'ui:fieldLayout': [['title', 'children']],
    },
    loading: {
      'ui:title': 'Loading',
      'ui:widget': 'ConditionEvaluationWidget',
      'ui:fieldLayout': [['title', 'children']],
    },
  },
  appearanceDivider: {
    'ui:label': false,
    'ui:widget': 'DividerWidget',
  },
  autoClearChangeset: {
    'ui:title': 'Clear changeset on successful save',
    'ui:titleClassName': 'text-secondary font-semibold',
    'ui:childrenClassName': 'w-[40px]',
    'ui:widget': 'ToggleWidget',
    'ui:options': {
      size: 'sm',
    },
    'ua:options': {
      orientation: 'column',
    },
    'ui:fieldLayout': [['title', 'children']],
  },
};
