import { cva } from 'class-variance-authority';
import { clsx } from 'clsx';
import Stack from '@unifyapps/ui/_components/Stack';
import type { TypographyVariants } from '@unifyapps/ui/components/Typography';
import { Typography } from '@unifyapps/ui/components/Typography';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import SvgXClose from '@unifyapps/icons/outline/XClose';
import { Box } from '@unifyapps/ui/components/Box';
import { useTranslation } from '@unifyapps/i18n/client';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import { SmallIconButton } from '@unifyapps/ui/components/SmallIconButton';
import type { MediaType } from '../../MediaViewer/types';
import type { MediaItemProps, MediaOverrides } from '../types';
import { isImage } from '../utils';
import { MediaItem } from './MediaItem';
import './style.css';

const itemVariants = cva(
  'flex-none w-full bg-primary p-sm border-secondary gap-sm group relative cursor-pointer items-center rounded-sm border',
  {
    variants: {
      size: {
        sm: 'h-10 max-w-[160px]',
        md: 'h-14 max-w-[280px]',
        lg: 'h-16 max-w-[320px]',
      },
    },
  },
);

const textVariants = cva('', {
  variants: {
    size: {
      sm: 'text-xs',
      md: 'text-sm',
      lg: 'text-sm',
    },
  },
});

export const getMediaItem = (item: MediaType, overrides?: MediaOverrides) => {
  switch (true) {
    case isImage(item):
      return overrides?.ImageItem ?? null;
    default:
      return null;
  }
};

export function CompactMediaItem({
  item,
  onRemoveItem,
  className,
  onClick,
  size,
  overrides,
}: MediaItemProps) {
  const { t } = useTranslation('common');
  const handleDelete: React.MouseEventHandler<HTMLButtonElement> = useEventCallback((event) => {
    event.stopPropagation();
    onRemoveItem?.(item.id);
  });

  const OverriddenMediaItem = getMediaItem(item, overrides);
  if (OverriddenMediaItem) {
    return (
      <OverriddenMediaItem
        className={className}
        item={item}
        onClick={onClick}
        onRemoveItem={onRemoveItem}
        size={size}
      />
    );
  }

  return (
    <Stack className={clsx(itemVariants({ size }), className)} direction="row" onClick={onClick}>
      <Box className="flex aspect-square h-full items-center">
        <MediaItem iconClassName="size-full" imgClassName="size-full" item={item} />
      </Box>
      <Stack className={clsx('gap-xs h-full', { 'justify-center': size === 'sm' })}>
        <Typography
          className="flex-1"
          maxNumberOfLines={1}
          variant={textVariants({ size }) as TypographyVariants}
          weight="semi-bold"
        >
          {item.name || item.caption || t('common:Attachment')}
        </Typography>
        {size !== 'sm' ? (
          <Typography variant="text-xxs" weight="light">
            {(item.fileType ?? 'File').toUpperCase()}
          </Typography>
        ) : null}
      </Stack>
      {onRemoveItem ? (
        <Tooltip placement="top" title={t('common:Actions.RemoveItem')}>
          <SmallIconButton
            Icon={SvgXClose}
            className="btnClassName absolute -end-2 -top-2 hidden justify-self-end group-hover:flex"
            onClick={handleDelete}
            size="sm"
            variant="solid"
          />
        </Tooltip>
      ) : null}
    </Stack>
  );
}
