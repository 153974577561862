import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { clsx } from 'clsx';
import type { TypographyProps } from '../Typography';
import { Typography } from '../Typography';
import { Box } from '../Box';
import { getFormattedTime } from './utils';
import { useTimer } from './hooks/useTimer';
import { TimerModes } from './types';

const ONE_YEAR = 365 * 24 * 60 * 60 * 1000;

type Props = {
  hidden?: boolean;
  format: string;
  mode: TimerModes;
  interval: number;
  autostart?: boolean;
  startValue?: number;
  endValue?: number;
  onCurrentValueUpdate?: (newValue: number) => void;
  onTimerExpire?: () => void;
  onTimerIntervalElapsed?: () => void;
  eventTimeInterval: number | undefined;
} & Pick<TypographyProps, 'className' | 'color' | 'weight' | 'variant' | 'style'>;

export type TimerRef = {
  start: () => void;
  stop: () => void;
  reset: () => void;
};

const Timer = forwardRef<TimerRef, Props>(function Timer(props, timerRef) {
  const {
    hidden,
    format,
    startValue,
    endValue,
    autostart,
    mode,
    interval,
    className,
    onCurrentValueUpdate,
    onTimerExpire,
    onTimerIntervalElapsed,
    eventTimeInterval,
    ...typographyProps
  } = props;
  const autoStartTimerRef = useRef<{ handled?: boolean }>({ handled: false });

  const { totalMilliSeconds, stop, start, reset } = useTimer({
    startValue: startValue ?? 0,
    endValue: endValue ?? (mode === TimerModes.CountUp ? ONE_YEAR : 0),
    mode,
    interval,
    onTimerExpire,
    onTimerIntervalElapsed,
    eventTimeInterval,
  });

  const displayTime = useMemo(
    () =>
      getFormattedTime({
        totalMilliSeconds,
        format,
      }),
    [format, totalMilliSeconds],
  );

  useImperativeHandle(
    timerRef,
    () => ({
      start,
      stop,
      reset,
    }),
    [reset, start, stop],
  );

  useEffect(() => {
    onCurrentValueUpdate?.(totalMilliSeconds);
    if (autoStartTimerRef.current.handled) return;
    if (autostart) {
      autoStartTimerRef.current.handled = true;
      start();
    }
  }, [autostart, onCurrentValueUpdate, start, timerRef, totalMilliSeconds]);

  if (hidden) {
    return null;
  }

  return (
    <Box className="gap-xs flex items-center justify-center">
      <Typography {...typographyProps} className={clsx('tabular-nums', className)}>
        {displayTime}
      </Typography>
    </Box>
  );
});

export default memo(Timer);
