import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { ModalBlockStateType, ModalComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const importModalComponent = () =>
  import(
    /* webpackChunkName: "modal-block" */
    './Modal'
  );

const Modal = lazy(importModalComponent);

class ModalBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Modal';
  }
  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'showModal',
        label: 'Show',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            state: {
              open: true,
            },
          } as ModalBlockStateType;
        },
      },
      {
        id: 'hideModal',
        label: 'Hide',
        method: (blockState: BlockStateUnionType, _: Action) => {
          return {
            ...blockState,
            state: {
              open: false,
            },
          } as ModalBlockStateType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as ModalComponentType, ['content', 'appearance', 'componentType']),
      state: {
        open: false,
      },
    });
  }

  getComponent() {
    return Modal;
  }

  preload() {
    return {
      blockPreload: importModalComponent,
    };
  }
}

export default ModalBlockStateDefinition;
