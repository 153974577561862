import type { Event } from '@unifyapps/defs/types/event';
import { EventTypeEnum } from '@unifyapps/defs/types/event';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { ActionType } from '@unifyapps/defs/types/action';
import _isUndefined from 'lodash/isUndefined';
import { useBlocksFromPage } from '../../stores/InterfaceStore';
import { useRuntimeBlockKey } from '../../components/BlockRuntimeStateProvider';

function useContextualDialogEvents({
  blockId,
  blockStateId,
}: {
  blockId: string;
  blockStateId: string | undefined;
}) {
  const runTimeBlockKey = useRuntimeBlockKey();

  const blocks = useBlocksFromPage();
  const { showSnackbar } = useSnackbar();
  let contextualDialogBlockId = '';

  for (const bId in blocks) {
    if (blocks[bId].component.componentType === 'ContextualDialog') {
      const isContextualBlockFound = blocks[bId].component.content.dialogBlockId === blockId;

      if (isContextualBlockFound && contextualDialogBlockId) {
        // show error snackbar
        showSnackbar({
          color: 'error',
          title: `More than one contextual dialog block found for block id ${bId}, prev ${contextualDialogBlockId} and new ${blockId}`,
        });
        return {};
      } else if (isContextualBlockFound) {
        contextualDialogBlockId = bId;
      }
    }
  }

  if (!contextualDialogBlockId) return {};

  // todo: no memo here
  const customEvents: Event[] = [
    {
      id: 'contextual-dialog-toggle',
      eventType: EventTypeEnum.OnClick,
      action: {
        actionType: ActionType.ControlBlockMethod,
        payload: {
          blockId: contextualDialogBlockId,
          methodName: 'toggleContextualDialog',
          blockStateId,
          isRuntimeBlock: !_isUndefined(runTimeBlockKey),
        },
      },
    },
  ];

  return { dependentBlockEvents: customEvents };
}

export default useContextualDialogEvents;
