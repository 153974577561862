import type { StackComponentType } from '@unifyapps/defs/blocks/Stack/types';
import type { WritableDraft } from 'immer';
import type { BlockType } from '@unifyapps/defs/types/block';
import _omit from 'lodash/omit';

export const defaultToBlockData = ({
  formData,
  draftBlock,
}: {
  formData?: object;
  draftBlock: WritableDraft<BlockType>;
}) => {
  (draftBlock.component as StackComponentType).content = _omit(formData, ['slots']);
};
