import type { UiSchema } from '@unifyapps/form/types';

export const uiSchema: UiSchema = {
  feedbackCategories: {
    'ui:title': false,
    'ui:widget': 'CheckboxesWidget',
    'ui:options': {
      'ua:variant': 'outlined',
    },
    'ua:options': {
      containerClassName: 'grid grid-cols-2',
    },
  },
  userFeedback: {
    'ui:widget': 'textarea',
    'ui:title': 'Feedback in detail (optional)',
    'ui:placeholder': 'Please provide a detailed description.',
  },
};
