import Stack from '@unifyapps/ui/_components/Stack';
import _noop from 'lodash/noop';
import { useMemo } from 'react';
import type { BlockStateUnionType, ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import { withSuspense } from '../../../withSuspense';
import useComputeBlockState from '../../../../hooks/useComputeBlockState';
import { useRegistryContext } from '../../../RegistryProvider';
import type { LoginLayoutComponentType, ComputedLoginLayoutComponentType } from '../../types';
import SafeTernaryBoundary from '../../../../../components/SafeTernaryBoundary';
import IdentityProvider from '../IdentityProvider';
import type { BlockComponentProps } from '../../../BlockRenderer/types';
import { useReactiveComputeContext } from '../../../../hooks/computeContext';

function LoginContent({
  component,
}: BlockComponentProps<
  LoginLayoutComponentType,
  Record<string, unknown>,
  Record<string, unknown>
>) {
  const { context } = useReactiveComputeContext();
  const computedComponent = useComputeBlockState<ComputedLoginLayoutComponentType>({
    blockState: component,
    context,
  });
  const { heading, subHeading, footer, logo, idps } = computedComponent;
  const TypographyComponent = useRegistryContext().registry.getBlockComponent('Typography');
  const ImageComponent = useRegistryContext().registry.getBlockComponent('Image');

  const { registry } = useRegistryContext();

  const BlockRenderer = registry.getBlockRenderer();

  const imageBlockState = useMemo(() => ({ ...logo, id: 'logo' }) as BlockStateUnionType, [logo]);
  const headingBlockState = useMemo(
    () => ({ ...heading, id: 'heading' }) as BlockStateUnionType,
    [heading],
  );
  const subHeadingBlockState = useMemo(
    () => ({ ...subHeading, id: 'subHeading' }) as BlockStateUnionType,
    [subHeading],
  );

  const computedImageBlockState = useComputeBlockState<ComputedBlockStateUnionType>({
    blockState: imageBlockState,
    context,
  });
  const computedHeadingBlockState = useComputeBlockState<ComputedBlockStateUnionType>({
    blockState: headingBlockState,
    context,
  });
  const computedSubHeadingBlockState = useComputeBlockState<ComputedBlockStateUnionType>({
    blockState: subHeadingBlockState,
    context,
  });

  return (
    <Stack className="gap-4xl min-h-fit w-[342px]">
      <SafeTernaryBoundary>
        <Stack className="gap-3xl">
          <SafeTernaryBoundary>
            <Stack alignItems="center" className="h-10 w-full" justifyContent="center">
              {logo && ImageComponent ? (
                <ImageComponent
                  blockState={imageBlockState}
                  component={logo}
                  computedBlockState={computedImageBlockState}
                  updateBlockState={_noop}
                />
              ) : null}
            </Stack>
          </SafeTernaryBoundary>
          <SafeTernaryBoundary>
            <Stack>
              {heading && TypographyComponent ? (
                <TypographyComponent
                  blockState={headingBlockState}
                  component={heading}
                  computedBlockState={computedHeadingBlockState}
                  updateBlockState={_noop}
                />
              ) : null}
              {subHeading && TypographyComponent ? (
                <TypographyComponent
                  blockState={subHeadingBlockState}
                  component={subHeading}
                  computedBlockState={computedSubHeadingBlockState}
                  updateBlockState={_noop}
                />
              ) : null}
            </Stack>
          </SafeTernaryBoundary>
        </Stack>
      </SafeTernaryBoundary>
      <Stack className="gap-3xl">
        <IdentityProvider idps={idps} />
      </Stack>
      {footer ? <BlockRenderer blockId={footer.id} /> : null}
    </Stack>
  );
}

export default withSuspense(LoginContent);
