import { useEffect } from 'react';
import useMutationHelpers from '@unifyapps/carbon/hooks/useMutationHelpers';
import type { UpdateBlockStateType } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { AutomationConfig, CopilotBlockStateType } from '@unifyapps/defs/blocks/Copilot/types';
import { usePrevious } from '@react-hookz/web';
import { getActiveChatId } from '../utils';
import { useQueryParams } from './useQueryParams';

export const useBlockUpdateStateEffect = ({
  updateBlockState,
  errorMessage,
  automationsConfig,
  aiAgentId,
  chatIdQueryParamKey,
  initialChatId,
}: {
  chatIdQueryParamKey: string;
  aiAgentId?: string;
  errorMessage?: string;
  updateBlockState: UpdateBlockStateType<CopilotBlockStateType>;
  automationsConfig: AutomationConfig | undefined;
  initialChatId?: string;
}) => {
  const { onShowErrorSnackbar } = useMutationHelpers();
  const { getQueryParam, setQueryParam } = useQueryParams();

  const activeChatId = getQueryParam(chatIdQueryParamKey) ?? initialChatId ?? null;

  const previousAiAgentId = usePrevious(aiAgentId);
  const previousAutomationsConfig = usePrevious(aiAgentId);

  return useEffect(() => {
    setQueryParam(chatIdQueryParamKey, getActiveChatId(activeChatId));
    updateBlockState((draft) => {
      //update query params whenever activeChatId changes
      draft.chatId = getActiveChatId(activeChatId);
    });

    if (errorMessage) {
      onShowErrorSnackbar({ title: errorMessage });
      updateBlockState((draft) => {
        //reset error post showing snackbar
        draft.errorMessage = '';
      });
    }

    if (previousAutomationsConfig !== automationsConfig) {
      updateBlockState((draft) => {
        draft.automationsConfig = automationsConfig;
      });
    }

    if (previousAiAgentId !== aiAgentId) {
      updateBlockState((draft) => {
        draft.aiAgentId = aiAgentId;
      });
    }
  }, [
    activeChatId,
    aiAgentId,
    automationsConfig,
    chatIdQueryParamKey,
    errorMessage,
    onShowErrorSnackbar,
    previousAiAgentId,
    previousAutomationsConfig,
    setQueryParam,
    updateBlockState,
  ]);
};
