import React from 'react';
import { clsx } from 'clsx';
import type { BlockRendererComponentType } from '@unifyapps/carbon/no-code/components/RegistryProvider/types';
import type { Appearance, ContainerBlockStateType } from '@unifyapps/defs/blocks/Container/types';
import { default as BaseStack } from '@unifyapps/ui/_components/Stack';
import { getBlockKey } from '@unifyapps/carbon/no-code/utils/block';

type ContainerVariantProps = {
  component: ContainerBlockStateType;
  BlockRenderer: BlockRendererComponentType;
  className?: string;
  dataAttributes?: Record<string, string>;
  style: React.CSSProperties | undefined;
};

// [1]
export function Full({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack className={clsx('w-full', className)} style={style} {...dataAttributes}>
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,1]
function TwoEqualColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-2', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,1,1]
function ThreeEqualColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-3', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,1,1,1]
function FourEqualColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,1,1,1,1]
function FiveEqualColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-5', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,1,1,1,1,1]
function SixEqualColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-6', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => (
        <BaseStack key={getBlockKey(blockId, index)}>
          <BlockRenderer blockId={blockId} parentBlockId={component.id} />
        </BaseStack>
      ))}
    </BaseStack>
  );
}

// [1,2]
function OneTwoColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-3', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 0,
              'col-span-2': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}
// [1,3]
function OneThreeColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 0,
              'col-span-3': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [2,1]
function TwoOneColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-3', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 1,
              'col-span-2': index === 0,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [3,1]
function ThreeOneColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-3': index === 0,
              'col-span-1': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}
// [3,2]
function ThreeTwoColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-5', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-3': index === 0,
              'col-span-2': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [2,3]
function TwoThreeColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-5', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-2': index === 0,
              'col-span-3': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [1,1,2]
function OneOneTwoColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 0 || index === 1,
              'col-span-2': index === 2,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [1,2,1]
function OneTwoOneColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 0 || index === 2,
              'col-span-2': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [2,1,1]
function TwoOneOneColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-4', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 1 || index === 2,
              'col-span-2': index === 0,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [2,1,2]
function TwoOneTwoColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-5', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 1,
              'col-span-2': index === 0 || index === 2,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

// [1,4]
function OneFourColumns({
  component,
  BlockRenderer,
  className,
  dataAttributes,
  style,
}: ContainerVariantProps) {
  return (
    <BaseStack
      className={clsx('grid w-full grid-cols-5', className)}
      style={style}
      {...dataAttributes}
    >
      {component.content.blockIds?.map((blockId, index) => {
        return (
          <BaseStack
            className={clsx({
              'col-span-1': index === 0,
              'col-span-4': index === 1,
            })}
            key={getBlockKey(blockId, index)}
          >
            <BlockRenderer blockId={blockId} parentBlockId={component.id} />
          </BaseStack>
        );
      })}
    </BaseStack>
  );
}

export const ContainerVariants: Partial<
  Record<Appearance['variant'], React.ComponentType<ContainerVariantProps>>
> = {
  '1': Full,
  '1_1': TwoEqualColumns,
  '1_2': OneTwoColumns,
  '1_3': OneThreeColumns,
  '2_1': TwoOneColumns,
  '2_3': TwoThreeColumns,
  '3_1': ThreeOneColumns,
  '3_2': ThreeTwoColumns,
  '1_1_2': OneOneTwoColumns,
  '1_2_1': OneTwoOneColumns,
  '2_1_1': TwoOneOneColumns,
  '2_1_2': TwoOneTwoColumns,
  '1_1_1': ThreeEqualColumns,
  '1_1_1_1': FourEqualColumns,
  '1_1_1_1_1': FiveEqualColumns,
  '1_1_1_1_1_1': SixEqualColumns,
  '1_4': OneFourColumns,
};
