import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import type { BlockId } from '@unifyapps/defs/types/block';
import { useTranslation } from '@unifyapps/i18n/client';
import type { CardComponentType } from '@unifyapps/defs/types/blocks';
import { getIsRootBlock } from '../../utils/block';
import { useBlockFromPage } from '../../stores/InterfaceStore/hooks';
import { getBlockEmptyInfo, getIsWholeBlockEmpty } from '../../utils/getBlockEmptyInfo';

export function PlaceHolderView({
  componentType,
  isRootBlock,
}: {
  componentType: string;
  isRootBlock: boolean;
}) {
  const { t } = useTranslation(['interfaces']);

  return (
    <Box className="p-2xl border-secondary h-full w-full border-2 border-dashed">
      <Typography color="text-placeholder" variant="text-md" weight="medium">
        {isRootBlock
          ? t('interfaces:EmptyRootId')
          : t('interfaces:EmptyBlockId', {
              componentType,
            })}
      </Typography>
    </Box>
  );
}

function PlaceholderBlock({ parentBlockId }: { parentBlockId?: BlockId }) {
  const parentBlock = useBlockFromPage({
    blockId: parentBlockId ?? null,
  });

  const grandParentBlock = useBlockFromPage({
    blockId: parentBlock?.parentId ?? null,
  });

  if (!parentBlock) return null;

  const emptyBlockInfo = getBlockEmptyInfo(parentBlock);
  const isWholeBlockEmpty = getIsWholeBlockEmpty(emptyBlockInfo);
  const isRootBlock = getIsRootBlock(parentBlock.id);

  if (!isWholeBlockEmpty) return null;

  const isStackBlock = parentBlock.component.componentType === 'Stack';
  const isGrandParentBlockSlotBased = Boolean(
    (grandParentBlock?.component as CardComponentType | undefined)?.slots,
  );

  // if the parentBlock is Stack, then it might be to render a slot, we don't want to show the empty placeholder when it is a slot
  if (isStackBlock && isGrandParentBlockSlotBased) return null;

  return (
    <PlaceHolderView
      componentType={parentBlock.component.componentType}
      isRootBlock={isRootBlock}
    />
  );
}

export default PlaceholderBlock;
