import useMqttSubscription from '@unifyapps/network/hooks/useMqttSubscription';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { safeJsonParse } from '@unifyapps/carbon/utils/json';
import type { MessageType } from '@unifyapps/defs/blocks/Chat/types';

export type NewMessage = {
  messageId?: string;
  caseId?: string;
  isAwaitingResponse?: boolean;
  messageType?: MessageType;
  context?: { playgroundId?: string };
};

export function useSubscribeNewMessage({
  loggedInUserId,
  onNewMessage,
  chatId,
}: {
  loggedInUserId?: string;
  onNewMessage: (message: NewMessage) => void;
  chatId: string | undefined;
}) {
  const _onMessage = useEventCallback((payload: string | Buffer) => {
    const message = safeJsonParse<NewMessage>(payload.toString());
    // mqtt send conversationId and everywhere else it is caseId
    const caseId = message.caseId ?? (message as Record<string, string>).conversationId;
    if (caseId) {
      message.caseId = caseId;
    }

    /**
     * When user switches to an older conversation from current using chat history side bar,
     * we need to ignore any replies that we receive over mqtt from the previous conversation.
     */
    if (message.caseId && chatId !== message.caseId) {
      return;
    }

    onNewMessage(message);
  });

  // todo topic will be different for different chatId and chatTypes
  useMqttSubscription(`chat_inbox_${loggedInUserId}`, { onMessage: _onMessage });
}
