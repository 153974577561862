import { Typography } from '@unifyapps/ui/components/Typography';
import Stack from '@unifyapps/ui/_components/Stack';
import { useTranslation } from '@unifyapps/i18n/client';
import SvgUnifyAi from '@unifyapps/icons/colored/UnifyAi';

export function EmptyState({
  welcomeText,
  size = 'sm',
  userName,
}: {
  welcomeText?: string;
  size?: 'sm' | 'lg';
  userName?: string;
}) {
  const { t } = useTranslation('copilot');
  const iconSize = size === 'sm' ? 28 : 32;

  return (
    <Stack className="flex-start gap-md">
      <SvgUnifyAi height={iconSize} width={iconSize} />
      <Stack>
        <Typography className="!text-rainbow" variant={size === 'sm' ? 'display-sm' : 'display-lg'}>
          {userName ? t('copilot:WelcomeUser', { userName }) : t('copilot:WelcomeWithAComma')}
        </Typography>
        <Typography className="!text-rainbow" variant={size === 'sm' ? 'display-sm' : 'display-lg'}>
          {welcomeText ?? t('HowCanIHelpYouToday')}
        </Typography>
      </Stack>
    </Stack>
  );
}
