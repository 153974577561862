import type { TypographyComponentType } from '@unifyapps/defs/blocks/Typography/types';
import type { CitationBlockParams } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';

export const generateCitationBlock = ({
  data,
  showCitations,
}: {
  data: CitationBlockParams['data'];
  showCitations?: boolean;
}): {
  blocks: Record<string, { id: string; component: TypographyComponentType }>;
  containerId: string;
} | null => {
  if (!showCitations) {
    return null;
  }

  const blockId = getIdWithPrefix(BLOCK_PREFIX);

  return {
    containerId: blockId,
    // @ts-expect-error -- todo mapping citation types
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Citation',
          content: { citations: data.citations },
        },
      },
    },
  };
};
