import type { ButtonBlockParams, Message } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import type { ButtonComponentType } from '@unifyapps/defs/blocks/Button/types';
import { CopilotEventTypes } from '@unifyapps/defs/blocks/Copilot/types';
import _isEmpty from 'lodash/isEmpty';

export const generateButtonBlock = (
  data: ButtonBlockParams,
  message?: Message,
): {
  blocks: Record<string, { id: string; component: ButtonComponentType; events: unknown[] }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX);
  const actionId = getIdWithPrefix('act');
  const eventId = getIdWithPrefix('evt');

  return {
    containerId: blockId,
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Button',
          content: data.content,
          appearance: data.appearance,
        },
        events:
          data.events && data.events.length > 0
            ? data.events
            : [
                {
                  action: {
                    actionType: 'emitPageEvent',
                    payload: {
                      eventType: CopilotEventTypes.SubmitMessage,
                      eventPayload: { message: data.content.value, messageId: message?.messageId },
                    },
                    id: actionId,
                  },
                  id: eventId,
                  eventType: 'onClick',
                },
              ],
      },
    },
  };
};
