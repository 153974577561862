import { useEffect, useRef, useState, useLayoutEffect, useMemo } from 'react';
import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import { isEmpty } from 'lodash';
import _debounce from 'lodash/debounce';

export const useScrollAnchor = ({ fetchedMessages }: { fetchedMessages?: Message[] }) => {
  const messagesRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const visibilityRef = useRef<HTMLDivElement>(null);
  const previousHeightRef = useRef<number | undefined>();

  const [scrollPosition, setScrollPosition] = useState<'top' | 'bottom' | undefined>('bottom');
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    const newHeight = scrollRef.current?.scrollHeight;

    /**
     * When the user scrolls to the top of the chat, fetchNextPage will be triggered
     * and the chat will be updated with new messages. We want to retain the scroll position to
     * the point until which the user was before the fetch.
     */
    if (scrollPosition === 'top') {
      if (previousHeightRef.current && newHeight) {
        scrollRef.current.scrollTop = newHeight - previousHeightRef.current;
      }
    }

    previousHeightRef.current = newHeight;
  }, [fetchedMessages?.length, scrollPosition]);

  const isFetchedMessagesEmpty = isEmpty(fetchedMessages);
  useEffect(() => {
    // scroll to bottom when messages are fetched initially
    if (messagesRef.current && !isFetchedMessagesEmpty) {
      if (scrollPosition === 'bottom' && !isVisible) {
        messagesRef.current.scrollIntoView({
          block: 'end',
        });
      }
    }
  }, [scrollPosition, isVisible, isFetchedMessagesEmpty]);

  useEffect(() => {
    const { current } = scrollRef;

    if (current) {
      const handleScroll = _debounce(
        (event: Event) => {
          const target = event.target as HTMLDivElement;

          if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
            setScrollPosition('bottom');
          } else if (target.scrollTop <= 0) {
            setScrollPosition('top');
          } else {
            setScrollPosition(undefined);
          }
        },
        500,
        { leading: true }, // leading: true is required to set scrollPosition to undefined when user scrolls, otherwise it wont let user scroll for debounce interval
      );
      current.addEventListener('scroll', handleScroll, {
        passive: true,
      });

      return () => {
        current.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (visibilityRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
            } else {
              setIsVisible(false);
            }
          });
        },
        {
          rootMargin: '0px 0px -72px 0px',
        },
      );

      observer.observe(visibilityRef.current);

      return () => {
        observer.disconnect();
      };
    }
  });

  return {
    messagesRef,
    scrollRef,
    visibilityRef,
  };
};
