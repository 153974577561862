import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockType,
  ComponentTypeUnionType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { LinkComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import Link from './Link';

class LinkBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Link';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as LinkComponentType, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Link;
  }
}

export default LinkBlockStateDefinition;
