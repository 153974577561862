import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { Content as NavigationMenuContent } from '@unifyapps/defs/blocks/NavigationMenu/types';
import { getLabelNavItems } from '@unifyapps/defs/blocks/NavigationMenu/utils';
import type { Action } from '@unifyapps/defs/types/action';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importNavigationMenuComponent = () =>
  import(
    /* webpackChunkName: "navigation-menu-block" */
    './NavigationMenu'
  );

const NavigationMenu = lazy(importNavigationMenuComponent);

class NavigationMenuBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Navigation';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'changeNavigationItem' as const,
        label: 'Change Navigation Tab',
        method: (blockState: BlockStateUnionType, action: Action) => {
          const { methodPayload } = action.payload as {
            methodPayload: { destinationNavTabId: string };
          };
          const { destinationNavTabId } = methodPayload;

          const navItems = (blockState.content as NavigationMenuContent).navItems;
          const labelNavItems = getLabelNavItems(navItems ?? []);

          const adaptedNavItemValue = labelNavItems.find(
            (navItem) => navItem.id === destinationNavTabId,
          );

          return {
            ...blockState,
            content: {
              ...blockState.content,
              value: adaptedNavItemValue,
            },
          } as BlockStateUnionType;
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return NavigationMenu;
  }

  preload() {
    return {
      blockPreload: importNavigationMenuComponent,
    };
  }
}

export default NavigationMenuBlockStateDefinition;
