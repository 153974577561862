import type { BlockType, ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import _noop from 'lodash/noop';
import { useRegistryContext } from '../../RegistryProvider';

const useGetFallbackNode = ({
  fallback,
  isSuspended,
}: {
  fallback: BlockType['fallback'];
  isSuspended?: boolean;
}) => {
  const { registry } = useRegistryContext();

  if (!fallback) return { fallbackNode: null };

  const Block = registry.getBlockComponent(fallback.componentType);

  const initialFallbackState = registry.getBlockInitialState(fallback.componentType, {
    component: fallback,
    id: 'fallback',
  });

  const fallbackNode =
    isSuspended && Block && initialFallbackState ? (
      <Block
        blockState={initialFallbackState}
        component={fallback}
        computedBlockState={initialFallbackState as ComputedBlockStateUnionType}
        updateBlockState={_noop}
      />
    ) : null;

  return {
    fallbackNode,
  };
};

export default useGetFallbackNode;
