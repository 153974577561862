import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { DrawerComponentType } from '@unifyapps/defs/blocks/Drawer/types';
import _pick from 'lodash/pick';
import type { Action } from '@unifyapps/defs/types/action';
import { lazy } from 'react';

const importDrawerComponent = () =>
  import(
    /* webpackChunkName: "drawer-block" */
    './Drawer'
  );

const Drawer = lazy(importDrawerComponent);

class DrawerBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Drawer;
  }

  get type(): ComponentTypeUnionType {
    return 'Drawer';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [
      {
        id: 'showDrawer',
        label: 'Show',
        method: (_blockState: BlockStateUnionType, _: Action) => {
          return Promise.resolve({
            ..._blockState,
            state: {
              open: true,
            },
          } as BlockStateUnionType);
        },
      },
      {
        id: 'hideDrawer',
        label: 'Hide',
        method: (_blockState: BlockStateUnionType, _: Action, ref?: { close: () => void }) => {
          return Promise.resolve({
            ..._blockState,
            state: {
              open: false,
            },
          } as BlockStateUnionType);
        },
      },
    ];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as DrawerComponentType, ['content', 'appearance', 'componentType']),
      state: {
        open: false,
      },
    });
  }

  preload() {
    return {
      blockPreload: importDrawerComponent,
    };
  }
}

export default DrawerBlockStateDefinition;
