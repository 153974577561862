import { type CSSProperties, memo, type SyntheticEvent } from 'react';
import List from '@mui/joy/List';
import { clsx } from 'clsx';
import { Divider } from '../Divider';
import type { MenuItemProps } from './MenuItem';
import MenuItem from './MenuItem';
import ConfirmationMenuItem from './ConfirmationMenuItem';

export type ListMenuItem = Omit<MenuItemProps, 'onClick'> & {
  type: 'label';
  id: string;
  titleClassName?: string;
};

type ListMenuDivider = {
  type: 'divider';
};

type ConfirmMenuItem = Omit<MenuItemProps, 'onClick'> & {
  type: 'confirmation';
  confirmationTitle: string;
  id: string;
  titleClassName?: string;
};

export type MenuListItem = ListMenuItem | ListMenuDivider | ConfirmMenuItem;

export type MenuListProps = {
  items: MenuListItem[];
  onAction: (id: string, event: SyntheticEvent) => void;
  className?: string;
  size?: 'sm' | 'md';
  style?: CSSProperties;
};

const MenuList = memo<MenuListProps>(function MenuList(props) {
  const { items, onAction, className, size = 'md', ...rest } = props;

  return (
    <List className={clsx('py-xs', className)} {...rest}>
      {items.map((item, index) => {
        switch (item.type) {
          case 'label':
            return (
              <MenuItem
                caption={item.caption}
                className={item.className}
                disabled={item.disabled}
                endDecoratorComponent={item.endDecoratorComponent}
                endDecoratorNode={item.endDecoratorNode}
                helpText={item.helpText}
                key={item.id}
                onClick={(event) => {
                  event.stopPropagation();
                  onAction(item.id, event);
                }}
                selected={item.selected}
                size={size}
                startDecoratorComponent={item.startDecoratorComponent}
                startDecoratorNode={item.startDecoratorNode}
                title={item.title}
                titleClassName={item.titleClassName}
                variant={item.variant}
              />
            );
          case 'confirmation':
            return (
              <ConfirmationMenuItem
                caption={item.caption}
                className={item.className}
                confirmationTitle={item.confirmationTitle}
                disabled={item.disabled}
                helpText={item.helpText}
                key={item.id}
                onClick={(event) => {
                  event.stopPropagation();
                  onAction(item.id, event);
                }}
                selected={item.selected}
                size={size}
                startDecoratorComponent={item.startDecoratorComponent}
                startDecoratorNode={item.startDecoratorNode}
                title={item.title}
                titleClassName={item.titleClassName}
                variant={item.variant}
              />
            );
          case 'divider':
            // eslint-disable-next-line react/no-array-index-key -- divider doesn't need a key
            return <Divider className="mx-md my-xs" key={`${item.type}:${index}`} />;
          default:
            return null;
        }
      })}
    </List>
  );
});

export default MenuList;
