import { cva } from 'class-variance-authority';
import { clsx } from 'clsx';
import type { ButtonGroupSizes } from './types';

// TODO these styles are not DS consistent, and we need to get them updated
export const buttonGroupButtonBaseClassname = clsx(
  // basic styles
  'flex flex-row items-center justify-center font-semibold',
  // normal button. need important for border color because joy adds its own color for disabled state
  'bg-primary text-fg-tertiary border !border-primary disabled:text-fg-disabled',
  // PRESSED button (Current=True in figma)
  'aria-pressed:bg-quaternary aria-pressed:disabled:bg-disabled_subtle',
  // interactive states
  'hover:bg-active hover:text-fg-tertiary_hover active:bg-primary_hover active:text-fg-tertiary_hover focus-visible:ring-gray',
);

export const buttonGroupButtonSizeVariants = cva('', {
  variants: {
    size: {
      xs: 'px-sm py-xxs gap-xs max-h-[1.25rem] text-xxs',
      sm: 'px-lg py-xs gap-sm max-h-8 text-sm',
      md: 'px-xl py-md gap-sm max-h-9 text-sm',
    } satisfies Record<ButtonGroupSizes, string>,
  },
});

export const buttonGroupIconButtonSizeVariants = cva('', {
  variants: {
    size: {
      xs: 'px-sm py-xxs max-h-[1.25rem]',
      sm: 'px-md py-xs max-h-8',
      md: 'px-lg py-md max-h-9',
    } satisfies Record<ButtonGroupSizes, string>,
  },
});
