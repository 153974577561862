import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { TimerModes } from '../types';

const getInitialTimerState = ({
  startValue,
  endValue,
}: {
  startValue: number;
  endValue: number;
}): {
  startTime: number;
  endTime: number;
  currentTime: number;
  isRunning: boolean;
  initTime: number;
} => {
  const initTime = Date.now();

  return {
    startTime: initTime + startValue,
    currentTime: initTime + startValue,
    endTime: initTime + endValue,
    isRunning: false,
    initTime,
  };
};

export const useTimer = ({
  startValue,
  endValue,
  mode,
  interval,
  onTimerExpire,
  onTimerIntervalElapsed,
  eventTimeInterval,
}: {
  startValue: number;
  endValue: number;
  mode: TimerModes;
  interval: number;
  onTimerExpire?: () => void;
  onTimerIntervalElapsed?: () => void;
  eventTimeInterval: number | undefined;
}) => {
  const [timerState, setTimerState] = useState(() =>
    getInitialTimerState({ startValue, endValue }),
  );
  // Ref to keep track if interval has been set once and it do not set up again
  const eventIntervalRef = useRef(false);

  useEffect(() => {
    if (eventTimeInterval && !eventIntervalRef.current && timerState.isRunning) {
      const intervalId = setInterval(() => {
        eventIntervalRef.current = true;
        onTimerIntervalElapsed?.();
      }, eventTimeInterval);
      return () => clearInterval(intervalId);
    }
  }, [eventTimeInterval, onTimerIntervalElapsed, timerState.isRunning]);

  const stop = useCallback(() => {
    setTimerState((prev) => ({
      ...prev,
      isRunning: false,
    }));
    eventIntervalRef.current = false;
  }, []);

  const start = useCallback(() => {
    const initTime = Date.now();

    setTimerState((prev) => {
      const remTime = prev.currentTime - prev.initTime;

      return {
        startTime: initTime + startValue,
        endTime: initTime + endValue,
        currentTime: initTime + remTime,
        initTime,
        isRunning: true,
      };
    });
  }, [endValue, startValue]);

  const reset = useCallback(() => {
    setTimerState(getInitialTimerState({ startValue, endValue }));
    eventIntervalRef.current = false;
  }, [endValue, startValue]);

  useLayoutEffect(() => {
    const { currentTime, startTime, endTime, isRunning } = timerState;

    if (startTime - endTime !== startValue - endValue) {
      return reset();
    }

    if (!isRunning) {
      return;
    }

    //todo: add error handling for interval
    if (interval <= 0) {
      return;
    }

    const timer = setTimeout(() => {
      const _currentTime = Date.now();

      if (mode === TimerModes.Countdown) {
        //setting initial Time to Date.now() because we cannot decrease the value of currentTime
        //Hence, in order to reduce the difference between currentTime and initTime, increasing the initTime.
        if (currentTime - _currentTime <= 0) {
          setTimerState((prev) => ({ ...prev, initTime: currentTime, isRunning: false }));
          onTimerExpire?.();
        } else {
          setTimerState((prev) => ({
            ...prev,
            initTime: _currentTime,
          }));
        }
      } else if (_currentTime >= endTime) {
        setTimerState((prev) => ({ ...prev, currentTime: endTime, isRunning: false }));
        onTimerExpire?.();
      } else {
        setTimerState((prev) => ({
          ...prev,
          currentTime: _currentTime,
        }));
      }
    }, interval);

    return () => clearTimeout(timer);
  }, [endValue, interval, mode, onTimerExpire, reset, startValue, timerState]);

  const totalMilliSeconds =
    mode === TimerModes.Countdown
      ? timerState.currentTime - timerState.initTime
      : timerState.currentTime - timerState.initTime;

  return { stop, start, reset, totalMilliSeconds, isRunning: timerState.isRunning };
};
