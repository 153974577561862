import React, { createContext, useCallback, useContext, useState, useMemo } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import type { CitationType } from '@unifyapps/defs/blocks/Citation/types';

export type CitationsPanelContextType = {
  citations: CitationType[];
  isPanelOpen: boolean;
  showPanel: (citations: CitationType[]) => void;
  closePanel: () => void;
};

export const CitationsPanelContext = createContext<CitationsPanelContextType | null>(null);

export function useCitationsPanelContext() {
  const context = useContext(CitationsPanelContext);
  if (!context) {
    throw new Error('useCitationsPanelContext must be used within a CitationPanelProvider');
  }
  return context;
}

export function CitationPanelProvider({ children }: { children: React.ReactNode }) {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [citations, setCitations] = useState<CitationType[]>([]);

  const showPanel = useEventCallback((citationsToShow: CitationType[]) => {
    setCitations(citationsToShow);
    setIsPanelOpen(true);
  });

  const closePanel = useCallback(() => {
    setIsPanelOpen(false);
    setCitations([]);
  }, []);
  const contextValue = useMemo(
    () => ({ citations, isPanelOpen, showPanel, closePanel }),
    [citations, closePanel, isPanelOpen, showPanel],
  );

  return (
    <CitationsPanelContext.Provider value={contextValue}>{children}</CitationsPanelContext.Provider>
  );
}
