import { default as BaseButtonGroup } from '@mui/joy/ButtonGroup';
import { clsx } from 'clsx';
import ButtonGroupButton from './ButtonGroupButton';
import ButtonGroupIconButton from './ButtonGroupIconButton';
import type { ButtonGroupProps } from './types';

function ButtonGroup(props: ButtonGroupProps) {
  const { className, disabled, size, ...rest } = props;

  return (
    <BaseButtonGroup
      className={clsx('shadow-xs', className)}
      disabled={disabled}
      // @ts-expect-error -- size is of same type as ButtonGroupSizes. We are not overriding internal types currently
      size={size}
      {...rest}
    />
  );
}

ButtonGroup.displayName = 'ButtonGroup';

const ButtonGroupNamespace = Object.assign(ButtonGroup, {
  Button: ButtonGroupButton,
  IconButton: ButtonGroupIconButton,
});

export default ButtonGroupNamespace;
