import type { KeyValueComponentType } from '@unifyapps/defs/types/blocks';
import type { KeyValueData } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import _uniqueId from 'lodash/uniqueId';

export const generateKeyValueBlock = ({
  data,
}: {
  data: KeyValueData;
}): {
  blocks: Record<string, { id: string; component: KeyValueComponentType }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX),
    containerId = `container_${blockId}`,
    cardId = `card_${blockId}`;

  return {
    containerId,
    //@ts-expect-error -- we need to add all properties here
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'KeyValue',
          appearance: {
            labelPosition: 'LEFT',
            groupLayout: 'LIST',
            showMoreLabel: 'Show more',
            styles: {
              gap: {
                all: 'gap-xs',
              },
              padding: {
                all: 'p-0',
              },
            },
            size: 'md',
            showDivider: true,
          },
          content: {
            fields: data.fields.map((datum, index) => ({
              id: datum.id ?? _uniqueId('field'),
              content: {
                type: datum.type,
                value: datum.value,
                label: datum.label,
                description: datum.description,
                currencyCode: datum.currencyCode ?? 'USD',
                dateFormat: datum.dateFormat,
                precision: datum.precision,
                notation: datum.notation,
                maxSignificantUnits: datum.maxSignificantUnits ?? 2,
                displayText: datum.displayText,
                decimalPlaces: datum.decimalPlaces,
                unit: datum.unit,
                style: datum.style,
                blockId: datum.blockId,
              },
              appearance: {
                visibility: {
                  value: 'true',
                },
              },
              addOns: {},
            })),
          },
        },
      },
      [cardId]: {
        id: cardId,
        component: {
          componentType: 'Card',
          content: {},
          slots: {
            body: {
              blockId,
            },
          },
          appearance: {
            styles: {
              borderRadius: { all: 'rounded-xl' },
              borderColor: 'border-secondary',
              borderWidth: { all: 'border' },
              gap: { all: 'gap-lg' },
              backgroundColor: 'bg-primary',
              padding: { x: 'px-lg', y: 'py-md' },
            },
          },
        },
      },
      [containerId]: {
        id: containerId,
        component: {
          componentType: 'Container',
          appearance: {
            variant: '2_1',
          },
          content: {
            blockIds: [cardId],
          },
        },
      },
    },
  };
};
