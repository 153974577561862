import { useCallback, useEffect, useMemo, useState } from 'react';
import { produce } from 'immer';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import useExecuteNode from '@unifyapps/carbon/copilot/hooks/useExecuteNode';
import useCopilotActions from '@unifyapps/carbon/copilot/hooks/useCopilotActions';
import AIAutomations from '@unifyapps/carbon/copilot/AIAutomations';
import type { Message } from '@unifyapps/defs/blocks/Chat/types';
import { useTranslation } from '@unifyapps/i18n/client';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import _keyBy from 'lodash/keyBy';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';
import type { FileType } from '@unifyapps/defs/blocks/FileUpload/types';
import { useChatContext } from '../components/ChatContext';
import type { MessageWithBlocks } from '../components/ChatMessage/hooks/useGetPage';
import { useGetMessageBlocks } from '../components/ChatMessage/hooks/useGetPage';
import MessageHelper from '../helpers/MessageHelper';
import { NEW_MESSAGE_ID, newUserMessage, useSubmitUserMessage } from './useSubmitUserMessage';
import { useLookupMessage } from './useLookupMessage';
import { useClearConversation } from './useClearConversation';
import type { NewMessage } from './useSubscribeNewMessage';
import usePollForNewMessages from './usePollForNewMessages';
import { useInitialAwaitingContext } from './useInitialAwaitingContext';

/**
 * All new messages that are not yet fetched from the server (using pagination query stored in fetchedMessages) are stored in this state
 */
const useMessageState = () => {
  const [newMessages, setNewMessages] = useState<Message[]>([]);
  const clearMessage = useCallback(() => setNewMessages([]), []);
  /**
   * Function to upsert a message, it will update the message with given id if found, otherwise it will add a new message,
   * when using updater fn it must return new Message object, otherwise it will be ignored,
   * Mutating new message in updater fn is ok
   */
  const upsertMessage = useCallback(
    (messageId: string, message: Message | ((prev?: Message) => Message | undefined)) => {
      setNewMessages((prevMessages) => {
        const messageIndex = prevMessages.findIndex((msg) => msg.messageId === messageId);

        return produce(prevMessages, (draft) => {
          const newMessage = typeof message === 'function' ? message(draft[messageIndex]) : message;
          if (!newMessage) return;

          if (messageIndex !== -1) {
            draft[messageIndex] = newMessage;
          } else {
            draft.push(newMessage);
          }
        });
      });
    },
    [],
  );

  const popMessage = useCallback((): Message | undefined => {
    let message: Message | undefined;
    setNewMessages((prevMessages) => {
      return produce(prevMessages, (draft) => {
        message = draft.pop();
      });
    });
    return message;
  }, []);

  const addBatchMessages = useCallback((messages: Message[]) => {
    setNewMessages((prevMessages) =>
      produce(prevMessages, (draft) => {
        const draftByMessageId = _keyBy(draft, 'messageId') as Record<string, Message | undefined>;
        messages.forEach((message) => {
          if (!draftByMessageId[message.messageId]) {
            draft.push(message);
          } else {
            draftByMessageId[message.messageId] = message;
          }
        });
        return draft;
      }),
    );
  }, []);

  return [newMessages, { upsertMessage, clearMessage, popMessage, addBatchMessages }] as const;
};

export function useMessageActions({
  automationsConfig,
  chatId,
  userId,
  fetchedMessages,
}: {
  automationsConfig: AutomationConfig;
  fetchedMessages?: Message[];
  userId: string;
  chatId?: string;
}) {
  const { getNewMessageContext, onLookupMessage, getPreviousMessageContext, getMessages } =
    useChatContext();

  const [initialIsAwaitingResponse, setInitialIsAwaitingResponse] = useInitialAwaitingContext();

  /**
   * state to show loader after user has entered the message, and waiting for response from MQTT
   */
  const [isAwaitingResponse, setIsAwaitingResponse] = useState<boolean>(initialIsAwaitingResponse);
  const [newMessages, { upsertMessage, clearMessage, popMessage, addBatchMessages }] =
    useMessageState();

  const getMessageBlocks = useGetMessageBlocks();

  const onNewPollMessages = useEventCallback((messages: Message[], isStillPolling: boolean) => {
    addBatchMessages(messages);
    if (!isStillPolling) {
      setIsAwaitingResponse(false);
    }
  });

  const { stopPolling, startPolling } = usePollForNewMessages(
    onNewPollMessages,
    chatId,
    automationsConfig,
  );

  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const onError = useCallback(
    (error: Error) => {
      showSnackbar({
        color: 'error',
        title: 'message' in error ? error.message : t('common:ErrorState.SomethingWentWrong'),
      });
    },
    [showSnackbar, t],
  );

  const triggerPolling = useEventCallback(() => {
    const lastMessageTime = newMessages[newMessages.length - 1]?.createdTime;
    if (lastMessageTime) startPolling(lastMessageTime);
  });

  const {
    createCase,
    fetchMessage,
    clearConversation: clearConversationAction,
    retryCase,
  } = useCopilotActions(automationsConfig);

  const { mutate: submitUserMessage } = useSubmitUserMessage({
    upsertMessage,
    setIsAwaitingResponse,
    onError,
    triggerPolling,
    onLookupMessage,
  });

  useEffect(() => {
    if (newMessages.length) {
      return;
    }
    setInitialIsAwaitingResponse((prevIsAwaitingResponse) => {
      if (prevIsAwaitingResponse) {
        const lastFetchedMessage = fetchedMessages?.[fetchedMessages.length - 1];
        const lastMessageTime = lastFetchedMessage?.createdTime;
        lastMessageTime && startPolling(lastMessageTime);
      }
      return false;
    });
  }, [
    fetchedMessages,
    newMessages.length,
    setInitialIsAwaitingResponse,
    startPolling,
    triggerPolling,
  ]);

  const fetchedMessagesWithBlocks = useMemo(
    () =>
      (fetchedMessages ?? []).map((message) => ({ ...message, blocks: getMessageBlocks(message) })),
    [fetchedMessages, getMessageBlocks],
  );

  const messageMap = useMemo(() => {
    // on refocus fetchMessage is refetched, and it includes newMessages also.
    // so we need to merge newMessages with fetchedMessages with unique messageId
    const messageIdVsMessage = new Map<string, MessageWithBlocks>();
    fetchedMessagesWithBlocks.forEach((message) =>
      messageIdVsMessage.set(message.messageId, message),
    );

    newMessages.forEach((message) => {
      const existingMessage = messageIdVsMessage.get(message.messageId);
      if (existingMessage) {
        messageIdVsMessage.set(message.messageId, { ...existingMessage, ...message });
      } else {
        messageIdVsMessage.set(message.messageId, {
          ...message,
          blocks: getMessageBlocks(message),
        });
      }
    });
    return messageIdVsMessage;
  }, [fetchedMessagesWithBlocks, getMessageBlocks, newMessages]);

  const messages = useMemo(
    () => Array.from(messageMap.values()).sort((a, b) => a.createdTime - b.createdTime),
    [messageMap],
  );

  const { mutate: lookupInlineMessage } = useLookupMessage({
    upsertMessage,
    chatId,
    setIsAwaitingResponse,
    onError,
  });

  const refetchMessage = useEventCallback((messageId: string) => {
    return lookupInlineMessage(fetchMessage(messageId));
  });

  const onNewMessage = useEventCallback((newMessage: NewMessage) => {
    onLookupMessage?.(newMessage);
    if (newMessage.messageId && !messageMap.has(newMessage.messageId)) {
      stopPolling();
    }
    if (newMessage.messageId) {
      const messageInput = fetchMessage(newMessage.messageId);
      return messageInput.automationId && lookupInlineMessage(messageInput);
    }
  });

  const { mutate: clearConversation } = useClearConversation({
    chatId,
    setIsAwaitingResponse,
    clearMessage,
    onError,
  });

  const { mutate: regenerateResponse } = useExecuteNode({
    onError,
    onSettled: () => {
      setIsAwaitingResponse(true);
      triggerPolling();
    },
  });

  const onSubmitMessage = useEventCallback(
    (
      { message, attachments }: { message: string; attachments?: FileType[] },
      contextMessageId?: string,
    ) => {
      if (isAwaitingResponse) {
        return;
      }

      setIsAwaitingResponse(true);
      message && upsertMessage(NEW_MESSAGE_ID, newUserMessage({ message, attachments }, chatId));

      submitUserMessage(
        createCase({
          caseId: chatId,
          userId,
          ...(message ? { message } : undefined),
          ...(contextMessageId ? { contextMessageId } : undefined),
          ...getNewMessageContext?.(),
          ...(attachments ? { attachments } : undefined),
        }),
      );
    },
  );

  const onClearConversation = useCallback(
    () => clearConversation(clearConversationAction(chatId)),
    [chatId, clearConversation, clearConversationAction],
  );

  const onRegenerateResponse = useEventCallback((messageId: string) => {
    setIsAwaitingResponse(true);
    popMessage();
    regenerateResponse(
      retryCase({
        ...MessageHelper.retryMessage({ userId, caseId: chatId ?? '', responseId: messageId }),
        ...getPreviousMessageContext?.(),
      }),
    );
  });

  useEffect(() => {
    getMessages?.(messages);
  }, [getMessages, messages]);

  return {
    messages,
    onNewMessage,
    onClearConversation,
    onSubmitMessage,
    isAwaitingResponse,
    onRegenerateResponse: AIAutomations.getRetryCase(automationsConfig)
      ? onRegenerateResponse
      : undefined,
    refetchMessage,
  };
}
