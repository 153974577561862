import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type { BlockType, ComponentTypeUnionType } from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { CitationComponentType } from '@unifyapps/defs/blocks/Citation/types';
import Citation from './Citation';

class CitationBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Citation;
  }

  get type(): ComponentTypeUnionType {
    return 'Citation';
  }

  useBlockRef?: boolean | undefined = true;

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) => ({
      id: block.id,
      ..._pick(block.component as CitationComponentType, [
        'content',
        'appearance',
        'componentType',
      ]),
    });
  }
}

export default CitationBlockStateDefinition;
