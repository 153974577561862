import type { ChartComponentType } from '@unifyapps/defs/blocks/Chart/types';
import type { ChartBlockData, ChartBlockAppearance } from '@unifyapps/defs/blocks/Chat/types';
import type { CardComponentType } from '@unifyapps/defs/types/blocks';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';

const getAdaptedSeries = (series?: { key: string; label?: string }[]) =>
  series?.map(({ key, label }) => ({
    id: key,
    value: { path: key, originalPath: key, key, originalKey: key },
    label,
    dataLabels: {
      enabled: false,
    },
  }));

export const generateChartBlock = ({
  data,
  appearance,
}: {
  data: ChartBlockData;
  appearance?: ChartBlockAppearance;
}): {
  blocks: Record<
    string,
    {
      id: string;
      component: ChartComponentType | CardComponentType;
    }
  >;
  containerId: string;
} => {
  const {
    chartType,
    chartData,
    xAxisValue,
    groupBy,
    xAxis,
    yAxis,
    yAxisRight,
    showLegends,
    showTotal,
    visibleDataPoints,
    series,
  } = data;

  const blockId = getIdWithPrefix(BLOCK_PREFIX),
    cardId = `card_${blockId}`;

  return {
    containerId: cardId,
    //@ts-expect-error -- converting from ChartBlockParams to component type according to chartType
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Chart',
          content: {
            chartType,
            data: chartData as unknown as string,
            xAxis: {
              ...xAxis,
              scale: xAxis?.scale ?? 'linear',
              tickLabels: xAxis?.tickLabels ?? { enabled: true },
              xAxisValue: {
                path: xAxisValue,
                originalPath: xAxisValue,
                key: xAxisValue,
                originalKey: xAxisValue,
              },
              groupBy: groupBy
                ? {
                    path: groupBy,
                    originalPath: groupBy,
                    key: groupBy,
                    originalKey: groupBy,
                  }
                : undefined,
            },
            yAxis: {
              ...yAxis,
              scale: yAxis?.scale ?? 'linear',
              tickLabels: yAxis?.tickLabels ?? { enabled: true },
              series: getAdaptedSeries(yAxis?.series ?? series) ?? [],
            },
            yAxisRight: {
              ...yAxisRight,
              scale: yAxisRight?.scale ?? 'linear',
              tickLabels: yAxisRight?.tickLabels ?? { enabled: true },
              series: getAdaptedSeries(yAxisRight?.series) ?? [],
            },
            showLegends: showLegends ?? true,
            showTotal: showTotal ?? false,
            visibleDataPoints: visibleDataPoints ?? chartData.length,
          },
          appearance: {
            chartColours: appearance?.chartColours ?? {
              colourBy: 'SERIES',
              colourMapping: {},
            },
            styles: {
              height: {
                custom: '300px',
              },
            },
          },
        },
      },
      [cardId]: {
        id: cardId,
        component: {
          componentType: 'Card',
          content: {},
          slots: {
            body: {
              blockId,
            },
          },
          appearance: {
            styles: {
              borderRadius: { all: 'rounded-xl' },
              borderColor: 'border-secondary',
              borderWidth: { all: 'border' },
              gap: { all: 'gap-lg' },
              backgroundColor: 'bg-primary',
              padding: { all: 'p-xl' },
            },
          },
        },
      },
    },
  };
};
