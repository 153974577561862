import { useMemo } from 'react';
import type {
  ButtonGroupBlockStateType,
  Option,
  MappedOptions,
} from '@unifyapps/defs/blocks/ButtonGroup/types';
import { ButtonGroupModes } from '@unifyapps/defs/blocks/ButtonGroup/types';
import _map from 'lodash/map';
import _get from 'lodash/get';

export const getAccessorKey = (accessor: string | { path: string }) => {
  return accessor && typeof accessor === 'object' ? accessor.path : accessor;
};

export const useComputeButtonGroupOptions = ({
  mode,
  blockState,
  options,
}: {
  mode: ButtonGroupModes;
  blockState: ButtonGroupBlockStateType;
  options: Record<string, unknown>[] | undefined;
}): Option[] | undefined =>
  useMemo(() => {
    if (mode === ButtonGroupModes.manual) {
      return options as Option[];
    }

    const mappedOptions = blockState.content.options as MappedOptions | undefined;

    return mappedOptions?.id
      ? _map(options, (option) => {
          return {
            id: mappedOptions.id ? _get(option, getAccessorKey(mappedOptions.id)) : '',
            label: mappedOptions.label ? _get(option, getAccessorKey(mappedOptions.label)) : '',
          } as Option;
        })
      : [];
  }, [blockState.content, mode, options]);
