import _get from 'lodash/get';
import _castArray from 'lodash/castArray';
import type { MediaBlockStateType } from '@unifyapps/defs/blocks/Media/types';
import { MediaDataType } from '@unifyapps/defs/blocks/Media/types';
import { useMemo } from 'react';

export const useAdaptMedia = ({ src, type, mappings }: MediaBlockStateType['content']) => {
  return useMemo(() => {
    if (type === MediaDataType.Static || !src) return src;

    if (typeof src === 'string') return src;

    if (Array.isArray(src)) {
      if (src.length === 0) return src;
      if (src.some((item) => typeof item === 'string')) {
        return src;
      }
    }

    const idPath = mappings?.id?.path ?? 'id';
    const namePath = mappings?.name?.path ?? 'name';
    const urlPath = mappings?.url?.path ?? 'url';
    const captionPath = mappings?.caption?.path ?? 'caption';
    const mimeTypePath = mappings?.mimeType?.path ?? 'mimeType';

    const media = _castArray<MediaDataType>(src as unknown as MediaDataType | MediaDataType[]);

    return media.map((item) => ({
      id: _get(item, idPath) as string,
      name: _get(item, namePath) as string,
      url: _get(item, urlPath) as string,
      caption: _get(item, captionPath) as string,
      mimeType: _get(item, mimeTypePath) as string,
    })) as unknown as MediaDataType[];
  }, [
    mappings?.caption?.path,
    mappings?.id?.path,
    mappings?.mimeType?.path,
    mappings?.name?.path,
    mappings?.url?.path,
    src,
    type,
  ]);
};
