import { useRef } from 'react';
import type { BlockStateUnionType } from '@unifyapps/defs/types/block';
import { useMountEffect, useUnmountEffect } from '@react-hookz/web';
import { invariant } from 'ts-invariant';
import { useRegistryContext } from '../components/RegistryProvider';
import { useGlobalStateStore } from '../stores/GlobalStateStore';

export function useSetBlockRef({ blockState }: { blockState?: BlockStateUnionType }) {
  const ref = useRef();
  const { registry } = useRegistryContext();
  const componentType = blockState?.componentType;
  const useBlockRef = registry.getUseBlockRef(componentType);
  const blockId = blockState?.id;

  const { setBlockRef, removeBlockRef } = useGlobalStateStore().use.actions();

  // invariant(blockId, 'blockId should be present inside blockState');

  useMountEffect(() => {
    if (!useBlockRef) return;
    blockId && setBlockRef(blockId, ref);
  });

  useUnmountEffect(() => {
    if (!useBlockRef) return;
    blockId && removeBlockRef(blockId);
  });

  return {
    blockRef: ref,
  };
}
