import { Form } from '@unifyapps/carbon/form';
import Modal from '@unifyapps/carbon/components/Modal';
import { useTranslation } from '@unifyapps/i18n/client';
import { FeedbackReaction, type Feedback } from '@unifyapps/defs/blocks/Chat/types';
import { useCallback, useMemo, useRef } from 'react';
import type { FormProps, FormType } from '@unifyapps/form/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import SvgThumbsDown from '@unifyapps/icons/outline/ThumbsDown';
import { useGetEntityType } from '@unifyapps/network/generated/entity-type-rest-api/entity-type-rest-api';
import { EntityTypeVsAssetClass } from '@unifyapps/carbon/consts/permissions';
import AsyncView from '@unifyapps/carbon/components/AsyncView';
import { produce } from 'immer';
import _pick from 'lodash/pick';
import useMutationHelpers from '@unifyapps/carbon/hooks/useMutationHelpers';
import { uiSchema } from './schema';

type Props = {
  onClose: () => void;
  feedback?: Feedback;
  onSaveFeedback: (feedback: Feedback, onSuccess?: () => void) => void;
  isPending?: boolean;
};

export function FeedbackModal({ onClose, feedback, onSaveFeedback, isPending }: Props) {
  const { t } = useTranslation(['copilot']);
  const formRef = useRef<FormType>(null);

  const { onShowSuccessSnackbar } = useMutationHelpers();

  const handleSuccess = useEventCallback(() => {
    onShowSuccessSnackbar({ title: t('copilot:Actions.ThanksForFeedback') });
    onClose();
  });

  const handleSubmit = useEventCallback<NonNullable<FormProps['onSubmit']>>(
    ({ formData }: { formData?: Feedback }) => {
      onSaveFeedback({ ...formData, reaction: FeedbackReaction.DISLIKE }, handleSuccess);
    },
  );
  const { data, isLoading, error } = useGetEntityType({
    entityType: EntityTypeVsAssetClass.CopilotFeedback,
  });

  const schema = useMemo(() => {
    const jsonSchema = produce(data?.schema?.schema ?? {}, (draft) => {
      delete draft.additionalProperties;
      draft.properties = _pick(draft.properties, ['feedbackCategories', 'userFeedback']);
    });
    return jsonSchema;
  }, [data?.schema?.schema]);

  const submitForm = useCallback(() => {
    formRef.current?.submit();
  }, []);

  return (
    <Modal minHeight="480px" onClose={onClose} open variant="md">
      <Modal.Header.Horizontal
        Icon={SvgThumbsDown}
        description={t('copilot:Actions.ProvideFeedbackDescription')}
        onClose={onClose}
        title={t('copilot:Actions.ProvideFeedback')}
      />
      <Modal.Content.Base className="justify-center">
        <AsyncView data={data} error={error} isLoading={isLoading}>
          <Form
            className="px-lg pb-xl flex-1 overflow-auto"
            formData={feedback}
            onSubmit={handleSubmit}
            ref={formRef}
            schema={schema}
            uiSchema={uiSchema}
          />
        </AsyncView>
      </Modal.Content.Base>
      <Modal.Footer.HorizontalRight
        cancelLabel={t('common:Actions.Cancel')}
        isPending={isPending}
        onCancel={onClose}
        onSubmit={submitForm}
        submitLabel={t('common:Actions.Submit')}
      />
    </Modal>
  );
}
