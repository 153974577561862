import { useEffect, useMemo, useRef } from 'react';
import type { EditorRef } from '@unifyapps/editor/types';
import { clsx } from 'clsx';
import BasicExtensions from '@unifyapps/editor/extensions/basic';
import _isEmpty from 'lodash/isEmpty';
import { Editor } from '@unifyapps/editor/editors/Editor';

export function TextareaEditor({
  ownerState,
  className,
  onInputChange,
  value,
}: {
  onInputChange: (value: string) => void;
  ownerState: {
    placeholder?: string;
    onKeyDown?: () => void;
    disabled?: boolean;
  };
  className: string;
  value?: string;
}) {
  const editorRef = useRef<EditorRef>(null);
  const extensions = useMemo(
    () => [
      BasicExtensions.configure({
        placeholder: { placeholder: ownerState.placeholder },
      }),
    ],
    [ownerState.placeholder],
  );

  useEffect(() => {
    const editorContent = editorRef.current?.getContent();

    if (!value) {
      editorRef.current?.clearContent();
      return;
    }

    //if the value changes from outside (i.e. if the messageInput is controlled) then update the editor content
    if (value !== editorContent) {
      editorRef.current?.setContent(value);
    }
  }, [value]);

  const hasMultipleLines = Boolean(value?.includes('\n'));

  return (
    <Editor
      className={clsx('text-md break-anywhere overflow-auto', className)}
      containerClassName={clsx('grow', { 'w-full': hasMultipleLines })}
      editable={!ownerState.disabled}
      extensions={extensions}
      initialContent={value}
      onChange={onInputChange}
      onKeyDown={ownerState.onKeyDown}
      ref={editorRef}
    />
  );
}
