import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import _pick from 'lodash/pick';
import Container from './Container';

class ContainerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'Container';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component, ['content', 'appearance', 'componentType']),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return Container;
  }
}

export default ContainerBlockStateDefinition;
