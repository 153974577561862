import type { JSONSchema7WithForiegnKey } from '../../../no-code/utils/dataSourceLookup/types';
import type { PathObj } from '../../../utils/schema/utils';
import { getPrimitiveFieldPaths } from '../../../utils/schema/utils';

export function getAllPaths({
  schema,
  basePath = [],
  shouldTraverseSchema,
}: {
  schema?: JSONSchema7WithForiegnKey;
  basePath?: string[];
  shouldTraverseSchema?: ({
    schema,
  }: {
    schema: JSONSchema7WithForiegnKey | JSONSchema7WithForiegnKey[];
  }) => boolean;
}): PathObj[] | undefined {
  if (!schema) {
    return;
  }

  if (schema.type === 'array' && schema.items) {
    return getPrimitiveFieldPaths({
      schema: schema.items,
      resolvedPath: basePath.length ? [basePath[basePath.length - 1]] : [],
      shouldTraverseSchema,
    });
  }

  return getPrimitiveFieldPaths({
    schema,
    resolvedPath: basePath.length ? [basePath[basePath.length - 1]] : [],
    shouldTraverseSchema,
  });
}
