import useMutationHelpers from '@unifyapps/carbon/hooks/useMutationHelpers';
import useExecuteNode from '@unifyapps/carbon/copilot/hooks/useExecuteNode';
import type React from 'react';

export const useClearConversation = ({
  onError,
  clearMessage,
  setIsAwaitingResponse,
  chatId,
}: {
  onError: (error: unknown) => void;
  clearMessage: () => void;
  setIsAwaitingResponse: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  chatId?: string;
}) => {
  const { onInvalidateQueries } = useMutationHelpers();

  return useExecuteNode({
    onError,
    onSuccess: () => {
      onInvalidateQueries([['fetchConversation', { chatId }]]);
      clearMessage();
      setIsAwaitingResponse(false);
    },
  });
};
