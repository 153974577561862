import type { MouseEvent } from 'react';
import { memo } from 'react';
import ListItem from '@mui/joy/ListItem';
import { clsx } from 'clsx';
import { Box } from '../Box';
import type { IconComponentType } from '../../types/index';
import { Typography } from '../Typography';
import Stack from '../../_components/Stack';
import { menuItemHelpTextVariants, menuItemIconVariants, menuItemTitleVariants } from './styles';

export type MenuItemProps = {
  startDecoratorComponent?: IconComponentType;
  startDecoratorNode?: React.ReactNode;
  endDecoratorComponent?: IconComponentType;
  endDecoratorNode?: React.ReactNode;
  title: string;
  caption?: string;
  helpText?: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
  onMouseOver?: (event: MouseEvent) => void;
  variant?: 'default' | 'danger';
  disabled?: boolean;
  selected?: boolean;
  size?: 'sm' | 'md';
  titleClassName?: string;
};

const MenuItem = memo(function MenuItem(props: MenuItemProps) {
  const {
    title,
    helpText,
    className,
    onClick,
    onMouseOver,
    variant = 'default',
    startDecoratorComponent: StartDecoratorComponent,
    startDecoratorNode,
    endDecoratorComponent: EndDecoratorComponent,
    endDecoratorNode,
    disabled = false,
    selected,
    size = 'md',
    titleClassName: _titleClassName,
  } = props;

  const iconClassName = menuItemIconVariants({ variant, disabled });
  const titleClassName = menuItemTitleVariants({ variant, disabled, className: _titleClassName });
  const helpTextClassName = menuItemHelpTextVariants({ variant, disabled });

  const startDecorator = StartDecoratorComponent ? (
    <StartDecoratorComponent className={iconClassName} height={16} width={16} />
  ) : (
    startDecoratorNode
  );

  const endDecorator = EndDecoratorComponent ? (
    <EndDecoratorComponent
      className={clsx('justify-self-end', iconClassName)}
      height={16}
      width={16}
    />
  ) : (
    endDecoratorNode
  );

  return (
    <ListItem
      className={clsx(
        'row bg-primary hover:bg-primary_hover my-xxs mx-md ps-md pe-lg flex items-center rounded-sm',
        { 'cursor-pointer': onClick },
        { 'pointer-events-none': disabled },
        { 'bg-secondary': selected },
        { 'py-md !h-9': size === 'md' },
        { 'py-sm !h-7 !min-h-7': size === 'sm' },
        className,
      )}
      onClick={onClick}
      onMouseOver={onMouseOver}
    >
      <Stack className="gap-xxs w-full">
        <Stack className="w-full" direction="row">
          <Box className="row me-auto flex items-center gap-x-2">
            {startDecorator}
            <Typography
              className={titleClassName}
              variant={size === 'sm' ? 'text-xs' : 'text-sm'}
              weight="medium"
            >
              {title}
            </Typography>
          </Box>
          {helpText ? (
            <Typography className={helpTextClassName} variant="text-xs" weight="regular">
              {helpText}
            </Typography>
          ) : null}
          {endDecorator}
        </Stack>
        {props.caption ? (
          <Stack className="w-full" direction="row">
            <Typography
              className={clsx('text-fg-secondary mb-xxs', titleClassName)}
              variant="text-xs"
              weight="regular"
            >
              {props.caption}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </ListItem>
  );
});

export default MenuItem;
