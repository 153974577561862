import { memo } from 'react';
import type { MouseEventHandler } from 'react';
import Stack from '@unifyapps/ui/_components/Stack/Stack';
import { Button } from '@unifyapps/ui/components/Button';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import { Tooltip } from '@unifyapps/ui/components/Tooltip';
import type { BlockMessageContentType, PrimaryActionItem } from '@unifyapps/defs/blocks/Chat/types';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { clsx } from 'clsx';
import type { SecondaryActionItem } from './hooks/useSecondaryActionItems';

function PrimaryActionButton({
  item,
  message,
  size,
}: {
  item: PrimaryActionItem;
  message: BlockMessageContentType[];
  size?: 'sm' | 'lg';
}) {
  const { onClick, color, variant, label } = item;
  const handleClick: MouseEventHandler<HTMLButtonElement> = useEventCallback((event) =>
    onClick(event, message),
  );

  return (
    <Button
      color={color ?? 'neutral'}
      onClick={handleClick}
      size={size === 'sm' ? 'xs' : 'sm'}
      variant={variant ?? 'outline'}
    >
      {label}
    </Button>
  );
}

function SecondaryActionButton({
  item,
  message,
  size,
}: {
  item: SecondaryActionItem;
  message: BlockMessageContentType[];
  size?: 'sm' | 'lg';
}) {
  const { onClick, Icon, tooltipLabel } = item;
  const handleClick: MouseEventHandler<HTMLButtonElement> = useEventCallback((event) =>
    onClick?.(event, message),
  );

  return (
    <Tooltip title={tooltipLabel}>
      <IconButton
        Icon={Icon}
        color="neutral"
        iconClassName={clsx('text-icon-secondary', { 'fill-current': item.active })}
        onClick={handleClick}
        size={size === 'sm' ? 'xs' : 'sm'}
        variant="soft"
      />
    </Tooltip>
  );
}

export const MessageFooter = memo(function MessageFooter({
  primaryActionItems,
  secondaryActionItems,
  size,
  message,
  className,
}: {
  primaryActionItems?: PrimaryActionItem[];
  secondaryActionItems: SecondaryActionItem[];
  size?: 'sm' | 'lg';
  message: BlockMessageContentType[];
  className?: string;
}) {
  if (!primaryActionItems && secondaryActionItems.length === 0) return null;

  return (
    <Stack className={clsx('gap-xl', className)}>
      {primaryActionItems ? (
        <Stack direction="row">
          {primaryActionItems.map((item) => (
            <PrimaryActionButton item={item} key={item.id} message={message} size={size} />
          ))}
        </Stack>
      ) : null}
      {secondaryActionItems.length !== 0 ? (
        <Stack className="gap-xs ms-auto" direction="row">
          {secondaryActionItems.map((item) => (
            <SecondaryActionButton item={item} key={item.id} message={message} size={size} />
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
});
