import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import type { SteppedContainerComponentType } from '@unifyapps/defs/blocks/SteppedContainer/types';
import _pick from 'lodash/pick';
import { lazy } from 'react';

const importSteppedContainerComponent = () =>
  import(
    /* webpackChunkName: "stepped-container-block" */
    './SteppedContainer'
  );

const SteppedContainer = lazy(importSteppedContainerComponent);

class SteppedContainerBlockStateDefinition implements BlockStateDefinition {
  get type(): ComponentTypeUnionType {
    return 'SteppedContainer';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as SteppedContainerComponentType, [
          'content',
          'componentType',
          'appearance',
        ]),
      }) as BlockStateUnionType;
  }

  getComponent() {
    return SteppedContainer;
  }

  preload() {
    return {
      blockPreload: importSteppedContainerComponent,
    };
  }
}

export default SteppedContainerBlockStateDefinition;
