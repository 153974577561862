import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  BlockStateUnionType,
  BlockType,
  ComponentTypeUnionType,
} from '@unifyapps/defs/types/block';
import _pick from 'lodash/pick';
import type { ModuleComponentType } from '@unifyapps/defs/blocks/Module/types';
import { lazy } from 'react';

const importModuleComponent = () =>
  import(
    /* webpackChunkName: "module-block" */
    './Module'
  );

const Module = lazy(importModuleComponent);

class ModuleBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return Module;
  }

  get type(): ComponentTypeUnionType {
    return 'Module';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType): BlockStateUnionType => ({
      id: block.id,
      ..._pick(block.component as ModuleComponentType, ['content', 'appearance', 'componentType']),
      output: undefined,
    });
  }

  preload() {
    return {
      blockPreload: importModuleComponent,
    };
  }
}

export default ModuleBlockStateDefinition;
