import type { BlockStateDefinition } from '@unifyapps/carbon/no-code/components/BlockDefinition';
import type {
  ComponentTypeUnionType,
  BlockType,
  BlockStateUnionType,
} from '@unifyapps/defs/types/block';
import type { IconButtonComponentType } from '@unifyapps/defs/types/blocks';
import _pick from 'lodash/pick';
import IconButton from './IconButton';

class IconButtonBlockStateDefinition implements BlockStateDefinition {
  getComponent() {
    return IconButton;
  }

  get type(): ComponentTypeUnionType {
    return 'IconButton';
  }

  getEventTargetIds() {
    return [];
  }

  getBlockControlMethods() {
    return [];
  }

  get initialStateGetter() {
    return (block: BlockType) =>
      ({
        id: block.id,
        ..._pick(block.component as IconButtonComponentType, [
          'content',
          'appearance',
          'componentType',
        ]),
      }) as BlockStateUnionType;
  }
}

export default IconButtonBlockStateDefinition;
