import type {
  TypographyComponentType,
  Appearance as TypographyAppearance,
} from '@unifyapps/defs/blocks/Typography/types';
import type { TypographyData } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';

export const generateTypographyBlock = ({
  data,
  appearance,
  isUserMessage,
}: {
  data: TypographyData;
  appearance?: TypographyAppearance;
  isUserMessage?: boolean;
}): {
  blocks: Record<string, { id: string; component: TypographyComponentType }>;
  containerId: string;
} | null => {
  if (!data.text) {
    return null;
  }

  const blockId = getIdWithPrefix(BLOCK_PREFIX);

  return {
    containerId: blockId,
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Typography',
          content: {
            type: data.type || 'MARKDOWN',
            value: data.text || '',
          },
          appearance: {
            styles: {
              color: isUserMessage ? 'text-brand-primary' : 'text-secondary',
              fontSize: 'prose-p:text-md prose-p:font-medium',
            },

            ...appearance,
          },
        },
      },
    },
  };
};
