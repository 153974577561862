import type { TypographyProps } from '@unifyapps/ui/components/Typography';
import type { ReactElement, ReactNode } from 'react';

export const enum TypographyWithExpandCollapseSlots {
  Expand = 'EXPAND',
  Collapse = 'COLLAPSE',
}

export type SlotProps = {
  name: TypographyWithExpandCollapseSlots;
  id?: string;
  children: ReactNode;
};

export type TypographyWithExpandCollapseProps = {
  text: string;
  children: ReactElement[];
} & Pick<
  TypographyProps,
  'align' | 'style' | 'variant' | 'weight' | 'title' | 'className' | 'maxNumberOfLines'
>;
