import { useMemo, memo } from 'react';
import { Loader as BaseLoader } from '@unifyapps/ui/components/Loader';
import type { BlockComponentProps } from '@unifyapps/carbon/no-code/components/BlockRenderer/types';
import type { LoaderBlockStateType, LoaderComponentType } from '@unifyapps/defs/types/blocks';
import { useBlockAppearanceStyles } from '@unifyapps/carbon/no-code/hooks/useBlockAppearanceStyles';

function Loader({
  dataAttributes,
  computedBlockState,
}: BlockComponentProps<LoaderComponentType, LoaderBlockStateType, LoaderBlockStateType>) {
  const { appearance } = computedBlockState;

  const { className } = useBlockAppearanceStyles({
    appearanceStyles: appearance?.styles,
  });

  const slotProps = useMemo(
    () => ({
      root: {
        ...dataAttributes,
      },
    }),
    [dataAttributes],
  );

  return (
    <BaseLoader className={className} size={appearance?.size} slotProps={slotProps} variant="arc" />
  );
}

export default memo(Loader);
