import { useDebouncedCallback } from '@react-hookz/web';
import useCopilotActions from '@unifyapps/carbon/copilot/hooks/useCopilotActions';
import { executeWorkflowNode } from '@unifyapps/network/generated/workflow-rest-api/workflow-rest-api';
import { keepPreviousData, useInfiniteQuery } from '@unifyapps/network/react-query';
import { useMemo } from 'react';
import type { Message, PaginatedMessages } from '@unifyapps/defs/blocks/Chat/types';
import AIAutomations from '@unifyapps/carbon/copilot/AIAutomations';
import { EMPTY_ARRAY } from '@unifyapps/carbon/consts/empty';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';

function getSortedMessage(pages?: Partial<PaginatedMessages>[]) {
  return (
    pages?.reduce((acc, page) => {
      if (page.response?.messages?.length) {
        acc.push(...page.response.messages);
      }
      return acc;
    }, [] as Message[]) ?? (EMPTY_ARRAY as Message[])
  ).reverse();
}

export function useFetchPaginatedMessage({
  chatId,
  automationsConfig,
}: {
  chatId?: string;
  automationsConfig?: AutomationConfig;
}) {
  const { fetchConversation } = useCopilotActions(automationsConfig);
  const {
    isLoading,
    isFetchingNextPage,
    data,
    fetchNextPage: _fetchNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: ['fetchConversation', { chatId }],
    queryFn: ({ pageParam }: { pageParam: { until?: number } | undefined }) => {
      const { debugParams, ...inputs } = fetchConversation({ caseId: chatId ?? '', ...pageParam });

      return executeWorkflowNode(
        {
          context: { appName: 'callables', resourceName: 'callables_call_automation' },
          inputs,
        },
        { debugParams } as { debugParams: Record<string, string> },
      ) as Promise<PaginatedMessages>;
    },
    getNextPageParam: (lastPage: { response?: { messages?: Message[] } }) => {
      if (lastPage.response?.messages) {
        return {
          until: lastPage.response.messages[lastPage.response.messages.length - 1].createdTime,
        };
      }
      return null;
    },
    initialPageParam: undefined,
    placeholderData: keepPreviousData,
    enabled: Boolean(chatId) && Boolean(AIAutomations.getFetchConversation(automationsConfig)),
  });

  const pages = data?.pages;
  const fetchMore = useDebouncedCallback(_fetchNextPage, [_fetchNextPage], 300);
  const fetchedMessages = useMemo(() => getSortedMessage(pages), [pages]);

  return { isLoading, isFetchingNextPage, messages: fetchedMessages, fetchMore, error };
}
