import { getIsSection } from '@unifyapps/carbon/widgets/SchemaGeneratorWidget/utils/canNodeHaveChild';
import type { JSONSchema7WithForiegnKey } from '@unifyapps/carbon/no-code/utils/dataSourceLookup/types';

export const shouldTraverseSchema = ({
  schema,
}: {
  schema: JSONSchema7WithForiegnKey;
}): boolean => {
  return getIsSection(schema);
};
