import { useRouter } from '@unifyapps/carbon/no-code/hooks/useRouter';
import { useCallback } from 'react';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import _isEmpty from 'lodash/isEmpty';
import _forEach from 'lodash/forEach';

//todo: remove these utils post setBlockQueryParams feature is added by Prakhar
const convertPayloadToString = (payload: unknown) => {
  if (typeof payload === 'object') {
    return JSON.stringify(payload);
  }
  if (typeof payload === 'string') {
    return payload;
  }
  return '';
};

export const createQueryParams = (
  queryParams: Record<string, Record<string, unknown> | string | undefined | null>,
) => {
  const searchParams = new URLSearchParams();

  _forEach(queryParams, (value, key) => {
    if (_isEmpty(value)) {
      return;
    }
    searchParams.set(key, convertPayloadToString(value));
  });

  return searchParams.toString();
};

export const useQueryParams = () => {
  const router = useRouter();

  const getQueryParam = useCallback(
    (key: string) => router.searchParams.get(key),
    [router.searchParams],
  );
  const setQueryParam = useEventCallback((key: string, value: string | undefined | null) => {
    const searchParams = Object.fromEntries(router.searchParams);

    const query = createQueryParams({
      ...searchParams,
      [key]: value,
    });
    router.push(query ? `${router.pathname}?${query}` : router.pathname);
  });

  return { getQueryParam, setQueryParam };
};
