'use client';
import React, { createContext, useContext, useState } from 'react';
import _noop from 'lodash/noop';

export type InitialAwaitingContextType = boolean;

const context = createContext<
  | [InitialAwaitingContextType, React.Dispatch<React.SetStateAction<InitialAwaitingContextType>>]
  | undefined
>(undefined);

function InitialAwaitingContext({ children }: { children?: React.ReactNode }) {
  const state = useState<InitialAwaitingContextType>(false);
  return <context.Provider value={state}>{children}</context.Provider>;
}

const fallbackContext = [false, _noop] as const;
const useInitialAwaitingContext = () => {
  return useContext(context) ?? fallbackContext;
};

export { useInitialAwaitingContext };
export default InitialAwaitingContext;
