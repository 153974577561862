import type { MediaBlockParams } from '@unifyapps/defs/blocks/Chat/types';
import { MessageType } from '@unifyapps/defs/blocks/Chat/types';
import { getIdWithPrefix } from '@unifyapps/carbon/utils/id';
import { BLOCK_PREFIX } from '@unifyapps/carbon/no-code/const';
import type { MediaComponentType } from '@unifyapps/defs/blocks/Media/types';
import { isImage } from '@unifyapps/carbon/components/Media/utils';
import { MediaDataType } from '@unifyapps/defs/blocks/Media/types';

const getMediaContainerWidth = (itemsCount: number) => {
  switch (itemsCount) {
    case 1:
      return 'w-[240px]';
    case 3:
      return 'max-w-[480px]';
    default:
      return 'max-w-[360px]';
  }
};

export const generateMediaBlock = ({
  data,
  messageType,
}: {
  data: MediaBlockParams['data'];
  messageType?: MessageType;
}): {
  blocks: Record<string, { id: string; component: MediaComponentType }>;
  containerId: string;
} => {
  const blockId = getIdWithPrefix(BLOCK_PREFIX);
  const isImageContainingBlock = data.some((item) => isImage(item));

  return {
    containerId: blockId,
    blocks: {
      [blockId]: {
        id: blockId,
        component: {
          componentType: 'Media',
          content: {
            src: data,
            type: MediaDataType.Static,
          },
          appearance: {
            variant: isImageContainingBlock ? 'spacious' : 'compact',
            display: isImageContainingBlock ? 'grid' : 'list',
            size: 'md',
            //todo: update this later
            maxUpfrontItems: isImageContainingBlock ? 4 : 100,
            styles: {
              width: getMediaContainerWidth(data.length),
              justifyContent:
                messageType === MessageType.User
                  ? 'justify-end justify-items-end'
                  : 'justify-start',
            },
          },
        },
      },
    },
  };
};
