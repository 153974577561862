import type { BlockComponentProps } from '../../../BlockRenderer/types';
import type { LoginLayoutComponentType } from '../../types';
import CenteredCardLayout from '../CenteredCardLayout';
import TwoColumnLayout from '../TwoColumnLayout';

function LoginLayout(
  props: BlockComponentProps<
    LoginLayoutComponentType,
    Record<string, unknown>,
    Record<string, unknown>
  >,
) {
  const { layoutType } = props.component;

  if (layoutType === 'centered-card') {
    return <CenteredCardLayout {...props} />;
  }

  return <TwoColumnLayout {...props} />;
}

export default LoginLayout;
