import AIAutomations from '@unifyapps/carbon/copilot/AIAutomations';
import useExecuteNode from '@unifyapps/carbon/copilot/hooks/useExecuteNode';
import useEventCallback from '@unifyapps/hooks/useEventCallback';
import { FeedbackReaction, type Feedback, type Message } from '@unifyapps/defs/blocks/Chat/types';
import useToggle from '@unifyapps/hooks/useToggle';
import useMutationHelpers from '@unifyapps/carbon/hooks/useMutationHelpers';
import type { AutomationConfig } from '@unifyapps/defs/blocks/Copilot/types';

type Args = {
  message: Message;
  refetchMessage: (messageId: string) => void;
  automationsConfig?: AutomationConfig;
};

export const useMessageFeedback = ({ message, refetchMessage, automationsConfig }: Args) => {
  const [feedbackModalOpen, { on: openFeedbackModal, off: closeFeedbackModal }] = useToggle(false);

  const { onMutationError } = useMutationHelpers();

  const { mutate: saveFeedback, isPending } = useExecuteNode({
    onError: onMutationError,
    onSuccess: (data, variables) => {
      const messageId = (variables.parameters as { messageId: string }).messageId;
      refetchMessage(messageId);
    },
  });

  const onSaveFeedback = useEventCallback((feedback: Feedback, onSuccess?: () => void) => {
    saveFeedback(
      {
        automationId: AIAutomations.getFeedbackAutomation(automationsConfig),
        synchronous: true,
        parameters: {
          messageId: message.messageId,
          ...feedback,
        },
      },
      { onSuccess },
    );
  });

  const onLikeMessage = useEventCallback(() => {
    if (message.feedback?.reaction === FeedbackReaction.LIKE) {
      // dont do anything if the message is already liked
      return;
    }
    onSaveFeedback({ reaction: FeedbackReaction.LIKE });
  });

  const onDislikeMessage = useEventCallback(() => {
    if (message.feedback?.reaction !== FeedbackReaction.DISLIKE) {
      onSaveFeedback({ reaction: FeedbackReaction.DISLIKE });
    }
    openFeedbackModal();
  });

  return {
    onSaveFeedback,
    onLikeMessage,
    isPending,
    feedbackModalOpen,
    closeFeedbackModal,
    onDislikeMessage,
  };
};
