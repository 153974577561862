import type { ImageComponentType } from '@unifyapps/defs/blocks/Image/types';
import _noop from 'lodash/noop';
import { useMemo } from 'react';
import type { BlockStateUnionType, ComputedBlockStateUnionType } from '@unifyapps/defs/types/block';
import { useRegistryContext } from '../../../RegistryProvider';
import type { LoginLayoutComponentType } from '../../types';

function ImageCreativeContent({ content }: { content: ImageComponentType }) {
  const ImageBlock = useRegistryContext().registry.getBlockComponent(content.componentType);

  const blockState = useMemo(
    () => ({ ...content, id: 'creativeContent' }) as BlockStateUnionType,
    [content],
  );

  return ImageBlock ? (
    <ImageBlock
      blockState={blockState}
      component={content}
      computedBlockState={blockState as ComputedBlockStateUnionType}
      updateBlockState={_noop}
    />
  ) : null;
}

function IframeCreativeContent({ content }: { content: string }) {
  return <iframe src={content} title="auth-iframe" />;
}

function CreativeContent({
  creativeContent,
}: {
  creativeContent: NonNullable<LoginLayoutComponentType['creativeContent']>;
}) {
  return (
    <>
      {creativeContent.type === 'image' ? (
        <ImageCreativeContent content={creativeContent.content} />
      ) : (
        <IframeCreativeContent content={creativeContent.content} />
      )}
    </>
  );
}

export default CreativeContent;
